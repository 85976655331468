import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  EventItemTypes,
  REPORT_ENTITY_TYPES,
  REPORT_REASONS,
  REPORT_TYPES,
} from "../../../constants/constants";
import { SEARCH_TABS } from "../../../constants/search";
import useAuth from "../../../hooks/useAuth";
import { useSearchParamsHook } from "../../../routes/Search/hooks/searchParams";
import ArtistItem from "../../Artist/ArtistItem";
import EmptyState from "../../EmptyState";
import EventItem from "../../Events/EventItem";
import PerformanceItem from "../../Performance/PerformanceItem";
import ProfileItem from "../../Profile/ProfileItem";
import EmptyStateIcon from "../EmptyStateIcon";
import VenueSearchItem from "../VenueSearchItem";
import { ResultsContainer } from "./SearchResults-Styles";
import { BodyText, ButtonRect, ButtonRectSm } from "../../../GlobalStyles";
import FlagIcon from "../../../assets/svg/flag.svg?react";
import { theme } from "../../../utils/theme";

const { EVENT, ARTIST, VENUE, FAN } = SEARCH_TABS;

export default function SearchResults({ data, fetchNextPage, hasNextPage }) {
  const { loggedInUser } = useAuth();
  const [searchParams] = useSearchParams();
  const {
    tab: activeTab,
    isPast,
    query,
    country,
    city,
    year,
    month,
    isRetro,
    artist,
    venue,
  } = useSearchParamsHook({ searchParams });
  const navigate = useNavigate();

  const performances = useMemo(() => {
    if (!isRetro && !isPast) return [...data];
    return data?.reduce((acc, curr) => {
      if (curr?.performances?.length === 0) return acc;

      if (curr?.performances?.length > 0) return [...acc, ...curr.performances];
    }, []);
  }, [data, isRetro]);

  const aOrAn = activeTab === EVENT || activeTab === ARTIST ? "an" : "a";
  const tabWithFilters = activeTab === EVENT || activeTab === VENUE;

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasNextPage, fetchNextPage]
  );

  useEffect(() => {
    if ((!isRetro && !isPast) || !hasNextPage) return;

    if (data[data.length - 1]?.performances?.length === 0) fetchNextPage();
  }, [hasNextPage, isRetro, isPast]);

  // No input empty state
  if (!query && !country && !city && !year && !month && !artist && !venue) {
    return (
      <EmptyState
        icon={<EmptyStateIcon activeTab={activeTab} />}
        title={`Search for ${aOrAn} ${activeTab}`}
        description={`Type in the search bar ${tabWithFilters ? "or tap filters" : ""} to find ${aOrAn} ${activeTab}.`}
        style={{
          paddingTop: tabWithFilters ? "calc(40% - 44px)" : "40%",
        }}
      />
    );
  }

  // No resuts empty state
  if (
    !data ||
    data?.length === 0 ||
    ((isRetro || isPast) && performances?.length === 0)
  )
    return (
      <EmptyState
        icon={<EmptyStateIcon activeTab={activeTab} />}
        title={"Oops, There’s nothing here."}
        description={
          activeTab !== EVENT
            ? "Maybe try searching for another one?"
            : `Are we missing ${activeTab === EVENT ? "your" : aOrAn} ${activeTab}?`
        }
        button={
          activeTab === EVENT ? (
            <div>
              <ButtonRect
                background={theme.colors.greyBtn}
                style={{
                  width: "fit-content",
                  alignSelf: "center",
                  border: "none",
                  margin: "8px 0 12px 0",
                }}
                onClick={() =>
                  navigate(
                    `/report?type=${REPORT_TYPES.EVENT}&entity_type=${REPORT_ENTITY_TYPES.EVENT_ENTITY}&reason=${REPORT_REASONS.MISSING_EVENT}`
                  )
                }
              >
                <FlagIcon stroke={theme.colors.white} />
                <BodyText color={theme.colors.white} weight="600">
                  Let us know!
                </BodyText>
              </ButtonRect>
              <BodyText color={theme.colors.grey}>
                and earn <BodyText color={theme.colors.green}>XP!</BodyText>
              </BodyText>
            </div>
          ) : null
        }
        style={{
          paddingTop: tabWithFilters ? "calc(40% - 44px)" : "40%",
        }}
      />
    );

  return (
    <ResultsContainer>
      {activeTab === EVENT &&
        (isRetro || isPast) &&
        data?.map((page) => {
          return page?.performances.map((performance, performanceIndex) => {
            const isLastItem =
              performanceIndex === page?.performances?.length - 1;

            return (
              <PerformanceItem
                key={performance?.performance_id}
                performance={performance}
                type={EventItemTypes.SEARCH}
                innerRef={isLastItem ? lastElementRef : null}
              />
            );
          });
        })}
      {activeTab === EVENT &&
        !isRetro &&
        !isPast &&
        data?.map((event, index) => {
          const isLastItem = index === data?.length - 1;

          return (
            <EventItem
              key={event?.id}
              event={event}
              type={EventItemTypes.SEARCH}
              isOwnProfile={loggedInUser && true}
              innerRef={isLastItem ? lastElementRef : null}
            />
          );
        })}
      {(activeTab === ARTIST || !activeTab) &&
        data?.map((artist, index) => {
          const isLastItem = index === data?.length - 1;

          return (
            <ArtistItem
              key={artist?.id}
              artist={artist}
              isOwnProfile={loggedInUser && true}
              isSearchMode={!activeTab}
              innerRef={isLastItem ? lastElementRef : null}
            />
          );
        })}
      {activeTab === VENUE &&
        data.length > 0 &&
        data?.map((venue, index) => {
          const isLastItem = index === data?.length - 1;

          if (venue.venue_name != "")
            return (
              <VenueSearchItem
                key={venue?.id}
                venue={venue}
                innerRef={isLastItem ? lastElementRef : null}
              />
            );
          else return null;
        })}
      {activeTab === FAN &&
        data.length > 0 &&
        data?.map((fan, index) => {
          const isLastItem = index === data?.length - 1;

          return (
            <ProfileItem
              key={fan?.id}
              user={fan}
              innerRef={isLastItem ? lastElementRef : null}
            />
          );
        })}
      {activeTab === EVENT && data?.length !== 0 && data?.length <= 6 && (
        <EmptyState
          description={
            <BodyText secondary>
              Are we missing your event? Earn{" "}
              <BodyText color={theme.colors.green}>10xp</BodyText>!
            </BodyText>
          }
          button={
            <div>
              <ButtonRectSm
                onClick={() =>
                  navigate(
                    `/report?type=${REPORT_TYPES.EVENT}&entity_type=${REPORT_ENTITY_TYPES.EVENT_ENTITY}&reason=${REPORT_REASONS.MISSING_EVENT}`
                  )
                }
              >
                <FlagIcon stroke={theme.colors.white} />
                Let us know!
              </ButtonRectSm>
            </div>
          }
          style={{
            paddingTop: "0",
            marginTop: "auto",
            flex: 0,
          }}
        />
      )}
    </ResultsContainer>
  );
}

SearchResults.propTypes = {
  data: PropTypes.array,
  blurred: PropTypes.bool,
  removeBlur: PropTypes.func,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
};
