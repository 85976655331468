import {
  Modal,
  PinInput,
  Text,
  Stack,
  Image,
  Group,
  Space,
  CloseButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import PropTypes from "prop-types";
import { useWhitelistCode } from "../hooks/useRestriction";
import { useEffect, useState } from "react";

export const CodeRestrictionModal = ({ opened, open, close, phoneNumber }) => {
  // console.log(phoneNumber);
  const [error, setError] = useState("");
  const { mutateAsync } = useWhitelistCode();
  const [verifying, setVerifying] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [code, setCode] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get("inviteCode");

    if (inviteCode) {
      setCode(inviteCode);
    }
  }, []);
  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      overlayProps={{
        blur: 5,
      }}
      radius={16}
      zIndex={100000000}
      mah={243}
      maw={288.83}
      w={288.83}
      h={243}
      yOffset={"60%"}
      styles={{
        content: {
          backgroundColor: "rgba(17, 17, 31, 1)",
          maxHeight: "243px",
          maxWidth: "288.83px",
          height: "243px",
          width: "288.83px",
        },
        root: {
          backgroundColor: "rgba(17, 17, 31, 1)",
        },
        body: {
          backgroundColor: "rgba(17, 17, 31, 1)",
          padding: "0px",
          alignContent: "center",
          alignItems: "center",

          height: "243px",
          width: "288.83px",
        },
      }}
    >
      <CloseButton
        top={4}
        right={4}
        size={30}
        variant="transparent"
        style={{
          position: "absolute",
          opacity: 0.6,
          color: "#FFFFFF",
        }}
        onClick={close}
      />
      <Stack
        align="center"
        justify="center"
        h="100%"
        m={"auto"}
        w={"100%"}
        gap={16}
      >
        <Text
          fw={900}
          ff="Druk"
          fz={26.04}
          style={{
            letterSpacing: "0.04em",
            color: "rgba(255, 255, 255, 1)",
          }}
        >
          ENTER ACCESS CODE
        </Text>
        <PinInput
          length={6}
          placeholder="0"
          gap={8}
          value={code}
          autoCapitalize="characters"
          onComplete={async (code) => {
            setVerifying(true);
            setTimeout(async () => {
              const result = await mutateAsync({ phoneNumber, code });

              if (result.error) {
                setError(result.error);
                console.log(result.error);
              } else {
                setError("");
              }

              setVerifying(false);

              if (result.success) {
                const spans = document.querySelectorAll("span");
                let button = null;

                spans.forEach((span) => {
                  if (span.textContent.trim() === "Submit") {
                    button = span.closest("button");
                  }
                });

                if (button) {
                  button.disabled = false;
                  button.style.color = "hsl(162, 60%, 65%)";
                }
                setIsSuccess(true);

                setTimeout(() => {
                  close();
                }, 250);
              }
            }, 500);
          }}
          onChange={(value) => {
            if (value) setCode(value.toUpperCase()); // Capitalize input
            setError("");
          }}
          styles={{
            input: {
              width: "36.14px",
              height: "46px",
              borderRadius: "10px",
              border: isSuccess
                ? "1px solid rgba(110, 219, 187, 1)"
                : error == ""
                  ? "1px solid rgba(255, 255, 255, 0.2)"
                  : "1px solid rgba(252, 104, 148, 0.6)",
              backgroundColor: "transparent",
              fontFamily: "Druk",
              fontSize: "26.04px",
              fontWeight: "900",
              color: "rgba(247, 247, 247, 1)",
            },
          }}
        />

        <Stack
          style={{
            fontFamily: "Suisse Int'l",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            textAlign: "center",
          }}
          gap={0}
          align="center"
          justify="center"
        >
          {verifying ? (
            <Text
              style={{
                fontFamily: "Suisse Int'l",
                fontWeight: "400",
                fontSize: "14px",
                textAlign: "center",
                color: "rgba(247, 247, 247, 1)",
                marginBottom: "4px",
              }}
            >
              Verifying...
            </Text>
          ) : error != "" ? (
            <Text
              style={{
                fontFamily: "Suisse Int'l",
                fontWeight: "400",
                fontSize: "14px",
                textAlign: "center",
                color: "rgba(252, 104, 148, 1)",
                marginBottom: "4px",
              }}
            >
              {error}
            </Text>
          ) : isSuccess ? (
            <Text
              style={{
                fontFamily: "Suisse Int'l",
                fontWeight: "400",
                fontSize: "14px",
                textAlign: "center",
                color: "rgba(110, 219, 187, 1)",
              }}
            >
              Success!
            </Text>
          ) : (
            <Space h={26} />
          )}

          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              color: "rgba(153, 153, 157, 1)",
            }}
          >
            Dont have a code?
          </Text>
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              color: "rgba(153, 153, 157, 1)",
            }}
          >
            Join and get notified when we launch.
          </Text>
          <Space h="12px" />
          <Group>
            <a
              href="https://chat.whatsapp.com/DeJzrqYGnqjL8LsWwVyz5G"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/svgs/grayViber.svg"
                alt="WhatsApp"
                style={{
                  opacity: 0.6,
                }}
              />
            </a>
            <a
              href="https://t.me/+biWclgxp3FtlMmE0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/svgs/grayTG.svg"
                alt="Telegram"
                style={{
                  opacity: 0.6,
                }}
              />
            </a>
          </Group>
        </Stack>
      </Stack>
    </Modal>
  );
};

CodeRestrictionModal.propTypes = {
  opened: PropTypes.bool,
  open: PropTypes.func,
  close: PropTypes.func,
};
