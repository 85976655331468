import { CARDS } from "../../constants/onboarding-cards";
import { Container } from "./Onboarding-Styles";
import OnboardingCard from "./OnboardingCard";
import { useOnboardingStore } from "./onboardingStore";

export default function Onboarding() {
  const { step, isOnboarded } = useOnboardingStore();

  if (isOnboarded) return null;

  return (
    <Container>
      <OnboardingCard data={CARDS[step]} />
    </Container>
  );
}
