import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../../assets/images/mom-logo-full.webp";
import Whatsapp from "../../assets/svg/whatsapp.svg?react";
import Telegram from "../../assets/svg/telegram.svg?react";
import useGlobalModal from "../../hooks/useGlobalModal";

import {
  BetaContainer,
  BottomContainer,
  CenterContainer,
  ContentContainer,
  HeaderContainer,
  LoginButton,
  LoginSection,
  LogoImage,
  Navbar,
} from "./Login-Styles";
import { BodyText, FooterText, HeaderXxxl } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { BetaIcon } from "../../components/BetaIcon";
import { getVersion } from "../../utils/version";
import { LOGIN_LINKS } from "../../constants/constants";

export default function Login() {
  const { ready, authenticated, user, loggedInUser, login } =
    useMomentifyLogin();
  const navigate = useNavigate();
  const { openSheetPWAModal } = useGlobalModal();

  useEffect(() => {
    openSheetPWAModal();
  }, []);

  useEffect(() => {
    const redirectToProfile = async () => {
      if (authenticated && user && loggedInUser) {
        navigate(`/profile/${loggedInUser.id}?from-login=true`);
      }
    };

    redirectToProfile();
  }, [authenticated, user, loggedInUser, navigate]);

  const notFullyReady = !ready || (authenticated && !loggedInUser);

  if ((!authenticated && !user) || notFullyReady) {
    return (
      <LoginSection>
        <Navbar>
          <LogoImage src={Logo} />
          <BetaIcon />
        </Navbar>
        <ContentContainer>
          <CenterContainer>
            <HeaderContainer>
              <HeaderXxxl
                style={{ textTransform: "uppercase", lineHeight: "63.18px" }}
              >
                Make More
                <br />
                Music Memories
              </HeaderXxxl>
              <HeaderContainer
                style={{
                  gap: 0,
                }}
              >
                <BodyText color={theme.colors.grey}>
                  Capture your memories of live music.
                </BodyText>
                <BodyText color={theme.colors.grey}>
                  Get rewards. Make friends. Support artists!
                </BodyText>
              </HeaderContainer>
            </HeaderContainer>
          </CenterContainer>
        </ContentContainer>
        <BetaContainer>
          <BodyText secondary>{"We're in"}</BodyText>
          <BetaIcon />
          <BodyText secondary>{"You'll need an access code!"}</BodyText>
        </BetaContainer>
        <BottomContainer>
          <LoginButton onClick={() => login()} disabled={notFullyReady}>
            <BodyText weight="600" color={theme.colors.black}>
              Log in
            </BodyText>
          </LoginButton>
          <BetaContainer style={{ gap: 16 }}>
            <BodyText secondary>No code? Join the queue</BodyText>
            <Link to={LOGIN_LINKS.WHATSAPP} target="_blank">
              <Whatsapp
                style={{
                  opacity: 0.6,
                }}
              />
            </Link>
            <Link to={LOGIN_LINKS.TELEGRAM} target="_blank">
              <Telegram
                style={{
                  opacity: 0.6,
                }}
              />
            </Link>
          </BetaContainer>
          <FooterText secondary>v{getVersion()}</FooterText>
        </BottomContainer>
      </LoginSection>
    );
  }
}
