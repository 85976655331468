import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Section } from "../../GlobalStyles";
import FollowingTabs from "../../components/Following/FollowingTabs";
import { NavBarTypes } from "../../constants/constants";
import ErrorPage from "../Error";
import { useFollowing } from "./hooks/followingQueries";
import TopNavV2 from "../../components/TopNavV2";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export default function Following() {
  const { id: userId } = useParams();
  const { loggedInUser } = useAuth();
  const { handleTracking } = useMixpanelTracker();

  const { data, error, isLoading } = useFollowing({ userId });

  useEffect(() => {
    handleTracking("Visited Following Page", {
      "Profile ID": userId,
      "User ID": loggedInUser?.id,
    });
  }, []);

  if (error) return <ErrorPage />;

  return (
    <Container>
      <TopNavV2 title={NavBarTypes.FOLLOWING} isSticky isBlurred />
      <FollowingTabs
        artists={data?.artists}
        fans={data?.fans}
        isLoading={isLoading}
      />
    </Container>
  );
}
