import { Heart } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";
import { FooterTextSm } from "../GlobalStyles";
import {
  useGetLikedMomentPhoto,
  useLikeMomentPhoto,
  useUnlikeMomentPhoto,
} from "../hooks/momentQueries";
import useMomentifyLogin from "../hooks/useMomentifyLogin";
import { theme } from "../utils/theme";
import useMixpanelTracker from "../hooks/useMixpanelTracker";

const ButtonContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border: none;
  background: transparent;
`;

/**
 * LikeButton component that handles sharing content.
 * @param {number} isCurrentUserLiked - The URL to be shared
 * @param {number} likeCount - The title of the content to be shared
 * @param {object} moment - The text content to be shared
 * @return {React.JSX.Element} - The ShareButton component
 */
export default function LikeButton({
  isCurrentUserLiked = null,
  likeCount = 0,
  moment,
}) {
  const { loggedInUser, login } = useMomentifyLogin();
  const [isCurrentlyLiked, setIsCurrentlyLiked] = useState(isCurrentUserLiked);
  const [currentMoment, setCurrentMoment] = useState(moment ?? null);
  const [_likeCount, setLikeCount] = useState(
    likeCount || currentMoment?.total_likes || 0
  );
  const queryClient = useQueryClient();
  const getLikedPhotoMutation = useGetLikedMomentPhoto({
    user_id: loggedInUser?.id,
    moment_id: moment?.moment_id,
  });
  const unlikePhoto = useUnlikeMomentPhoto();
  const likePhoto = useLikeMomentPhoto();
  const { handleTracking } = useMixpanelTracker();

  // Handle user like in the moment photo
  const handleClick = async (e) => {
    e.preventDefault();

    // If user is not logged in, display an error message
    if (!loggedInUser) return login();

    // Toggle the user's like status
    setIsCurrentlyLiked((isCurrentlyLiked) => !isCurrentlyLiked);

    if (isCurrentlyLiked) {
      setLikeCount(_likeCount - 1);
      handleTracking("Unliked a moment", {
        "Moment ID": moment?.moment_id,
      });
    } else {
      setLikeCount(_likeCount + 1);
      handleTracking("Liked a moment", {
        "Moment ID": moment?.moment_id,
      });
    }

    // TODO: Check this out
    queryClient.removeQueries({
      queryKey: ["performance"],
    });

    queryClient.invalidateQueries({
      queryKey: [
        "artist-moments",
        {
          artistId: moment?.artist_id?.toString(),
          userId: moment?.user_id,
        },
      ],
      refetchType: "all",
    });

    // Retrieve and update moments feed data
    const momentsData = queryClient.getQueryData([
      "moments-feed",
      loggedInUser?.id,
    ]);

    if (momentsData) {
      const updatedPages = momentsData.pages.map((page) => ({
        unseenMoments: page.unseenMoments.map((moment) => {
          if (moment?.moment_id === currentMoment?.moment_id) {
            return {
              ...moment,
              total_likes: moment.total_likes + (moment.liked_by_user ? -1 : 1),
              liked_by_user: !moment.liked_by_user,
            };
          }
          return moment;
        }),
        seenMoments: page.seenMoments.map((moment) => {
          if (moment?.moment_id === currentMoment?.moment_id) {
            return {
              ...moment,
              total_likes: moment.total_likes + (moment.liked_by_user ? -1 : 1),
              liked_by_user: !moment.liked_by_user,
            };
          }
          return moment;
        }),
      }));

      queryClient.setQueryData(["moments-feed", loggedInUser?.id], {
        pages: updatedPages,
        pageParams: momentsData.pageParams,
      });
    }

    // Retrieve and update events feed data
    const eventsData = queryClient.getQueryData([
      "events-feed",
      loggedInUser?.id,
    ]);
    if (eventsData) {
      const updatedPages = eventsData.pages.map((page) =>
        page.map((event) => {
          if (event.moment_id === currentMoment?.moment_id) {
            return {
              ...event,
              total_likes: event.total_likes + (event.liked_by_user ? -1 : 1),
              liked_by_user: !event.liked_by_user,
            };
          }
          return event;
        })
      );

      queryClient.setQueryData(["events-feed", loggedInUser?.id], {
        pages: updatedPages,
        pageParams: eventsData.pageParams,
      });
    }

    const user = await getLikedPhotoMutation.mutateAsync();

    // If user is liked, remove them from the list
    if (user && user.data?.user_id == loggedInUser?.id) {
      user.data.creator_id = moment.user_id;

      unlikePhoto.mutateAsync(user);

      setCurrentMoment({
        total_likes: currentMoment?.total_likes
          ? currentMoment.total_likes - 1
          : 0,
        liked_status: false,
      });
    } else {
      const likedMomentPhoto = await likePhoto.mutateAsync(moment);

      setCurrentMoment({
        ...likedMomentPhoto.data.action,
        action_id: likedMomentPhoto.data.action.id,
        total_likes: currentMoment?.total_likes
          ? currentMoment.total_likes + 1
          : 1,
        liked_status: true,
        creator_id: moment.user_id,
      });
    }
  };

  // Render the ShareButton component with the share icon
  return (
    <ButtonContainer onClick={handleClick}>
      <Heart
        height={30}
        width={30}
        color={isCurrentlyLiked ? theme.colors.green : theme.colors.white}
        weight={isCurrentlyLiked ? "fill" : "regular"}
      />
      <FooterTextSm>{_likeCount}</FooterTextSm>
    </ButtonContainer>
  );
}

LikeButton.propTypes = {
  isCurrentUserLiked: PropTypes.bool,
  likeCount: PropTypes.number,
  moment: PropTypes.object,
  isFetched: PropTypes.bool,
};
