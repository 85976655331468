import { setDisplayName, setMatrixAccountAvatar } from "../api/matrix";
import {
  acceptUserTerms,
  getDuplicateUsername,
  uploadUserImage,
} from "../api/profile";
import { CINNY_ACCESS_TOKEN, CINNY_USER_ID } from "../routes/Chat/constants";
import { getSecret } from "./cinny-utils";
import { toast } from "react-toastify";
import { isProd } from "./utils";
import { CDN_LINKS } from "../constants/constants";
import useMomentifyLogin from "../hooks/useMomentifyLogin";
import SubscribeModal from "../components/Modal/SubscribeModal";
import { useUserStore } from "../routes/Profile/store/userStore";
import useGlobalModal from "../hooks/useGlobalModal";
import { useSubscribeMutate } from "../routes/Artist/hooks/artistQueries";

export const handleProfileSubmit = async (data, loggedInUser, isCreate) => {
  if (!loggedInUser) {
    return {
      success: false,
      error: { name: "username", message: "Please login to continue." },
    };
  }

  const username = data?.username;
  const image =
    data?.image?.length > 0
      ? data?.image[0]
      : data?.addedImage
        ? data?.addedImage
        : null;
  const currentUsername = username == loggedInUser.username;

  if (currentUsername && !image) {
    return {
      success: false,
      error: {
        name: "username",
        message: "This is your current username. Please enter a new username.",
      },
    };
  } else if (!username) {
    return {
      success: false,
      error: {
        name: "username",
        message: "Please enter a username.",
      },
    };
  } else if (username.length < 3) {
    return {
      success: false,
      error: {
        name: "username",
        message: "Username must be at least 3 characters long.",
      },
    };
  } else if (username.length > 20) {
    return {
      success: false,
      error: {
        name: "username",
        message: "This username is too long (max 20).",
      },
    };
  }
  // Usernames cannot include spaces or special characters but can include underscores
  else if (!username.match(/^[a-zA-Z0-9_]+$/)) {
    return {
      success: false,
      error: {
        name: "username",
        message:
          "Usernames cannot include spaces or special characters. Please try again!",
      },
    };
  }

  const duplicate = await getDuplicateUsername(username);

  if (duplicate && !currentUsername) {
    return {
      success: false,
      error: {
        name: "username",
        message: "This username is already taken.",
      },
    };
  }

  const matrixToken = getSecret(CINNY_ACCESS_TOKEN);
  const matrixUserID = getSecret(CINNY_USER_ID);
  // Upload image to s3
  if (image) {
    const imageUploaded = await uploadUserImage(loggedInUser.id, image);

    if (!imageUploaded) {
      uploadUserImage;
      toast.error("Error uploading image. Please try again.", {
        toastId: "image-upload-error",
      });
      return {
        success: false,
        error: {
          name: "image",
          message: "Error uploading image. Please try again.",
        },
      };
    }

    const imageUrl = isProd
      ? `${CDN_LINKS.APP}/${imageUploaded.key}`
      : `${CDN_LINKS.STAGING}/${imageUploaded.key}`;

    data.avatar = imageUrl;
    await setMatrixAccountAvatar(
      import.meta.env.VITE_MATRIX_APP_URL,
      matrixToken,
      matrixUserID,
      data.avatar
    );
  }

  await setDisplayName(
    import.meta.env.VITE_MATRIX_APP_URL,
    matrixToken,
    matrixUserID,
    username
  );

  if (isCreate) await acceptUserTerms(loggedInUser.id);

  return { success: true };
};

export const useSubscription = (artist, isFollowing, isSubscribed) => {
  const {
    addUserFollowedArtist,
    addUserSubscriptions,
    removeUserSubscriptions,
  } = useUserStore();
  const { loggedInUser, login } = useMomentifyLogin();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();

  const subscribeMutation = useSubscribeMutate({
    loggedInUserId: loggedInUser?.id,
    artistId: artist.artist_id,
  });

  const handleSubscribeClick = async () => {
    try {
      if (!loggedInUser) return login();
      subscribeMutation.mutate({ isSubscribed, isFollowing });

      artist.id = artist.artist_id;

      if (!isFollowing && !isSubscribed) {
        addUserFollowedArtist(artist);
        addUserSubscriptions(artist);
      } else if (!isSubscribed) {
        addUserSubscriptions(artist);
      } else if (isSubscribed) {
        removeUserSubscriptions(artist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const showSubscribeButton = () => {
    if (!loggedInUser) return login();

    setSheetModalContent(
      <SubscribeModal
        artist={artist}
        handleSubscribeClick={handleSubscribeClick}
      />
    );
    toggleSheetModal();
  };

  return { handleSubscribeClick, showSubscribeButton };
};
