import PropTypes from "prop-types";
import { BalanceContainer, Container } from "./WalletCard-Styles";
import WalletCardBalance from "../WalletCardBalance";
import WalletCardFooter from "../WalletCardFooter";
import WalletCardHeader from "../WalletCardHeader";

export default function WalletCard({ wallet }) {
  return (
    <Container primary={wallet?.primary}>
      <WalletCardHeader wallet={wallet} />
      <BalanceContainer>
        <WalletCardBalance wallet={wallet} />
      </BalanceContainer>
      <WalletCardFooter wallet={wallet} />
    </Container>
  );
}

WalletCard.propTypes = {
  wallet: PropTypes.object,
  primary: PropTypes.bool,
};
