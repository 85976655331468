import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 12px;
`;
