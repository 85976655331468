import PropTypes from "prop-types";
import { Container } from "./VenueDetails-Styles";
import { theme } from "../../../utils/theme";
import { BodyText, IconWithText } from "../../../GlobalStyles";
import Map from "../../../assets/svg/map.svg?react";
import ShareButton from "../../ShareButton";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { colors } = theme;

export default function VenueDetails({ venue, isLoading }) {
  const { venue_name, city, country } = venue || {};

  if (isLoading) {
    return (
      <Container>
        <SkeletonElement height="18px" width="200px" />
        <SkeletonElement height="28px" width="28px" radius="50%" />
      </Container>
    );
  }

  return (
    <Container>
      <IconWithText>
        <div style={{ display: "grid", placeContent: "center" }}>
          <Map
            height={18}
            width={18}
            stroke={colors.white}
            fill={colors.white}
          />
        </div>
        <BodyText>
          {city}, {country}
        </BodyText>
      </IconWithText>
      {/* Render venue name */}
      <ShareButton
        url={window.location.href}
        title={venue_name}
        text={`Check out this venue - ${venue_name}`}
      />
    </Container>
  );
}

VenueDetails.propTypes = {
  venue: PropTypes.object,
  isLoading: PropTypes.bool,
};
