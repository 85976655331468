import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 8px;
  background: ${({ theme }) => theme.colors.ephemeralBg};
`;

export const IconWithTextContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 12px;
`;

export const MomentTipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.ephemeralBgTransparent};
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
`;
