import { getOneArtist } from "../../api/artist";
import { getActionByTypeAndId, getUserById } from "../../api/profile";

export default async function landingPageLoader({ params }) {
  if (!params.inviteId) return null;

  const { inviteId } = params;

  try {
    const inviteLink = await getActionByTypeAndId({
      action_id: inviteId,
      action_type: "invite_friend",
    });

    const invitor = !!inviteLink.data
      ? await getUserById(inviteLink?.data?.user_id)
      : null;

    const artist = !!inviteLink?.data?.artist_id
      ? await getOneArtist(inviteLink?.data?.artist_id)
      : null;

    // if(!inviteLink?.data || !invitor?.data) return null

    return {
      inviteLink: inviteLink.data,
      invitor: invitor ?? null,
      artist: artist?.data?.artist ?? null,
      inviteCode: inviteLink?.code ?? null,
    };
  } catch (error) {
    console.warn(error);
    return null;
  }
}
