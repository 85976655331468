import { useQuery } from "@tanstack/react-query";
import { getTicketABI, getTicketManagerABI } from "../../../api/ticketing";

export const useGetTicketABI = () => {
  return useQuery({
    queryKey: ["ticketABI"],
    queryFn: async () => {
      const data = await getTicketABI();

      return data.abi;
    },
  });
};

export const useGetTicketManagerABI = () => {
  return useQuery({
    queryKey: ["ticket-manager-ABI"],
    queryFn: async () => {
      const data = await getTicketManagerABI();

      return data.abi;
    },
  });
};
