import styled from "styled-components";
import { NavBarTypes } from "../../constants/constants";
import { Section, BodyText, ButtonRect } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import NotificationItem from "../../components/Notifications/NotificationItem";
import { useEffect, useState } from "react";
import useNotification from "../../hooks/useNotification";
import { NotificationPermissionState } from "../../enums/permission";
import TopNav from "../../components/TopNav";
import BellRingingIcon from "../../assets/svg/bell-ringing.svg?react";
import useGlobalModal from "../../hooks/useGlobalModal";
import useCheckIn from "../../hooks/useCheckIn";
import withCheckInContextProvider from "../../hoc/withCheckInContextProvider";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import CheckInEvent from "../../components/CheckIn/CheckInEvent";
import NotificationModal from "../../components/Modal/NotificationModal";
import { useUserNotifications } from "./hooks/notificationQueries";
import useAuth from "../../hooks/useAuth";
import EmptyState from "../../components/EmptyState";
import ConfettiIcon from "../../assets/svg/confetti.svg?react";
import SearchIcon from "../../assets/svg/search.svg?react";
import RectButtonIconText from "../../components/RectButtonIconText";
import { useNavigate } from "react-router-dom";
import PartialEmptyState from "../../components/PartialEmptyState";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

const { colors } = theme;

const BadgesSection = styled(Section)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 16px;
  // padding: 16px;
  align-items: ${({ hasBadges }) => !hasBadges && "center"};
  justify-content: ${({ hasBadges }) => !hasBadges && "center"};
  flex: ${({ hasBadges }) => !hasBadges && "1"};
`;

const BadgesContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  align-items: ${({ hasBadges }) => !hasBadges && "center"};
  justify-content: ${({ hasBadges }) => !hasBadges && "center"};
  flex: ${({ hasBadges }) => !hasBadges && "1"};
  overflow: scroll;
`;

/**
 * Function to render the user badges section based on the attained and next attainable badges.
 *
 * @return {React.JSX.Element} The user badges section component
 */
function NotificationCenter() {
  const { loggedInUser } = useAuth();

  const navigate = useNavigate();

  const notifications = useUserNotifications({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: true,
  });

  const [notificationList, setNotificationList] = useState(
    notifications?.data ?? []
  );
  const {
    notificationPermissionState,
    getBrowserNotifState,
    askNotificationPermission,
  } = useNotification();
  const { openSheetModal, setSheetModalContent } = useGlobalModal();
  const { setCurrentEvent, currentEvent, handleBlindCheckinEvent } =
    useCheckIn();
  const { handleToggle, isOpen } = useCheckInModalToggle();
  const handlePopSelectedItem = (notificationId) => {
    setNotificationList(
      notificationList.filter((row) => row.id != notificationId)
    );
  };
  const { handleTracking } = useMixpanelTracker();

  useEffect(() => {
    handleTracking("Checked Notifications");
    getBrowserNotifState().then((permState) => {
      if (permState == NotificationPermissionState.GRANTED) {
        askNotificationPermission();
      }
    });
  }, []);

  useEffect(() => {
    if (currentEvent && !isOpen) {
      handleToggle();
    }
    return () => {
      setCurrentEvent(null);
    };
  }, [currentEvent]);

  if (isOpen) {
    return (
      <CheckInEvent
        onClose={handleToggle}
        onRefresh={() => handleBlindCheckinEvent(currentEvent)}
      />
    );
  }

  // Render the user notification center
  return (
    <BadgesSection>
      {/* Render the profile navigation for badges */}
      <TopNav type={NavBarTypes.NOTIFICATIONS} />
      {/* Render the badges container based on the existence of attained or next attainable badges */}
      <BadgesContainer hasBadges={true}>
        {notificationList?.length > 0 && !notifications?.isLoading && (
          <>
            {notifications?.isLoading ? (
              <>
                {Array.from({ length: 12 }).map((_, index) => (
                  <NotificationItem
                    key={index}
                    index={index}
                    notification={{
                      notification_type: "",
                      title: "",
                      description: "",
                      img: "",
                      created_at: "",
                      created_at_offset: "",
                      id: "",
                      read_at: "",
                      subject_type: "",
                      subject_id: "",
                      subject_label: "",
                    }}
                    isLoading={true}
                  />
                ))}
              </>
            ) : (
              <>
                {notificationList.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    index={notification.id}
                    notification={notification}
                    handlePopSelectedItem={handlePopSelectedItem}
                    refetch={notifications?.refetch}
                  />
                ))}
              </>
            )}
            {[NotificationPermissionState.PROMPT].includes(
              notificationPermissionState
            ) && (
              <PartialEmptyState
                icon={
                  <BellRingingIcon
                    stroke={colors.white}
                    width="24px"
                    height="24px"
                    opacity={0.6}
                  />
                }
                description="Turn push notifications on."
                button={
                  <ButtonRect
                    style={{
                      marginTop: "12px",
                    }}
                    onClick={() => {
                      setSheetModalContent(
                        <NotificationModal
                          askNotificationPermission={askNotificationPermission}
                        />
                      );
                      openSheetModal();
                    }}
                  >
                    <BodyText weight="600">Turn on</BodyText>
                  </ButtonRect>
                }
              />
            )}
          </>
        )}
      </BadgesContainer>
      {!notificationList?.length && !notifications?.isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            gap: "12px",
            flex: "1",
            padding: "0 20px",
          }}
        >
          <EmptyState
            icon={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              ) ? (
                <BellRingingIcon
                  stroke={colors.white}
                  width="24px"
                  height="24px"
                  opacity={0.6}
                />
              ) : (
                <ConfettiIcon
                  height={24}
                  width={24}
                  stroke="white"
                  opacity={0.6}
                />
              )
            }
            title={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              )
                ? "Turn push notifications on!"
                : "Make more memories!"
            }
            description={
              <BodyText color={colors.grey}>
                Add events you’re going to, get notified and earn{" "}
                <span style={{ color: colors.green }}>XP.</span>
              </BodyText>
            }
            button={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              ) ? (
                <ButtonRect
                  style={{
                    marginTop: "12px",
                  }}
                  onClick={() => {
                    setSheetModalContent(
                      <NotificationModal
                        askNotificationPermission={askNotificationPermission}
                      />
                    );
                    openSheetModal();
                  }}
                >
                  <BodyText weight="600">Turn on</BodyText>
                </ButtonRect>
              ) : (
                <RectButtonIconText
                  icon={
                    <SearchIcon
                      stroke={theme.colors.white}
                      height={14}
                      width={14}
                    />
                  }
                  text={<BodyText weight="600">Search events</BodyText>}
                  onClick={() => {
                    navigate("/search?tab=event");

                    setTimeout(() => {
                      document.querySelector("input[name=search]")?.focus();
                    }, 100);
                  }}
                />
              )
            }
          />
        </div>
      )}
    </BadgesSection>
  );
}

export default withCheckInContextProvider(NotificationCenter);
