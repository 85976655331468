import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-blend-mode: multiply;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: left;
  padding: 0 24px 24px;
  position: relative;
`;

export const LogoImage = styled.img`
  height: 60px;
  width: 60px;
  background: transparent;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  gap: ${({ gap }) => gap};
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  top: 0px;
  right: 16px;
`;

// Adding global styles to overwrite the modal container's border
export const SheetOverwrite = createGlobalStyle`
  .react-modal-sheet-container {
    border-radius: 0px !important; /* Your custom border style */
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
`;
