import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background: #11111fe5;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
`;

export const ProfileLeftHeader = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const ProfileRightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

export const GreenDot = styled.span`
  width: 4px;
  height: 4px;
  background: rgba(66, 251, 173, 1);
  position: absolute;
  right: 3px;
  top: 4px;
  border-radius: 50%;
`;
