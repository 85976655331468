import { createContext, useState } from 'react';
import { MarketplaceDispatchEvents } from '../constants/dispatchEvents';

export const MarketplaceContext = createContext({});

const initialState = {
  isOpen: false,
  uri: 'base',
};

const MarketplaceContextProvider = (params) => {
  const { children } = params;
  const [context, setContent] = useState(initialState);

  function dispatch(actionType, payload) {
    switch (actionType) {
      case MarketplaceDispatchEvents.TOGGLE_ON:
        {
          setContent((prev) => ({
            ...prev,
            isOpen: !prev.isOpen,
            uri: payload ? payload : 'base'
          }));
        }
        break;
    }
  }

  return (
    <MarketplaceContext.Provider value={{ context, dispatch }}>
      {children}
    </MarketplaceContext.Provider>
  );
}

export default MarketplaceContextProvider;