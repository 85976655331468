import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BodyText, FooterText, IconWithText } from "../../../GlobalStyles";
import {
  Container,
  DescriptionItemsContainer,
} from "../../../routes/Collectible/Collectible-Styles";
import OpenseaIcon from "../../../assets/svg/opensea.svg?react";
import MagicEdenIcon from "../../../assets/svg/magic-eden.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { isProd } from "../../../utils/utils";

export default function TicketUrls({ contractAddress, isLoading }) {
  const openseaUrl = isProd
    ? `https://opensea.io/assets/base/${contractAddress}`
    : `https://testnets.opensea.io/assets/base-sepolia/${contractAddress}`;

  const magicEdenUrl = isProd
    ? `https://magiceden.io/collections/base/${contractAddress}`
    : null;

  if (isLoading) {
    return (
      <Container style={{ gap: "12px" }}>
        <SkeletonElement height="17px" width="150px" />
        <DescriptionItemsContainer style={{ margin: 0 }}>
          <IconWithText>
            <SkeletonElement height="24px" width="100%" />
          </IconWithText>
          <IconWithText>
            <SkeletonElement height="24px" width="100%" />
          </IconWithText>
        </DescriptionItemsContainer>
      </Container>
    );
  }

  return (
    <Container style={{ gap: "12px" }}>
      <BodyText weight="600">Trade your ticket</BodyText>
      <DescriptionItemsContainer style={{ margin: 0 }}>
        {magicEdenUrl && (
          <IconWithText
            style={{ gap: "8px" }}
            as={Link}
            to={magicEdenUrl}
            target="_blank"
          >
            <MagicEdenIcon />
            <FooterText>Magic Eden</FooterText>
          </IconWithText>
        )}
        <IconWithText
          style={{ gap: "8px" }}
          as={Link}
          to={openseaUrl}
          target="_blank"
        >
          <OpenseaIcon />
          <FooterText>Opensea</FooterText>
        </IconWithText>
      </DescriptionItemsContainer>
    </Container>
  );
}

TicketUrls.propTypes = {
  contractAddress: PropTypes.string,
  isLoading: PropTypes.bool,
};
