import { BodyText, FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { TicketHeaderContainer } from "./Ticket-Styles";
import PropTypes from "prop-types";

const { colors } = theme;

export default function TicketHeader({ ticket }) {
  const { type, quantity, remaining } = ticket || {};

  return (
    <TicketHeaderContainer>
      <BodyText weight="600">{type}</BodyText>
      {remaining > 0 && (
        <FooterText color={quantity === remaining ? colors.green : colors.grey}>
          {quantity}/{remaining} selected
        </FooterText>
      )}
    </TicketHeaderContainer>
  );
}

TicketHeader.propTypes = {
  ticket: PropTypes.object,
};
