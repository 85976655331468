import PropTypes from "prop-types";
import { DescriptionItemsContainer } from "../../../routes/Collectible/Collectible-Styles";
import CollectibleDescriptionItem from "../../Collectibles/CollectibleDescriptionItem";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { Container, Content, Header } from "./CollectibleAdditionalInfo-Styles";
import { BodyText } from "../../../GlobalStyles";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { theme } from "../../../utils/theme";
import { useState } from "react";

export default function CollectibleAdditionalInfo({ collectible, isLoading }) {
  const { contract_type, total_supply, royalty, symbol, contract_address } =
    collectible || {};

  const [isOpen, setIsOpen] = useState(false);

  const toggleInfo = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <SkeletonElement height="53px" width="100%" radius="8px" />;
  }

  return (
    <Container isOpen={isOpen}>
      <Header onClick={toggleInfo}>
        <BodyText bold>Additional info</BodyText>
        {isOpen ? (
          <CaretUp color={theme.colors.grey} weight="bold" />
        ) : (
          <CaretDown color={theme.colors.grey} weight="bold" />
        )}
      </Header>
      <Content isOpen={isOpen}>
        <DescriptionItemsContainer
          style={{
            margin: 0,
          }}
        >
          <CollectibleDescriptionItem
            label="Price"
            value="FREE!"
            icon={<USDCIcon height={16} width={16} />}
          />
          {contract_type && (
            <CollectibleDescriptionItem
              label="Edition"
              value={contract_type}
              icon={<EditionIcon />}
            />
          )}
          {(typeof royalty === "number" || typeof royalty === "string") && (
            <CollectibleDescriptionItem
              label="Creator earnings"
              value={`${royalty}%`}
            />
          )}
          {total_supply && (
            <CollectibleDescriptionItem
              label="Total supply"
              value={total_supply}
            />
          )}
          {contract_address && (
            <CollectibleDescriptionItem
              label="Contract address"
              value={`${contract_address.slice(0, 4)}...${contract_address.slice(-4)}`}
            />
          )}
          {symbol && (
            <CollectibleDescriptionItem
              label="Contract symbol"
              value={symbol}
            />
          )}
        </DescriptionItemsContainer>
      </Content>
    </Container>
  );
}

CollectibleAdditionalInfo.propTypes = {
  collectible: PropTypes.object,
  isLoading: PropTypes.bool,
};
