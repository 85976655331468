import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { BodyText } from "../../../GlobalStyles";
import { ArtistContainer } from "../../../routes/Collectible/Collectible-Styles";
import ArtistIcon from "../../Artist/ArtistIcon";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function CollectibleArtist({ artist, isLoading }) {
  const navigate = useNavigate();
  const { artist_name, artist_id, profile_img, banner_img } = artist || {};

  if (isLoading) {
    return (
      <ArtistContainer>
        <SkeletonElement height="40px" width="40px" radius="50%" />
        <SkeletonElement height="24px" width="100px" />
      </ArtistContainer>
    );
  }
  return (
    <ArtistContainer onClick={() => navigate(`/artists/${artist_id}`)}>
      <ArtistIcon imageUrl={profile_img || banner_img} />
      <BodyText truncate>{artist_name}</BodyText>
    </ArtistContainer>
  );
}

CollectibleArtist.propTypes = {
  artist: PropTypes.object,
  isLoading: PropTypes.bool,
};
