import { useContext } from "react";
import { MarketplaceDispatchEvents } from "../constants/dispatchEvents";
import { MarketplaceContext } from "../contexts/MarketplaceContext";

export default function useMarketplace() {
  const { context, dispatch } = useContext(MarketplaceContext);
  const {
    isOpen,
    uri
  } = context;

  const toggleMarketplace = (uri) => {
    console.log({ uri })
    dispatch(MarketplaceDispatchEvents.TOGGLE_ON, uri);
  };

  return {
    toggleMarketplace,
    isOpen,
    uri
  };
}
