import styled from "styled-components";
import { Section } from "../../GlobalStyles";

export const ArtistSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export const ArtistDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  gap: 16px;
  flex: 1;
`;
