import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.blue};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const ButtonWithMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TopUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
