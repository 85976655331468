import { useCheckoutStore } from "../checkoutStore";
import {
  addDelayInMs,
  calculateTotal,
  checkTicketsMinted,
  countAndDeduplicateTickets,
  countTicketsByType,
  sortTickets,
} from "../../../utils/checkout";
import { useGetTicketManagerABI } from "./checkoutQueries";
import { useMemo } from "react";
import usdcJson from "../../../constants/usdc-abi.json";
import { useChainId, useSwitchChain, useWriteContract } from "wagmi";
import { isProd } from "../../../utils/utils";
import {
  USDC_ADDRESS,
  USDC_ADDRESS_SEPOLIA,
} from "../../../constants/constants";
import { base, baseSepolia } from "viem/chains";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import useGlobalModal from "../../../hooks/useGlobalModal";
import PaymentSuccess from "../PaymentSuccess";
import {
  deployMetadata,
  getTicketsMinted,
  insertTicketsMinted,
} from "../../../api/ticketing";
import useAuth from "../../../hooks/useAuth";
import useAccountKit from "../../../hooks/useAccountKit";
import { encodeFunctionData } from "viem";
import moment from "moment";

export const useTicketCheckout = () => {
  const { loggedInUser } = useAuth();

  const { ticketsSelected, contract, event } = useBuyTicketStore();
  const {
    ticketsTotal,
    momentifyFee,
    setIsMinting,
    setTxnHash,
    setBoughtTickets,
  } = useCheckoutStore();
  const { alchemyProvider } = useAccountKit();
  const { primaryWallet, receivingWallet } = usePrimaryWallet();
  const { data: ticketManagerABI } = useGetTicketManagerABI();
  const { writeContractAsync } = useWriteContract();
  const chain = useChainId();
  const { switchChain } = useSwitchChain();
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  const {
    id: event_id,
    headline_artist_id,
    headline_artist,
    event_name,
    venue,
    date: event_date,
  } = event || {};
  const { estimatedGasPrice, contractAddress, image, symbol } = contract || {};

  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainTobeUsed = isProd ? base : baseSepolia;

  const { parsedTotal } = useMemo(() => {
    if (!ticketsTotal) return { formattedTotal: "0", parsedTotal: 0 };

    const total = calculateTotal({
      ticketsTotal,
      momentifyFee,
      gas: estimatedGasPrice,
    });

    return total;
  }, [ticketsTotal, momentifyFee, estimatedGasPrice]);

  const buy = async () => {
    try {
      if (!primaryWallet) return;

      setIsMinting(true);

      const { GA, VIP } = countTicketsByType(ticketsSelected);

      if (!GA && !VIP) {
        setIsMinting(false);
        return;
      }

      // Switch chain if not on the correct chain
      if (isProd && chain !== base.id) {
        switchChain({ chainId: base.id });
        await primaryWallet.switchChain(base.id);
      } else if (!isProd && chain !== baseSepolia.id) {
        switchChain({ chainId: baseSepolia.id });
        await primaryWallet.switchChain(baseSepolia.id);
      }

      console.log("Starting Approval...");

      const usdcApprovalTxn = await writeContractAsync({
        address: USDC,
        abi: usdcJson.abi,
        functionName: "approve",
        args: [contractAddress, parsedTotal],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!usdcApprovalTxn) {
        setIsMinting(false);
        console.warn("Failed to approve USDC");
        return;
      }

      console.log("USDC Approved");
      console.log("Starting Batch Mint...");

      const batchMintTxn = await writeContractAsync({
        address: contractAddress,
        abi: ticketManagerABI,
        functionName: "batchMint",
        args: [primaryWallet.address, GA, VIP],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!batchMintTxn) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        return;
      }

      console.log("Batch Minted:", { batchMintTxn });

      setTxnHash(batchMintTxn);

      const boughtTickets = countAndDeduplicateTickets(ticketsSelected);

      setBoughtTickets(boughtTickets);

      console.log("Getting Tickets Minted...");

      const { data: batchMintData, success: getTicketsSuccess } =
        await getTicketsMinted({
          owner: primaryWallet.address,
          contractAddress: contractAddress,
          txn: batchMintTxn,
        }).catch((error) => {
          console.error(error);
          return false;
        });

      if (!getTicketsSuccess || !batchMintData || batchMintData?.length < 1) {
        setIsMinting(false);
        console.warn("Failed to get minted tickets");
        return;
      }

      console.log("Tickets Minted:", { batchMintData });
      console.log("Inserting Tickets Minted...");

      const { success, data } =
        (await insertTicketsMinted({
          tickets: boughtTickets,
          userId: loggedInUser?.id,
          txnHash: batchMintTxn,
          mints: batchMintData || [],
        })) || {};

      if (!success || !data || data?.length < 1) {
        setIsMinting(false);
        console.warn("Failed to insert tickets minted");
        return;
      }

      toggleSheetModal();
      setSheetModalContent(<PaymentSuccess />);
      await addDelayInMs(500);
      toggleSheetModal();

      console.log("Tickets Inserted:", data);
      setIsMinting(false);
    } catch (error) {
      console.error(error);
      setIsMinting(false);
    } finally {
      setIsMinting(false);
    }
  };

  const buyForFree = async () => {
    try {
      if (
        !ticketsSelected ||
        !ticketsSelected.length ||
        !loggedInUser ||
        !primaryWallet ||
        !receivingWallet ||
        !alchemyProvider
      )
        return;

      const hasPrice = ticketsSelected.some((ticket) => ticket.price > 0);

      if (hasPrice) return;

      setIsMinting(true);

      const tickets = countAndDeduplicateTickets(ticketsSelected).map(
        ({ type, count }) => ({
          tierName: type,
          numTokens: count,
        })
      );

      // Input Data
      const inputData = encodeFunctionData({
        abi: ticketManagerABI,
        functionName: "batchMint",
        args: [
          contractAddress, // Tickets Contract
          receivingWallet.address, // Wallet Address of User
          tickets, // Selected Tickets
          isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA, // USDC
        ],
      });

      const sortedTickets = sortTickets(ticketsSelected);

      await deployMetadata({
        metadata: {
          artistId: headline_artist_id,
          eventId: event_id,
          contractAddress: contractAddress,
          name: event_name,
          symbol: symbol,
          image: image,
          description: `Ticket for ${event_name} by ${headline_artist}`,
          attributes: sortedTickets.map(({ type }) => {
            return {
              data: [
                {
                  trait_type: "Event name",
                  value: event_name,
                },
                {
                  trait_type: "Artist name",
                  value: headline_artist,
                },
                {
                  trait_type: "Venue name",
                  value: venue,
                },
                {
                  trait_type: "Date",
                  value: moment(event_date).format("Do MMM YYYY"),
                },
                {
                  trait_type: "Event start time",
                  value: moment(event_date).format("HH:mm"),
                },
                {
                  trait_type: "Ticket type",
                  value: type,
                },
                {
                  trait_type: "User id",
                  value: loggedInUser?.id,
                },
              ],
            };
          }),
        },
      }).then(() => {
        console.log("Metadata deployed");
      });

      // Send the user operation
      const userOperation = await alchemyProvider.sendUserOperation({
        uo: {
          target: import.meta.env.VITE_TICKETS_MANAGER_ADDRESS, // The desired target contract address
          data: inputData, // The desired call data
        },
      });

      // Wait for the user operation to be mined
      const txnHash = await alchemyProvider.waitForUserOperationTransaction({
        hash: userOperation.hash,
      });

      // const txnHash = await smartWallet.sendTransaction({
      //   account: smartWallet.account,
      //   to: import.meta.env.VITE_TICKETS_MANAGER_ADDRESS, // The desired target contract address
      //   chain: isProd ? base : baseSepolia,
      //   data: inputData,
      //   value: 0,
      //   maxPriorityFeePerGas: parseGwei("0.0015"),
      //   maxFeePerGas: parseGwei("0.0015"),
      // });

      console.log("Transaction Hash:", txnHash);

      if (!txnHash) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        return;
      }

      const boughtTickets = countAndDeduplicateTickets(ticketsSelected);

      const { data: batchMintData, success: getTicketsSuccess } =
        await checkTicketsMinted(
          receivingWallet.address,
          contractAddress,
          txnHash
        ).catch((error) => {
          console.error("Failed to get minted tickets:", error);
          return { success: false, data: null };
        });

      if (!getTicketsSuccess || !batchMintData || !batchMintData?.length) {
        throw new Error("Failed to get minted tickets");
      }

      const { success, data } =
        (await insertTicketsMinted({
          tickets: boughtTickets,
          userId: loggedInUser?.id,
          txnHash: txnHash,
          mints: batchMintData || [],
        })) || {};

      if (!success || !data || !data?.length) {
        setIsMinting(false);
        console.warn("Failed to insert tickets minted");
        return;
      }

      setBoughtTickets(boughtTickets);

      toggleSheetModal();
      setSheetModalContent(<PaymentSuccess />);
      await addDelayInMs(500);
      toggleSheetModal();

      console.log("Tickets Minted Inserted to DB");
      setIsMinting(false);
    } catch (error) {
      console.error(error);
      setIsMinting(false);
    } finally {
      setIsMinting(false);
    }
  };

  return { buy, buyForFree };
};
