import { ArrowUpRight, ToggleLeft, ToggleRight } from "@phosphor-icons/react";
import { useLinkAccount, usePrivy } from "@privy-io/react-auth";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { BodyText, FooterText } from "../../GlobalStyles";
import { setEmailLinkAction, setFarcasterLinkAction } from "../../api/profile";
import SupportIcon from "../../assets/svg/emoji-happy.svg?react";
import LockUnlockedIcon from "../../assets/svg/lock-unlocked.svg?react";
import LockIcon from "../../assets/svg/lock.svg?react";
import LogoutIcon from "../../assets/svg/logout.svg?react";
import Mail from "../../assets/svg/mail.svg?react";
import Farcaster from "../../assets/svg/farcaster.svg?react";
import ProfileIcon from "../../assets/svg/profile.svg?react";
import TelephoneIcon from "../../assets/svg/telephone.svg?react";
import Pin from "../../assets/svg/pin-white.svg?react";
import Bell from "../../assets/svg/bell-ringing.svg?react";
import { UsernameTypes } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";
import useGlobalModal from "../../hooks/useGlobalModal";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import {
  forceClearCinnyKeys,
  isCinnyAuthenticated,
  isCinnySupported,
} from "../../utils/cinny-utils";
import { theme } from "../../utils/theme";
import { getUsernameByType } from "../../utils/username";
import LinkEmailModal from "../Modal/LinkEmailModal";
import { Location } from "./Location";
import SettingsOption from "./SettingsOption";
import useGeolocationPermission from "../../routes/Events/hooks/geoLocationHooks";
import useNotification from "../../hooks/useNotification";
import WalletOption from "./WalletOption";
import InviteCategory from "./InviteCategory";

const { fontSizes, colors } = theme;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const CategoryHeader = styled(BodyText)`
  margin-bottom: 4px;
`;

const Line = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  opacity: 0.5;
  margin: 12px 0;
`;

export default function SettingsCategories() {
  const { user } = usePrivy();
  const { logout: momentifyLogOut } = useMomentifyLogin();
  const { loggedInUser, updateCurrentUser } = useAuth();
  const [matrixLoggedIn, setMatrixLoggedIn] = useState(isCinnyAuthenticated());
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const permState = useGeolocationPermission();
  const { isNotificationPermitted } = useNotification();
  const queryClient = useQueryClient();

  // const { requestFarcasterSignerFromWarpcast } = useFarcasterSigner();

  const username = getUsernameByType(loggedInUser, UsernameTypes.USERNAME);
  const mobileNumber = loggedInUser?.mobile_number;
  const iconSize = 16;
  const farcasterFID = user?.farcaster?.fid;
  const email = user?.email?.address ?? user?.google?.email;

  useEffect(() => {
    if (loggedInUser.email == null && email) {
      updateCurrentUser({
        email: email,
      });
    }
  }, [email, loggedInUser]);

  useEffect(() => {
    if (!farcasterFID) return;

    if (loggedInUser.farcaster_id == null) {
      updateCurrentUser({
        farcaster_id: farcasterFID,
      });
    }
  }, [farcasterFID, loggedInUser]);

  const { linkEmail, linkGoogle, linkFarcaster } = useLinkAccount({
    onSuccess: async (user, linkMethod) => {
      if (linkMethod == "farcaster") {
        console.log("farcaster linked");
        const actionResult = await setFarcasterLinkAction({
          user_id: loggedInUser.id,
        });
        if (actionResult?.success) {
          toast.success(`Farcaster linked +${actionResult?.data?.xp?.xp}xp`, {
            toastId: "linkfarcaster-action-success",
          });
        }

        return;
      }
      // Any logic you'd like to execute if the user successfully links an account while this
      // component is mounted
      const actionResult = await setEmailLinkAction({
        user_id: loggedInUser.id,
      });
      if (actionResult?.success) {
        toast.success(`Email linked +${actionResult?.data?.xp?.xp}xp`, {
          toastId: "linkemail-action-success",
        });
      }
    },
    onError: (error) => {
      console.error(error);
      // Any logic you'd like to execute after a user exits the link flow or there is an error
    },
  });

  const matrixLogOut = async () => {
    try {
      if (isCinnySupported()) {
        import("../../utils/federated-cinny-utils").then(async (module) => {
          if (module.InitMatrixFunc.isClientRunning()) {
            await module.InitMatrixFunc.logout({
              reloadOnLogout: false,
              clearCinnyKeysOnly: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 100);
          } else {
            forceClearCinnyKeys();
          }
        });
      }
    } catch (error) {
      console.error({ logoutError: error });
    }
  };

  const handleChangeVisibility = async () => {
    await updateCurrentUser({
      private: !loggedInUser?.private,
    });

    queryClient.removeQueries({
      queryKey: ["performance"],
    });
  };

  const handleLinkEmailModal = async () => {
    setSheetModalContent(
      <LinkEmailModal linkGoogle={linkGoogle} linkEmail={linkEmail} />
    );
    toggleSheetModal();
  };

  const categories = [
    {
      name: "Profile",
      options: [
        {
          name: "Username",
          leftIcon: (
            <ProfileIcon
              height={iconSize}
              width={iconSize}
              stroke="white"
              strokeWidth={1.5}
            />
          ),
          rightIcon: <BodyText color={colors.grey}>{username}</BodyText>,
        },
        {
          name: "Phone Number",
          leftIcon: (
            <TelephoneIcon height={iconSize} width={iconSize} stroke="white" />
          ),
          rightIcon: (
            <BodyText color={colors.grey}>
              {mobileNumber ?? "No phone number"}
            </BodyText>
          ),
        },
        {
          name: "Private Account",
          leftIcon: loggedInUser?.private ? (
            <LockIcon height={iconSize} width={iconSize} stroke="white" />
          ) : (
            <LockUnlockedIcon
              height={iconSize}
              width={iconSize}
              stroke="white"
            />
          ),
          rightIcon: loggedInUser?.private ? (
            <ToggleRight size={28} color={colors.green} weight="fill" />
          ) : (
            <ToggleLeft size={28} color={colors.white} />
          ),
          onClick: () => handleChangeVisibility(),
        },
        {
          name:
            user?.email?.address || user?.google?.email ? (
              "Email"
            ) : (
              <BodyText color={colors.white}>
                Link Email
                <BodyText
                  color={colors.softGreen}
                  style={{ marginLeft: "10px" }}
                >
                  +50xp
                </BodyText>
              </BodyText>
            ),
          leftIcon: <Mail height={iconSize} width={iconSize} stroke="white" />,
          rightIcon:
            user?.email?.address || user?.google?.email ? (
              <BodyText color={colors.grey}>
                {user?.email?.address ?? user?.google?.email}
              </BodyText>
            ) : (
              <ArrowUpRight size="24px" color={colors.grey} />
            ),
          onClick: () =>
            user?.email?.address || user?.google?.email
              ? null
              : handleLinkEmailModal(),
        },
        {
          name: "Wallet",
        },
      ],
    },
    {
      name: "Connect socials",
      options: [
        {
          leftIcon: (
            <BodyText color={colors.grey}>
              Import artists, improve your app experience.
            </BodyText>
          ),
        },
        // {
        //   leftIcon: (
        //     <Instagram
        //       style={{
        //         position: "relative",
        //         bottom: "1px",
        //       }}
        //       height={iconSize}
        //       width={iconSize}
        //       stroke="white"
        //     />
        //   ),
        //   name: (
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "row",
        //         gap: 6,
        //         flexGrow: 1,
        //       }}
        //     >
        //       <BodyText
        //         style={{
        //           marginLeft: "-2px",
        //         }}
        //         color={colors.white}
        //       >
        //         Link Instagram
        //       </BodyText>
        //       <BodyText
        //         style={{
        //           marginLeft: "-2px",
        //         }}
        //         color={colors.grey}
        //       >
        //         up to
        //       </BodyText>
        //       <BodyText
        //         style={{
        //           marginLeft: "-2px",
        //         }}
        //         color={colors.green}
        //       >
        //         +1000xp
        //       </BodyText>
        //       <BodyText
        //         style={{
        //           marginLeft: "-2px",
        //         }}
        //         color={colors.grey}
        //       >
        //         and more!
        //       </BodyText>
        //     </div>
        //   ),
        //   rightIcon: (
        //     <ArrowUpRight
        //       style={{
        //         position: "relative",
        //         bottom: "1px",
        //       }}
        //       onClick={linkInstagram}
        //       size="24px"
        //       color={colors.grey}
        //     />
        //   ),
        // },
        {
          leftIcon: (
            <Farcaster
              style={{
                position: "relative",
                bottom: "1px",
              }}
              height={iconSize}
              width={iconSize}
              stroke="white"
            />
          ),
          name: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 6,
                flexGrow: 1,
              }}
            >
              <BodyText style={{}} color={colors.white}>
                {farcasterFID ? "Farcaster" : "Link Farcaster"}
              </BodyText>
              {!farcasterFID && (
                <BodyText
                  style={{
                    marginLeft: "6px",
                  }}
                  color={colors.green}
                >
                  +200xp
                </BodyText>
              )}
            </div>
          ),
          rightIcon: !farcasterFID ? (
            <ArrowUpRight
              style={{
                position: "relative",
                bottom: "1px",
              }}
              onClick={linkFarcaster}
              size="24px"
              color={colors.grey}
            />
          ) : (
            <BodyText color={colors.grey}>{"FID: " + farcasterFID}</BodyText>
          ),
        },
      ],
    },
    {
      name: "Location",
      options: [{}],
    },
    {
      name: "Invite",
      options: [{}],
    },
    {
      name: "Permissions",
      description:
        "Open your device or browser settings to edit their settings.",
      options: [
        {
          name: "Share location",
          leftIcon: <Pin height={iconSize} width={iconSize} stroke="white" />,
          rightIcon: (
            <FooterText color={colors.grey}>
              {permState === "granted" ? "On" : "Off"}
            </FooterText>
          ),
          bottomText: (
            <FooterText color={colors.grey}>
              Share your location to check in to and get better event
              recommendations.
            </FooterText>
          ),
        },
        {
          name: "Push Notifications",
          leftIcon: <Bell height={iconSize} width={iconSize} stroke="white" />,
          rightIcon: (
            <FooterText color={colors.grey}>
              {isNotificationPermitted ? "On" : "Off"}
            </FooterText>
          ),
          bottomText: (
            <FooterText color={colors.grey}>
              Receive alerts even when you are not using the app.
            </FooterText>
          ),
        },
      ],
    },
    {
      name: "Miscellaneous",
      options: [
        {
          name: "Support",
          leftIcon: (
            <SupportIcon height={iconSize} width={iconSize} stroke="white" />
          ),
          rightIcon: (
            <Link to="https://t.me/+RKqEzEoDwCExNzk8" target="_blank">
              <BodyText secondary>Speak to Support</BodyText>
            </Link>
          ),
        },
        {
          name: "Logout",
          leftIcon: (
            <LogoutIcon height={iconSize} width={iconSize} stroke="white" />
          ),
          onClick: () => {
            momentifyLogOut();
            if (matrixLoggedIn) matrixLogOut();
          },
        },
      ],
    },
  ];

  const noHeaderCategories = ["Miscellaneous", "Line", "Location", "Invite"];

  useEffect(() => {
    setMatrixLoggedIn(isCinnyAuthenticated());
  }, [loggedInUser]);

  // This is good, but very hard to edit when adding new designs.
  return categories.map((category, index) => (
    <CategoryContainer key={index}>
      {!noHeaderCategories.includes(category.name) && (
        <CategoryHeader size={fontSizes.mobile.body} color="white" weight="600">
          {category.name}
        </CategoryHeader>
      )}
      {category.description && (
        <FooterText color={colors.grey} style={{ margin: "6px 0" }}>
          {category.description}
        </FooterText>
      )}

      {category?.options?.map((option, index) => {
        if (category.name == "Location") return <Location key={index} />;
        if (category.name == "Invite") return <InviteCategory key={index} />;
        if (option.name == "Wallet") return <WalletOption key={index} />;

        return (
          <SettingsOption
            key={index}
            optionIcon={option.leftIcon}
            option={option.name}
            rightIcon={option.rightIcon}
            onClick={option.onClick}
            bottomText={option.bottomText}
          />
        );
      })}
      {index !== categories.length - 1 && <Line />}
    </CategoryContainer>
  ));
}
