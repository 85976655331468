import styled from "styled-components";
import { TopNavBarHeight } from "../../constants/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

export const TabContainer = styled.div`
  display: flex;
  position: sticky;
  top: ${TopNavBarHeight};
  left: 0;
  background: ${({ blur }) => (!blur ? "transparent" : "#11111FE5")};
  backdrop-filter: ${({ blur }) => (!blur ? "none" : "blur(5px)")};
  -webkit-backdrop-filter: ${({ blur }) => (!blur ? "none" : "blur(5px)")};
  transition: 0.3s ease;
  z-index: 99;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: ${({ theme, active }) => (active ? "white" : theme.colors.grey)};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  flex-wrap: nowrap;
  flex: 1;
  border: none;
  padding: 12px;
  gap: 12px;
  border-bottom: 1px solid
    ${({ active }) => (active ? "white" : "rgba(255, 255, 255, 0.15)")};

  svg {
    stroke: ${({ theme, active }) => (active ? "white" : theme.colors.grey)};
    fill: ${({ active, type }) =>
      active && type !== "artist" ? "white" : "none"};
  }
`;

export const TabComponentContainer = styled.div`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  gap: 12px;
  flex: 1;
  padding-bottom: 16px;
`;
