import PropTypes from "prop-types";
import { BodyText, ButtonRect, HeaderL } from "../../../GlobalStyles";
import { Container, HeaderContainer } from "./SetWalletModal-Styles";
import WalletIcon from "../../../assets/svg/wallet.svg?react";
import { theme } from "../../../utils/theme";
import useAuth from "../../../hooks/useAuth";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useTabsStore } from "../../Tabs/tabsStore";
import { useState } from "react";
import { usePrivy } from "@privy-io/react-auth";

export default function SetWalletModal({ wallet, isUnlink }) {
  const { address, connected, type, walletClientType } = wallet || {};

  const [isLoading, setIsLoading] = useState(false);
  const { updateCurrentUser } = useAuth();
  const { activeTab } = useTabsStore();
  const { toggleSheetModal } = useGlobalModal();
  const { unlinkWallet } = usePrivy();

  const isPay = activeTab === "pay" || !activeTab;

  const setPrimaryWallet = async () => {
    try {
      setIsLoading(true);

      if (isPay) {
        await updateCurrentUser({
          embedded_wallet_id: address,
        });
      } else {
        await updateCurrentUser({
          receiving_wallet: address,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      toggleSheetModal();
    }
  };

  const unlinkAndDisconnectWallet = async () => {
    if (type === "smart_wallet" || walletClientType === "privy") return;

    try {
      setIsLoading(true);
      await unlinkWallet(address);
      connected &&
        walletClientType !== "metamask" &&
        (await wallet?.disconnect());
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
      toggleSheetModal();
    }
  };

  const handleConfirm = () => {
    if (isUnlink) {
      unlinkAndDisconnectWallet();
    } else {
      setPrimaryWallet();
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <WalletIcon height={24} width={24} stroke={theme.colors.green} />
        <HeaderL style={{ textTransform: "uppercase" }}>
          {isUnlink
            ? "Unlink Wallet"
            : `Make ${isPay ? "payment" : "receiving"} wallet`}
        </HeaderL>
      </HeaderContainer>
      <BodyText secondary>
        {isUnlink
          ? "Are you sure you want to unlink this wallet?"
          : `Use this wallet for ${isPay ? "paying" : "receiving"} tickets and collectibles.`}
      </BodyText>
      <ButtonRect primary onClick={handleConfirm} disabled={isLoading}>
        {isLoading ? "Processing..." : "Confirm"}
      </ButtonRect>
    </Container>
  );
}

SetWalletModal.propTypes = {
  wallet: PropTypes.object,
  isUnlink: PropTypes.bool,
};
