import { useEffect, useState } from "react";
import { SEARCH_PARAMS } from "../../../constants/search";

const {
  QUERY,
  TAB,
  COUNTRY,
  CITY,
  ARTIST,
  VENUE,
  YEAR,
  MONTH,
  RETRO,
  PAST,
  LOCATION_PICKER,
  QUICK_SEARCH,
} = SEARCH_PARAMS;

/**
 * Get search params
 * @param {Object} searchParams
 * @typedef {Object} SearchParams
 * @property {string} query
 * @property {string} tab
 * @property {string} country
 * @property {string} city
 * @property {string} artist
 * @property {string} venue
 * @property {string} year
 * @property {string} month
 * @property {string} isRetro
 * @property {string} isLocationPickerOpen
 * @property {string} isPast
 * @property {string} quickSearch
 * @returns {SearchParams}
 */
export const useSearchParamsHook = ({ searchParams }) => {
  const [params, setParams] = useState({
    query: searchParams.get(QUERY),
    tab: searchParams.get(TAB),
    country: searchParams.get(COUNTRY),
    city: searchParams.get(CITY),
    artist: searchParams.get(ARTIST),
    venue: searchParams.get(VENUE),
    year: searchParams.get(YEAR),
    month: searchParams.get(MONTH),
    isRetro: searchParams.get(RETRO) === "true",
    isPast: searchParams.get(PAST) === "true",
    quickSearch: searchParams.get(QUICK_SEARCH) === "true",
    isLocationPickerOpen: searchParams.get(LOCATION_PICKER) === "true",
  });

  useEffect(() => {
    setParams({
      query: searchParams.get(QUERY),
      tab: searchParams.get(TAB),
      country: searchParams.get(COUNTRY),
      city: searchParams.get(CITY),
      artist: searchParams.get(ARTIST),
      venue: searchParams.get(VENUE),
      year: searchParams.get(YEAR),
      month: searchParams.get(MONTH),
      isRetro: searchParams.get(RETRO) === "true",
      isPast: searchParams.get(PAST) === "true",
      quickSearch: searchParams.get(QUICK_SEARCH) === "true",
      isLocationPickerOpen: searchParams.get(LOCATION_PICKER) === "true",
    });
  }, [searchParams]);

  return { ...params };
};
