import { useQuery } from "@tanstack/react-query";
import { getRoom } from "../../../utils/matrix-utils";
import { eventLoader } from "../loader";
import {
  getEventTickets,
  getEventTicketsEstimate,
} from "../../../api/ticketing";

export const useEventLoader = ({
  eventId,
  userId,
  date,
  latitude,
  longitude,
}) => {
  return useQuery({
    queryKey: ["event", eventId, userId],
    queryFn: async () => {
      const event = await eventLoader({
        eventId,
        userId,
        date,
        latitude,
        longitude,
      });

      return event;
    },
  });
};

export const useGetRoom = ({ room = null }) => {
  return useQuery({
    queryKey: ["room", room?.id],
    queryFn: async () => {
      const fetchedRoom = await getRoom(room);

      return fetchedRoom ?? [];
    },
    staleTime: 1000 * 60 * 3,
    enabled: room != null,
  });
};

export const useGetEventTickets = ({ eventId, artistId }) => {
  return useQuery({
    queryKey: ["eventTickets", eventId, artistId],
    queryFn: async () => {
      const tickets = await getEventTickets({ eventId });

      if (!tickets.success || !tickets.data || tickets.data.length === 0) {
        return { tickets: [], contract: {} };
      }

      const contract = await getEventTicketsEstimate({
        eventId,
        artistId,
      });

      return { tickets: tickets.data, contract };
    },
    enabled: !!eventId && !!artistId,
  });
};
