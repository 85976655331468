import { useParams, useNavigate } from "react-router";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect, useState } from "react";
import LockIcon from "../../assets/svg/lock.svg?react";
import HintIcon from "../../assets/svg/hint.svg?react";
import ClaimIcon from "../../../public/svgs/claimicon.svg?react";
import WalletIcon from "../../../public/svgs/walleticon.svg?react";
import LoadingScreen from "../../components/LoadingScreen";
import ProfileDetails from "../../components/Profile/ProfileDetails";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
import useAuth from "../../hooks/useAuth";
import useGlobalModal from "../../hooks/useGlobalModal";
import ErrorPage from "../Error";
import { useFollowerQuery } from "../Followers/hooks/followersQueries";
import { useFollowing } from "../Following/hooks/followingQueries";
import { ProfileContainer, ProfileSection } from "./Profile-Styles";
import {
  useAlchemyQuery,
  useUserCollectibles,
  useUserEvents,
  useUserMessages,
  useUserMoments,
  useUserNotifications,
  useUserStats,
  useUserTickets,
} from "./hooks/profileQueries";
import EmptyState from "../../components/EmptyState";
import EphemeralTips from "../../components/Tips/EphemeralTips";
import { theme } from "../../utils/theme";
import { FooterText } from "../../GlobalStyles";
import { EPHEMERAL_TIPS } from "../../constants/constants";
import useNotification from "../../hooks/useNotification";
import { NotificationPermissionState } from "../../enums/permission";
import { useUserStore } from "./store/userStore";
import ClaimRewardModal from "../../components/Modal/ClaimRewardModal";
import { EphemeralTipButton } from "../../components/EphemeralTipButton";
import {
  useRewardXP,
  useUpdateUserXPStatus,
} from "../../components/Modal/ClaimRewardModal/hooks/claimRewardQueries";
import Onboarding from "../../components/Onboarding";
import { useSearchParams } from "react-router-dom";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

/**
 * Profile component to display user profile information
 */
export default function Profile() {
  const { id: userId } = useParams();
  const [searchParams] = useSearchParams();
  const { ready, user } = usePrivy();
  const { loggedInUser } = useAuth();
  const { notificationPermissionState } = useNotification();
  const { openSheetPWAModal, setSheetModalContent, openSheetModal } =
    useGlobalModal();
  const navigate = useNavigate();
  const [randomizer] = useState(Math.floor(Math.random() * 3));
  const { externalMints, fidMints, unrewardedGuessXP } = useUserStore();
  const { handleTracking } = useMixpanelTracker();

  const unrewardedExternalMints = externalMints?.find(
    (mint) => mint.is_rewarded == false
  );

  const totalUnrewardedXP = unrewardedGuessXP?.reduce(
    (acc, guess) => acc + guess.xp,
    0
  );

  const isOwnProfile = loggedInUser?.id ? loggedInUser?.id == userId : false;

  const userStatsQuery = useUserStats({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userMomentsQuery = useUserMoments({
    userId: userId ?? "",
    isOwnProfile: true,
  });
  const userEventsQuery = useUserEvents({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userCollectiblesQuery = useUserCollectibles({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userTicketsQuery = useUserTickets({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userNotificationsQuery = useUserNotifications({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: isOwnProfile,
  });

  const userMessagesQuery = useUserMessages({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: isOwnProfile,
  });

  useAlchemyQuery();

  useFollowing({
    userId,
    enabled: !(userStatsQuery?.data?.private ?? true) || isOwnProfile,
  });

  useFollowerQuery({
    id: userId,
    enabled: !(userStatsQuery?.data?.private ?? true) || isOwnProfile,
  });

  // Open global modal after loading
  useEffect(() => {
    handleTracking("Visited Profile Page", {
      "User ID": loggedInUser?.id,
      "Profile ID": userId,
    });
    openSheetPWAModal();
  }, []);

  const { mutateAsync: rewardXP } = useRewardXP();
  const { mutateAsync: updateUserXPStatus } = useUpdateUserXPStatus();
  const handleClickClaimUnrewardedXP = async () => {
    console.log("totalUnrewardedXP", totalUnrewardedXP);
    rewardXP({
      loggedInUserId: loggedInUser.id,
      action_type: "game_xp",
      xp: totalUnrewardedXP,
      custom_message: "from playing our guess games!",
    });
    updateUserXPStatus({ fid: loggedInUser?.farcaster_id });
  };

  const getEphemeralTipMessage = () => {
    // // TODO: Make dynamic based on reward
    if (unrewardedExternalMints)
      return (
        <>
          <EphemeralTipButton
            icon={<ClaimIcon height={12} width={12} />}
            text="Claim"
            onClickFunction={() => {
              setSheetModalContent(
                <ClaimRewardModal externalMint={unrewardedExternalMints} />
              );
              openSheetModal();
            }}
          />
          <FooterText style={{ marginLeft: -4 }} color={theme.colors.grey}>
            your reward for claiming your First Fan badge!
          </FooterText>
        </>
      );

    if (fidMints && fidMints?.length > 0)
      return (
        <>
          <EphemeralTipButton
            icon={<WalletIcon height={12} width={12} />}
            text="Connect Wallet"
            onClickFunction={() => navigate("/profile/settings")}
          />
          <FooterText color={theme.colors.grey}>
            to receive First Fan badge rewards!
          </FooterText>
        </>
      );

    if (totalUnrewardedXP > 0) {
      return (
        <>
          <EphemeralTipButton
            icon={<ClaimIcon height={12} width={12} />}
            text="Claim"
            onClickFunction={handleClickClaimUnrewardedXP}
          />
          <FooterText style={{ marginLeft: -4 }} color={theme.colors.grey}>
            your reward of{" "}
            <FooterText color={theme.colors.green}>
              {totalUnrewardedXP}xp
            </FooterText>{" "}
            from playing our game/s!
          </FooterText>
        </>
      );
    }

    if (randomizer === 0 && !loggedInUser?.location) {
      return (
        <FooterText color={theme.colors.grey}>
          <FooterText
            color={theme.colors.grey}
            style={{ textDecoration: "underline" }}
            onClick={() => navigate("/profile/settings")}
          >
            Add your home city
          </FooterText>{" "}
          for better recommendations.{" "}
          <FooterText color={theme.colors.green}>+50xp</FooterText>
        </FooterText>
      );
    } else if (
      randomizer === 1 &&
      !user?.email?.address &&
      !user?.google?.email
    ) {
      return (
        <FooterText color={theme.colors.grey}>
          <FooterText
            color={theme.colors.grey}
            style={{ textDecoration: "underline" }}
            onClick={() => navigate("/profile/settings")}
          >
            Add your email
          </FooterText>{" "}
          to receive updates and benefits.{" "}
          <FooterText color={theme.colors.green}>+50xp</FooterText>
        </FooterText>
      );
    } else if (
      randomizer === 2 &&
      [NotificationPermissionState.PROMPT]?.includes(
        notificationPermissionState
      )
    ) {
      return (
        <FooterText color={theme.colors.grey}>
          Turn on push notifications to receive alerts even when you are not
          using the app.
        </FooterText>
      );
    }
    return null;
  };

  // Display loading screen if data is not ready
  if (!ready) return <LoadingScreen />;

  if (userStatsQuery?.data?.length === 0)
    return <ErrorPage description={"We can't find this user."} />;

  // Render the profile section with user information
  return (
    <ProfileSection>
      <ProfileContainer>
        <ProfileHeader
          user={userStatsQuery?.data}
          hasNotification={
            userNotificationsQuery?.data?.filter((row) => !row.read_at)
              ?.length > 0
          }
          hasMessages={
            userMessagesQuery?.data?.filter((row) => !row.read_at)?.length > 0
          }
          isStatsLoading={userStatsQuery?.isLoading}
          isNotificationLoading={userNotificationsQuery?.isLoading}
          isMessagesLoading={userMessagesQuery?.isLoading}
        />
        <ProfileDetails
          user={userStatsQuery?.data}
          isStatsLoading={userStatsQuery?.isLoading}
          isPrivate={userStatsQuery?.data?.private}
        />
        {!userStatsQuery?.isLoading && isOwnProfile && (
          <EphemeralTips
            icon={
              <HintIcon
                fill={theme.colors.green}
                height={12}
                width={12}
                style={{
                  position: "relative",
                  top: 2,
                }}
              />
            }
            message={getEphemeralTipMessage()}
            type={EPHEMERAL_TIPS.HOME_TIP_PROFILE}
          />
        )}
        {/* Display private account message or profile tabs based on user data */}
        {loggedInUser?.id !== userStatsQuery?.data?.id &&
        userStatsQuery?.data?.private ? (
          <EmptyState
            icon={<LockIcon height="25px" width="21.5px" stroke="white" />}
            title="This account is private"
            description="This user has chosen not to share their profile in public."
          />
        ) : (
          <ProfileTabs
            user={userStatsQuery?.data}
            events={userEventsQuery?.data}
            moments={userMomentsQuery?.data}
            collectibles={userCollectiblesQuery?.data}
            tickets={userTicketsQuery?.data}
            isMomentsLoading={userMomentsQuery?.isLoading}
            isEventsLoading={userEventsQuery?.isLoading}
            isCollectiblesLoading={userCollectiblesQuery?.isLoading}
            isTicketsLoading={userTicketsQuery?.isLoading}
          />
        )}
      </ProfileContainer>
      {(searchParams.get("onboard") == "true" ||
        searchParams.get("from-login") == "true") && <Onboarding />}
    </ProfileSection>
  );
}
