import { styled } from "styled-components";
import { Section } from "../../GlobalStyles";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SettingsCategories from "../../components/Settings/SettingsCategory";
import { NavBarTypes } from "../../constants/constants";
import LoadingScreen from "../../components/LoadingScreen";
import AppVersion from "../../components/Settings/AppVersion";
import TopNav from "../../components/TopNav";
import useGlobalModal from "../../hooks/useGlobalModal";
import useMarketplace from "../../hooks/useMarketplace";

const SettingsSection = styled(Section)`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.button`
display: grid;
place-content: center;
padding: 6px;
border: none;
background: transparent;
`;

export default function Market() {
  const { user, ready, getAccessToken } = usePrivy();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { isOpen, toggleMarketplace } = useMarketplace();

  const [token, setToken] = useState(null)

  useEffect(() => {
    if (ready && !user && !loggedInUser) navigate("/login");

    // openFullMarketModal();

    // getAccessToken().then(res => {
    //   console.log({ res })
    //   setToken(res)
    // })
  }, [ready, user, loggedInUser, navigate]);

  if (!ready && !user && !loggedInUser || !token) {
    return <LoadingScreen />;
  }

  return (
    <SettingsSection>
      {/* <iframe style={{
        width: '100%',
        height: '100vh'
      }} src={`http://localhost:4000/ethereum?privyToken=${token}`} /> */}
    </SettingsSection>
  );
}
