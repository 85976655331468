import PropTypes from "prop-types";
import { BodyText } from "../../../GlobalStyles";
import { useLinkedWallets } from "../../../hooks/useLinkedWallets";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import { theme } from "../../../utils/theme";
import WalletCard from "../../Wallet/WalletCard";
import { ContentContainer, PrimaryWalletContainer } from "./WalletsTab-Styles";
import { useTabsStore } from "../tabsStore";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import LinkWallets from "../../WalletsPage/LinkWallets";

const { colors } = theme;

export default function WalletsTab({ isLoading }) {
  const { activeTab } = useTabsStore();
  const { primaryWallet, receivingWallet } = usePrimaryWallet();
  const { linkedWallets } = useLinkedWallets();

  const walletToDisplay =
    activeTab === "receive" ? receivingWallet : primaryWallet;

  const description =
    activeTab === "receive" ? (
      <>
        Collectibles and tickets will be sent to your <strong>Receiving</strong>{" "}
        wallet.
      </>
    ) : (
      <>
        Payments will be made from your <strong>Payments</strong> wallet by
        default.
      </>
    );

  if (isLoading) {
    return (
      <ContentContainer>
        <PrimaryWalletContainer>
          <SkeletonElement height="20px" width="120px" />
          <SkeletonElement height="16px" />
          <SkeletonElement height="16px" width="50%" />
          <SkeletonElement height="208px" radius="10px" />
        </PrimaryWalletContainer>
        <SkeletonElement height="20px" width="120px" />
        <SkeletonElement height="153px" radius="10px" />
        <SkeletonElement height="153px" radius="10px" />
        <SkeletonElement height="153px" radius="10px" />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <BodyText color={colors.grey}>{description}</BodyText>
      <WalletCard wallet={{ ...walletToDisplay, primary: true }} />
      {linkedWallets
        ?.filter((wallet) => wallet.address !== walletToDisplay?.address)
        ?.map((wallet) => (
          <WalletCard key={wallet.address} wallet={wallet} />
        ))}
      <LinkWallets />
      <BodyText secondary style={{ textAlign: "center", paddingTop: "24px" }}>
        By default, you pay and receive from the same wallet. You can add
        multiple wallets and set separate wallets for payments and receiving.
      </BodyText>
    </ContentContainer>
  );
}

WalletsTab.propTypes = {
  isLoading: PropTypes.bool,
};
