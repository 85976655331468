import { PAYMENT_METHODS } from "../../../constants/constants";
import { BodyText, ButtonRect } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useCheckoutStore } from "../checkoutStore";
import { useTicketCheckout } from "../hooks/useTicketCheckout";
import { useWertCheckout } from "../hooks/useWertCheckout";
import { Container } from "./CheckoutButton-Styles";

const { colors } = theme;

export default function CheckoutButton() {
  const { ticketsSelected } = useBuyTicketStore();
  const { paymentMethod, isMinting, total, error } = useCheckoutStore();
  const { buy, buyForFree } = useTicketCheckout();
  const { buyWithCard } = useWertCheckout();

  const free = total === 0 && ticketsSelected?.length > 0;
  const disabledBuy = isMinting || (!paymentMethod && !free);

  const handleBuy = () => {
    if (paymentMethod === PAYMENT_METHODS.WALLET) buy();
    else if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD) buyWithCard();
    else if (free) buyForFree();
  };

  return (
    <Container>
      {error && (
        <BodyText color={colors.red}>An error occurred. Try again.</BodyText>
      )}
      <ButtonRect
        style={{ background: colors.green, border: "none" }}
        disabled={disabledBuy}
        onClick={free ? buyForFree : handleBuy}
      >
        <BodyText weight="600" color={colors.black}>
          {error
            ? "Try again"
            : isMinting
              ? "Processing..."
              : free
                ? "Claim tickets"
                : "Pay with Card"}
        </BodyText>
      </ButtonRect>
    </Container>
  );
}
