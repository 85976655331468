import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  padding: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const UserTicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
