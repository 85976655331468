import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 16px 0;
  background: #25254380;
  border-radius: 8px;
  transition: 0.1s ease;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Content = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? "400px" : 0)};
  margin-bottom: ${({ isOpen }) => (isOpen ? "16px" : "4px")};
  overflow: hidden;
  transition: 0.5s ease;
`;
