import { BodyText, IconWithText } from "../../../GlobalStyles";
import { useCheckoutStore } from "../checkoutStore";
import { ButtonWithMethod, Container } from "./PaymentMethod-Styles";
import CreditCardIcon from "../../../assets/svg/credit-card.svg?react";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import SelectButton from "../../Buttons/SelectButton";

const { colors } = theme;

export default function PaymentMethod({ method, disabled }) {
  const { paymentMethod, setPaymentMethod } = useCheckoutStore();

  const handlePaymentMethod = (method) => {
    if (paymentMethod === method) {
      // if (method === CREDIT_CARD) handleTotal(-1);
      setPaymentMethod(null);
    } else {
      // method === CREDIT_CARD
      //   ? handleTotal(1)
      //   : paymentMethod === CREDIT_CARD && method === WALLET && handleTotal(-1);
      setPaymentMethod(method);
    }
  };

  return (
    <Container onClick={() => handlePaymentMethod(method)} disabled={disabled}>
      <ButtonWithMethod>
        <IconWithText>
          <SelectButton selected={paymentMethod === method} />
          <CreditCardIcon stroke={colors.white} height={16} width={16} />
          <BodyText>{method}</BodyText>
        </IconWithText>
      </ButtonWithMethod>
    </Container>
  );
}

PaymentMethod.propTypes = {
  method: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
