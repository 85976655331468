import { BodyText, Header } from "../../../GlobalStyles";
import { useSearchParams } from "react-router-dom";
import { Container, RetroButton } from "./RetroCard-Styles";
import { SEARCH_PARAMS } from "../../../constants/search";
import RetroIcon from "../../../assets/svg/retro-moment.svg?react";
import { theme } from "../../../utils/theme";
import useMixpanelTracker from "../../../hooks/useMixpanelTracker";

const { RETRO } = SEARCH_PARAMS;

export default function RetroCard() {
  const [, setSearchParams] = useSearchParams();
  const { handleTracking } = useMixpanelTracker();

  const handleRedirect = () => {
    setSearchParams({ [RETRO]: true });
    document.querySelector("input[name=search]")?.focus();
    handleTracking(`Clicked "Add a past Moment"`);
  };

  return (
    <Container>
      <Header style={{ textTransform: "uppercase" }}>Log Past Events</Header>
      <BodyText style={{ margin: "12px 0 16px 0", color: theme.colors.grey }}>
        Search artist and add their past event to your Moments.
      </BodyText>
      <RetroButton onClick={handleRedirect}>
        <RetroIcon stroke={theme.colors.white} strokeWidth={1.5} />
        <BodyText weight="600">Add a past Moment</BodyText>
      </RetroButton>
    </Container>
  );
}
