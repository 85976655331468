import PropTypes from "prop-types";
import { Container } from "./PerformanceHeader-Styles";
import PerformanceEvent from "../PerformanceEvent";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import AddToMomentsButton from "../AddToMomentsButton";

export default function PerformanceHeader({ data, isLoading }) {
  const { performances, claimedMoment } = data || {};
  const performance = performances?.length > 0 ? performances[0] : null;
  const { image } = performance || {};
  return (
    <Container bg={image}>
      <PerformanceEvent performance={performance} isLoading={isLoading} />
      {isLoading ? (
        <SkeletonElement
          height="32px"
          width="177px"
          margin="0 0 16px 0"
          radius="10px"
          style={{ alignSelf: "center" }}
        />
      ) : (
        <AddToMomentsButton
          performance={performance}
          claimedMoment={claimedMoment}
        />
      )}
    </Container>
  );
}

PerformanceHeader.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};
