import { styled } from "styled-components";
import { ButtonRect, FooterText, Section } from "../../GlobalStyles";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SettingsCategories from "../../components/Settings/SettingsCategory";
import { NavBarTypes } from "../../constants/constants";
import LoadingScreen from "../../components/LoadingScreen";
import AppVersion from "../../components/Settings/AppVersion";
import TopNavV2 from "../../components/TopNavV2";
import useMarketplace from "../../hooks/useMarketplace";
import { isProd } from "../../utils/utils";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

const SettingsSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
`;

export default function Settings() {
  const { user, ready } = usePrivy();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { handleTracking } = useMixpanelTracker();

  const { toggleMarketplace } = useMarketplace();

  useEffect(() => {
    handleTracking("Visited Settings Page");
  }, []);

  useEffect(() => {
    if (ready && !user && !loggedInUser) navigate("/login");
  }, [ready, user, loggedInUser, navigate]);

  if (!ready && !user && !loggedInUser) {
    return <LoadingScreen />;
  }

  return (
    <SettingsSection>
      <TopNavV2 title={NavBarTypes.SETTINGS} isSticky />
      <SettingsCategories />
      <AppVersion />

      {!isProd && (
        <ButtonRect
          style={{
            margin: "20px",
          }}
          onClick={() => toggleMarketplace()}
        >
          <FooterText>Go to market</FooterText>
        </ButtonRect>
      )}
    </SettingsSection>
  );
}
