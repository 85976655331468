export const SEARCH_DROP_DOWN_LOCATIONS = [
  {
    country: "United Kingdom",
    cities: [
      "London",
      "Manchester",
      "Glasgow",
      "Bristol",
      "Liverpool",
      "Birmingham",
      "Leeds",
      "Edinburgh",
      "Brighton",
      "Nottingham",
    ],
  },
  {
    country: "United States",
    cities: [
      "New York City",
      "Chicago",
      "Las Vegas",
      "Los Angeles",
      "San Francisco",
    ],
  },
  {
    country: "Ireland",
    cities: ["Dublin", "Cork"],
  },
  {
    country: "Canada",
    cities: ["Toronto", "Edmonton", "Vancouver"],
  },
  {
    country: "Norway",
    cities: ["Oslo", "Bergen"],
  },
  {
    country: "Spain",
    cities: ["Madrid", "Barcelona", "Valencia"],
  },
  {
    country: "France",
    cities: ["Paris", "Marseille", "Toulouse"],
  },
  {
    country: "Germany",
    cities: ["Berlin", "Bremen"],
  },
  {
    country: "Denmark",
    cities: ["København K", "København V"],
  },
  {
    country: "Finland",
    cities: ["Helsinki", "Oulu"],
  },
];

export const YEARS = [
  2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
  2037, 2038, 2039, 2040,
];

export const PAST_YEARS = [
  2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
  2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const SEARCH_TABS = {
  EVENT: "event",
  ARTIST: "artist",
  VENUE: "venue",
  FAN: "fan",
};

export const SEARCH_PARAMS = {
  QUERY: "query",
  TAB: "tab",
  COUNTRY: "country",
  CITY: "city",
  YEAR: "year",
  MONTH: "month",
  ARTIST: "artist",
  VENUE: "venue",
  RETRO: "retro",
  PAST: "past",
  LOCATION_PICKER: "locationPicker",
  QUICK_SEARCH: "quickSearch",
};

export const RECENT_SEARCH_TYPES = {
  TEXT: "text",
  ARTIST: "artist",
  EVENT: "event",
};
