import PropTypes from "prop-types";
import { DetailsContainer } from "./CollectibleThumbnail-Styles";
import { BodyText, FooterTextSm } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";

const { colors } = theme;

export default function CollectibleThumbnailDetails({
  collectible,
  isExternal,
}) {
  const { artist, edition_name, name } = collectible || {};

  return (
    <DetailsContainer>
      <BodyText bold truncate style={{ margin: "4px 0 8px 0" }}>
        {isExternal ? name : artist}
      </BodyText>
      <FooterTextSm color={colors.grey} truncate>
        {isExternal ? "Momentify Badge" : (edition_name ?? "No edition name")}
      </FooterTextSm>
    </DetailsContainer>
  );
}

CollectibleThumbnailDetails.propTypes = {
  collectible: PropTypes.object,
  isExternal: PropTypes.bool,
};
