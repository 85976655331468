import { useQuery } from "@tanstack/react-query";
import { venueLoader } from "../loader";
import { getVenueCollectibles, getVenueMoments } from "../../../api/venues";

export const useVenue = ({ venueId, venueName, userId = null }) => {
  return useQuery({
    queryKey: ["venue", venueId, userId],
    queryFn: () => venueLoader({ venueId, venueName, userId }),
  });
};

export const useVenueMomentsQuery = ({ venueId, userId }) => {
  return useQuery({
    queryKey: [
      "venue-moments",
      {
        venueId,
        userId,
      },
    ],
    queryFn: async () => {
      const moments = await getVenueMoments({ venueId, userId });

      if (!moments?.success) return [];

      return moments?.data || [];
    },
  });
};

export const useVenueCollectiblesQuery = ({ venueId }) => {
  return useQuery({
    queryKey: [
      "venue-collectibles",
      {
        venueId,
      },
    ],
    queryFn: async () => {
      const collectibles = await getVenueCollectibles({ venueId });

      if (!collectibles?.success) return [];

      return collectibles?.data || [];
    },
  });
};
