import PropTypes from "prop-types";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { Header } from "./UserTickets-Styles";
import UserTicketsFilters from "./UserTicketsFilters";
import { useUserTicketsStore } from "./stores/userTicketsStore";
import { USER_TICKETS_FILTERS } from "../../../constants/constants";

const { UPCOMING, PAST } = USER_TICKETS_FILTERS;
export default function UserTicketsHeader({ totalTickets, isLoading }) {
  const { activeFilter } = useUserTicketsStore();

  const prefix =
    activeFilter === UPCOMING
      ? "Upcoming"
      : activeFilter === PAST
        ? "Past"
        : "Your";

  return (
    <Header>
      <BodyText weight="600">
        {prefix} tickets
        {!isLoading && (
          <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
            ({totalTickets})
          </FooterText>
        )}
      </BodyText>
      {!isLoading && <UserTicketsFilters />}
    </Header>
  );
}

UserTicketsHeader.propTypes = {
  totalTickets: PropTypes.number,
  isLoading: PropTypes.bool,
};
