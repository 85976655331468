import { BodyText, ButtonRectXs } from "../GlobalStyles";
import { theme } from "../utils/theme";

export const EphemeralTipButton = ({ icon, text, onClickFunction }) => {
  const { fontStyles } = theme;
  /* Share location */

  return (
    <ButtonRectXs
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        background: "rgba(255, 255, 255, 0.12)",
        backdropFilter: "blur(50px)",
        borderRadius: "8px",
        borderColor: "transparent",
      }}
      onClick={onClickFunction}
    >
      {icon}
      <BodyText
        style={{
          fontStyle: fontStyles.suisse,
          fontSize: "10px",
          fontWeight: 400,
        }}
      >
        {text}
      </BodyText>
    </ButtonRectXs>
  );
};
