import styled from "styled-components";

export const DivisionContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  gap: ${({ gap }) => (gap ? gap : "0px")};
  align-items: ${({ align }) => (align ? align : "normal")};
  justify-content: ${({ justify }) => (justify ? justify : "normal")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  margin: ${({ margin }) => (margin ? margin : "0")};
`;
