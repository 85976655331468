import { formatUnits, parseUnits } from "viem";
import {
  getMintByTxn,
  getTicketsMinted,
  insertTicketsMinted,
} from "../api/ticketing";

export const calculateTotal = ({ ticketsTotal, momentifyFee, gas }) => {
  // Convert values to smallest unit (6 decimals for USDC)
  const ticketsTotalInSmallestUnit = parseUnits(ticketsTotal.toString(), 6); // Returns BigInt
  const mintFeeInSmallestUnit = parseUnits(momentifyFee.toString(), 6); // Returns BigInt
  const gasInSmallestUnit = parseUnits(gas.toString(), 6); // Returns BigInt

  // Calculate the total amount required (prices + mint fees + gas)
  const totalAmount =
    ticketsTotalInSmallestUnit + mintFeeInSmallestUnit + gasInSmallestUnit;

  return {
    formattedTotal: formatUnits(totalAmount, 6),
    parsedTotal: totalAmount,
  };
};

export const countTicketsByType = (tickets) => {
  return tickets.reduce(
    (acc, ticket) => {
      const typeKey = ticket.type === "General admission" ? "GA" : ticket.type;
      acc[typeKey] = (acc[typeKey] || 0) + 1;
      return acc;
    },
    { VIP: 0, GA: 0 }
  );
};

export const sortTickets = (tickets) => {
  return tickets.sort((a, b) => a.type.localeCompare(b.type));
};

export const countAndDeduplicateTickets = (tickets) => {
  // Sort the tickets by type
  const sortedTickets = sortTickets(tickets);

  // Create a map to store the count for each ticket by its type
  const ticketMap = sortedTickets.reduce((acc, ticket) => {
    const typeKey = ticket.type;

    if (!acc[typeKey]) {
      // Add a new entry with the ticket object and count 1
      acc[typeKey] = { ...ticket, type: typeKey, count: 1 };
    } else {
      // Increment the count if the ticket type already exists
      acc[typeKey].count += 1;
    }

    return acc;
  }, {});

  // Return an array of unique ticket objects with the count added
  return Object.values(ticketMap);
};

export const addDelayInMs = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const checkTicketsMinted = async (wallet, contractAddress, tx_id) => {
  let attempt = 0;
  let getTicketsSuccess = false;

  while (attempt < 3 && !getTicketsSuccess) {
    attempt++;

    console.log("Getting minted tickets on Attempt:", attempt);

    try {
      const { data, success } =
        (await getTicketsMinted({
          owner: wallet,
          contractAddress: contractAddress,
          txn: tx_id,
        })) || {};

      getTicketsSuccess = success;

      if (success && data?.length) {
        return { data, success };
      }
    } catch (error) {
      console.error(
        "Failed to get minted tickets on attempt",
        attempt,
        ":",
        error
      );
    }

    if (attempt === 3) {
      console.error("Failed to get minted tickets after 3 attempts");
      return {
        success: false,
        error: "Failed to get minted tickets after 3 attempts",
      };
    }
  }
};

export const processTicketMinting = async (params, stateUpdaters) => {
  const { tx_id, userWallet, contractAddress, boughtTickets, loggedInUser } =
    params;

  const { setTxnHash, setBoughtTickets, setIsMinting, setError } =
    stateUpdaters;

  try {
    const { mintedTickets } = (await getMintByTxn({ txn: tx_id })) || {};
    if (mintedTickets?.length > 0) {
      return { success: true, alreadyMinted: true };
    }

    await addDelayInMs(1000);

    const { data: batchMintData, success: batchMintSuccess } =
      await checkTicketsMinted(userWallet, contractAddress, tx_id);

    if (!batchMintSuccess) {
      setError("Failed to get tickets minted");
      throw new Error("Failed to get tickets minted");
    }

    const { success, data } =
      (await insertTicketsMinted({
        tickets: boughtTickets,
        userId: loggedInUser?.id,
        txnHash: tx_id,
        mints: batchMintData,
      })) || {};

    if (!success || !data?.length) {
      setError("Failed to insert tickets minted");
      throw new Error("Failed to insert tickets minted");
    }

    // Set initial states concurrently
    await Promise.all([setTxnHash(tx_id), setBoughtTickets(boughtTickets)]);

    console.log("Tickets Minted Inserted to DB");

    await clearPendingState(stateUpdaters);

    return { success: true, data };
  } catch (error) {
    console.error("Error in ticket minting process:", error);
    setError(`Error in ticket minting process: ${error?.message || error}`);
    setIsMinting(false);
    return {
      success: false,
      error: error.message || "Unknown error occurred",
    };
  } finally {
    setIsMinting(false);
  }
};

export const clearPendingState = async (stateUpdaters) => {
  const { setPendingBoughtTickets, setPendingTxn, setPendingEvent } =
    stateUpdaters;

  await Promise.all([
    setPendingBoughtTickets([]),
    setPendingTxn(null),
    setPendingEvent(null),
  ]);
};

export const setPendingState = async (
  event,
  boughtTickets,
  tx_id,
  stateUpdaters
) => {
  const { setPendingEvent, setPendingBoughtTickets, setPendingTxn } =
    stateUpdaters;

  await Promise.all([
    setPendingEvent(event),
    setPendingBoughtTickets(boughtTickets),
    setPendingTxn(tx_id),
  ]);
};
