import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useMomentsStore = create((set) => ({
  // FYP Index
  momentsIndex: 0,
  setMomentsIndex: (value) => set(() => ({ momentsIndex: value })),

  // Events Index
  eventsIndex: 0,
  setEventsIndex: (value) => set(() => ({ eventsIndex: value })),
}));

export const usePersistentMomentsStore = create(
  persist(
    (set) => ({
      // FYP Index
      seenIndices: [],
      setSeenIndices: (value) => set(() => ({ seenIndices: value })),
    }),
    {
      name: "persistent-moments-store",
    }
  )
);
