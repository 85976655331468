import { useQuery } from "@tanstack/react-query";
import {
  fetchSingleCollectible,
  fetchSingleExternalCollectible,
} from "../../api/collectibles";

export const useCollectibleQuery = ({
  userId,
  collectibleId,
  type = "airdrop",
}) => {
  return useQuery({
    queryKey: ["collectible", userId, collectibleId, type],
    queryFn: () => {
      return fetchSingleCollectible(userId, collectibleId, type);
    },
    refetchInterval: 1000 * 60 * 4, // Refetch events every 1 min,
  });
};

export const useExternalCollectibleQuery = ({
  userId,
  collectibleId,
  tokenId,
}) => {
  return useQuery({
    queryKey: ["external-collectible", userId, collectibleId, tokenId],
    queryFn: () => {
      return fetchSingleExternalCollectible(userId, collectibleId, tokenId);
    },
  });
};
