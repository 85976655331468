import PropTypes from "prop-types";
import { FooterText, HeaderL, HeaderSm } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { formatPrice } from "../../../utils/utils";
import { TicketDetailsContainer, TicketFees } from "./Ticket-Styles";

export default function TicketDetails({ price, fee }) {
  const formattedPrice = formatPrice(price);
  const formattedFee = formatPrice(fee, false);

  return (
    <TicketDetailsContainer>
      <TicketFees>
        <TicketFees style={{ gap: "0px" }}>
          <HeaderL lineHeight="1">${formattedPrice[0]}</HeaderL>
          <HeaderSm>.{formattedPrice[1] ?? "00"}</HeaderSm>
        </TicketFees>
        <FooterText color={theme.colors.grey}>
          +${price > 0 ? formattedFee : "0.00"} fee
        </FooterText>
      </TicketFees>
    </TicketDetailsContainer>
  );
}

TicketDetails.propTypes = {
  price: PropTypes.number,
  // Number or string
  fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
