import moment from "moment-timezone";
import { isCinnyAuthenticated, isCinnySupported } from "../utils/cinny-utils";
import { createOrUpdatePreEventRoomHandler } from "../utils/matrix-utils";
import { decreasePreEventTotalMember } from "./matrix";

export const fetchRecommendEvents = async (userId, userCountry) => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/recommend`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: userId,
          userCountry: userCountry,
        }),
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchNearbyEvents = async (
  latitude,
  longitude,
  distance,
  userId,
  userCountry
) => {
  try {
    // Fetch nearby events
    let events = await fetch(`${import.meta.env.VITE_API_URL}/events/nearby`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latitude,
        longitude,
        distance,
        userid: userId,
        userCountry: userCountry,
      }),
    });

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchFutureNearbyEvents = async (
  latitude,
  longitude,
  distance,
  userId,
  userCountry
) => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/future-nearby`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          latitude,
          longitude,
          distance,
          userid: userId,
          userCountry: userCountry,
        }),
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchComingSoon = async (
  latitude,
  longitude,
  distance,
  userId,
  userCountry
) => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/coming-soon/nearby`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          latitude,
          longitude,
          distance,
          userid: userId,
          userCountry: userCountry,
        }),
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const searchEvents = async (
  query,
  userId,
  artistId,
  venueId,
  country,
  city,
  year,
  month,
  signal,
  limit = 20,
  offset = 0,
  latitude,
  longitude,
  quickSearch = false
) => {
  try {
    const localTime = moment().local().format("YYYY-MM-DD HH:mm:ss");
    const userTimezone = moment.tz.guess();
    const userTime = moment
      .tz(localTime, "YYYY-MM-DD HH:mm:ss", userTimezone)
      .format("YYYY-MM-DD HH:mm:ss");

    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/search?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          country,
          city,
          year,
          month,
          userId,
          artistId,
          venueId,
          latitude,
          longitude,
          quick_search_param: quickSearch,
          date_time_param: userTime,
        }),
        signal,
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};

export const getEligibility = async (event) => {
  // Get event date
  const eventDate = moment(event.date);

  // Check if event is today using moments js
  const isToday = eventDate.isSame(moment(), "day");

  if (event.distance_meters != null && event.distance_meters < 50 && isToday)
    return true;
  else return false;
};

export const getUserDuplicateCheckins = async (sessionId, event, user) => {
  // Get user duplicate checkins
  const duplicateCheckins = await fetch(
    `${import.meta.env.VITE_API_URL}/events/${
      event.event_id ?? event.id
    }/checkin/session/${sessionId}/users/${user?.id}/wallets/${
      user?.embedded_wallet_id
    }`
  ).catch((err) => {
    console.error(err);
    return false;
  });

  return await duplicateCheckins.json();
};

export const getCheckInLatestHistory = async (eventId) => {
  try {
    const checkInHistory = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${eventId}/checkin-history-latest`
    ).catch((err) => {
      console.error(err);
      return false;
    });

    return await checkInHistory.json();
  } catch (err) {
    return false;
  }
};

export const checkInToEvent = async (checkInInfo) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${
        checkInInfo.event?.id ?? checkInInfo.event.event_id
      }/checkin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: checkInInfo?.sessionId,
          userId: checkInInfo?.user.id,
          walletAddress: checkInInfo?.user.walletAddress,
          deviceInfo: checkInInfo?.deviceInfo,
          browserInfo: checkInInfo?.browserInfo,
          latitude: checkInInfo?.location.coords.latitude,
          longitude: checkInInfo?.location.coords.longitude,
          precision: checkInInfo?.location.coords.accuracy,
          checkinOutcome: checkInInfo?.checkinOutcome,
          failedReason: checkInInfo?.failedReason,
          checkinNumber: checkInInfo?.checkinNumber,
          venueId:
            checkInInfo?.event?.venue_id ??
            checkInInfo?.event?.combined_venue_id,
          eventId: checkInInfo?.event.event_id ?? checkInInfo?.event.id,
          artistIds:
            checkInInfo?.event.combined_artist_ids &&
            checkInInfo?.event.combined_artist_ids.map((artistId) => artistId),
          officialCheckinNumber: checkInInfo?.officialCheckinNumber,
          momentId: checkInInfo?.momentId,
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
  }
};

export const getOneEvent = async (
  eventId,
  userId,
  date,
  latitude,
  longitude
) => {
  if (!eventId) return false;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}?userId=${userId}&date=${date}&lat=${latitude}&long=${longitude}`
  ).catch((err) => {
    console.error(err);
    return false;
  });

  const event = await response.json();

  if (response.status !== 200) throw Error(event.message);

  return event;
};

export const getOneEventWithLocation = async (eventId, lat, long, date) => {
  if (!eventId || !lat || !long) return false;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}/location`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latitude: lat,
        longitude: long,
        date_filter: date,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return false;
  });

  const event = await response.json();

  if (response.status !== 200) throw Error(event.message);

  return event;
};

export const checkUserInterestByUserId = async (eventId, userId, signal) => {
  if (!eventId || !userId) return false;

  const participants = await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}/participants/${userId}`,
    {
      signal,
    }
  ).catch((err) => {
    if (err === "AbortError") return;

    console.error(err);
    return false;
  });

  if (!participants) return false;

  return await participants.json();
};

export const checkUserInterestByDiscordId = async (eventId, participant) => {
  if (!eventId || !participant) return false;

  const participants = await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}/participants/${participant.id}/discord/${participant.discord?.subject}`
  ).catch((err) => {
    console.error(err);
    return false;
  });

  if (!participants) return false;

  return await participants.json();
};

export const addEventParticipant = async (
  eventId,
  participant,
  sentiment,
  headlineArtist,
  artistIds
) => {
  if (!eventId || !participant || !sentiment) return false;

  await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}/participants`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: participant.id,
        discordId: participant.discord?.subject,
        sentiment,
        artistIds,
      }),
    }
  )
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      return false;
    });

  if (isCinnySupported() && isCinnyAuthenticated()) {
    const _event = {
      id: eventId,
      headline_artist: headlineArtist,
    };
    const preEventRoom = await createOrUpdatePreEventRoomHandler(_event).catch(
      (err) => {
        console.error(err);
        return false;
      }
    );

    if (!preEventRoom) return false;

    return preEventRoom;
  }
  return true;
};

export const removeEventParticipant = async (
  eventId,
  participant,
  sentiment
) => {
  if (!eventId || !participant || !sentiment) return false;
  let currentMatrixToken = localStorage.getItem("cinny_access_token");
  await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}/participants`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: participant.id,
        discordId: participant.discord?.subject,
        sentiment,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return false;
  });

  await decreasePreEventTotalMember(
    import.meta.env.VITE_API_URL,
    currentMatrixToken,
    eventId
  ).catch((err) => {
    console.error(err);
    return false;
  });

  return true;
};

export const getEventParticipantsCount = async (eventId) => {
  if (!eventId) return false;

  const participants = await fetch(
    `${import.meta.env.VITE_API_URL}/events/${eventId}/participants/count`
  ).catch((err) => {
    console.error(err);
    return false;
  });

  if (!participants) return false;

  return await participants.json();
};

export const getAIImageByEventId = async (eventId) => {
  if (!eventId) return false;

  try {
    const image = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${eventId}/prompt-image`
    ).catch((err) => {
      console.error(err);
      return false;
    });

    if (!image) return false;

    return await image.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const getAIImageByEventIdAndPerformanceId = async (
  eventId,
  performanceId
) => {
  if (!eventId || !performanceId) return false;

  try {
    const image = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${eventId}/performance/prompt-image`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          performance_id: performanceId,
        }),
      }
    ).catch((err) => {
      console.error(err);
      return false;
    });

    if (!image) return false;

    return await image.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getEventPerformances = async (eventId, eventDate) => {
  if (!eventId) return false;

  try {
    const performances = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${eventId}/performances?date=${eventDate}`
    ).catch((err) => {
      console.error(err);
      return false;
    });

    return await performances.json();
  } catch (err) {
    console.warn(err);
    return false;
  }
};

/**
 * Asynchronous function to retrieve events feed for a specific user.
 *
 * @param {string} userId - The ID of the user to retrieve the events feed for.
 * @return {Promise} The events feed data if successful, or false if there's an error.
 */
export const getEventsFeed = async (userId, limit, offset) => {
  if (!userId) return false;

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/events-feed?limit=${limit}&offset=${offset}`
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return false;
    }

    return await response.json();
  } catch (err) {
    if (err.code === 20) return false;
    console.error(err);
    return false;
  }
};

export const getSearchLocations = async () => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/location-json`
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return false;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getSearchLocationsExact = async (query) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/location-json/${query}`
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return false;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getAllInterestedEvents = async (userId) => {
  const localTime = moment().local().format("YYYY-MM-DD HH:mm:ss");
  const userTimezone = moment.tz.guess();
  const userTime = moment
    .tz(localTime, "YYYY-MM-DD HH:mm:ss", userTimezone)
    .format("YYYY-MM-DD HH:mm:ss");
  try {
    if (!userId) return [];

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/interested-events?date_time_param=${userTime}`
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return [];
    }

    return await response.json();
  } catch (err) {
    if (err.code === 20) return [];
    console.error(err);
    return [];
  }
};

export const upsertTicketClick = async ({
  userId,
  eventId,
  ticketType,
  ticketUrl,
}) => {
  try {
    if (!userId || !eventId || !ticketType || !ticketUrl) return false;

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/ticket-click`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          event_id: eventId,
          ticket_type: ticketType,
          ticket_url: ticketUrl,
        }),
      }
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return false;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const insertEventPageClick = async ({
  user_id,
  combined_artist_ids,
  event_id,
  horizontal_position,
  vertical_position,
  header,
  country,
}) => {
  try {
    if (!user_id || !event_id) return;

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/event-page-click`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          combined_artist_ids,
          event_id,
          horizontal_position,
          vertical_position,
          header,
          country,
        }),
      }
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return false;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchNewlyAppended = async (
  latitude,
  longitude,
  userId,
  userCountry
) => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/newly-appended`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          latitude,
          longitude,
          userid: userId,
          userCountry: userCountry,
        }),
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchNewlyAppendedPopular = async () => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/newly-appended-popular`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchPopularInArea = async (userCountry) => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/popular-in-area`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userCountry: userCountry,
        }),
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchEventsFriendsInterested = async (userid) => {
  try {
    // Fetch nearby events
    let events = await fetch(
      `${import.meta.env.VITE_API_URL}/events/events-friends-interested`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: userid,
        }),
      }
    );

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const getApefestEvents = async () => {
  try {
    // Fetch Apefest Events
    let events = await fetch(`${import.meta.env.VITE_API_URL}/apefest/events`);

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};
