import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  followUser,
  getFollowActionUser,
  unfollowUser,
} from "../../../api/activities";
import {
  getIfUserFollowsFan,
  getUserLatestMessages,
  getUserNotifications,
  getUserParticipated,
  getUserStats,
} from "../../../api/profile";
import { fetchEvents, fetchMoments, profileLoader } from "../loader";
import { useUserStore } from "../store/userStore";
import {
  fetchCollectibles,
  fetchExternalMints,
  fetchExternalNFTs,
  getFIDMints,
  insertExternalMints,
} from "../../../api/collectibles";
import useAuth from "../../../hooks/useAuth";
import { getTicketsByUser } from "../../../api/ticketing";
import useAccountKit from "../../../hooks/useAccountKit";
import { getUnrewardedGuessXP } from "../../../api/user";

export const useProfileQuery = ({ userId, loggedInUserId }) => {
  return useQuery({
    queryKey: ["profile", userId],
    queryFn: async () => {
      const data = await profileLoader({ params: { id: userId } });
      return data;
    },
    gcTime: loggedInUserId == userId ? Infinity : 1000 * 60 * 60 * 2,
  });
};

export const useUserParticipated = ({ loggedInUserId }) => {
  const { setUserParticipated } = useUserStore();
  return useQuery({
    queryKey: ["participated", loggedInUserId],
    queryFn: async () => {
      const data = await getUserParticipated(loggedInUserId);

      setUserParticipated(data);
      return data;
    },
    enabled: !!loggedInUserId,
  });
};

export const useUserMessages = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["messages", userId.toString()],
    queryFn: async () => {
      const messages = await getUserLatestMessages(userId);
      return messages?.data ?? [];
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch messages every 1 min,
  });
};

// TODO: Invalidate this on exp gain
export const useUserStats = ({ userId, isOwnProfile }) => {
  const { setLoggedInUser, loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["stats", userId.toString()],
    queryFn: async () => {
      const stats = await getUserStats(userId);

      const xp = stats?.length == 0 ? 0 : stats[0].total_xp;

      if (loggedInUser?.id == userId) {
        setLoggedInUser({ ...loggedInUser, xp });
      }

      return !stats || stats?.length == 0 ? [] : stats[0];
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch stats every 1 min,
  });
};

export const useUserNotifications = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["notifications", userId.toString()],
    queryFn: async () => {
      const notifications = await getUserNotifications(userId);
      return notifications?.data ?? [];
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch notif every 1 min,
  });
};

export const useUserMoments = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["moments", userId.toString()],
    queryFn: async () => {
      const moments = await fetchMoments(userId, isOwnProfile);

      return moments;
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch moments every 1 min,
  });
};

export const useUserEvents = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["events", userId.toString()],
    queryFn: async () => {
      const events = await fetchEvents(userId, isOwnProfile);

      // Add is_interested to each event
      if (events?.length > 0 && isOwnProfile) {
        events.forEach((event) => {
          event.is_interested = true;
        });
      }

      return events;
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch events every 1 min,
  });
};

export const useUserCollectibles = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["collectibles", userId.toString()],
    queryFn: ({ signal }) => {
      return fetchCollectibles(userId, signal);
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch events every 1 min,
  });
};

export const useUserTickets = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["tickets", userId.toString()],
    queryFn: () => {
      return getTicketsByUser({ userId });
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch events every 1 min,
  });
};

export const useIsFollowing = ({ userId, profileId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["isFollowing", userId?.toString(), profileId?.toString()],
    queryFn: async () => {
      const isUserFollowed = await getIfUserFollowsFan(userId, profileId);
      return isUserFollowed?.data ?? false;
    },
    enabled: !isOwnProfile && !!userId && !!profileId,
    refetchInterval: 1000 * 60 * 5, // Refetch events every 1 min,
  });
};

export const useAlchemyQuery = (isMainnet) => {
  const { setAlchemyProvider } = useAccountKit();

  return useQuery({
    queryKey: ["alchemy"],
    queryFn: () => {
      setAlchemyProvider(isMainnet);

      return true;
    },
  });
};

export const useFollowMutate = ({ loggedInUserId, userId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["follow-user"],
    mutationFn: async ({ isFollowing }) => {
      if (!isFollowing) await followUser(loggedInUserId, userId);
      else
        await getFollowActionUser(loggedInUserId, userId).then(async (res) => {
          if (res.success)
            await unfollowUser(loggedInUserId, userId, res?.data?.id);
        });
    },
    onSuccess: (_, { isFollowing }) => {
      if (isFollowing) {
        toast.dismiss();
        toast.success("Unfollowed the user");
      } else {
        toast.dismiss();
        toast.success("Followed the user");
      }

      if (
        queryClient.getQueryData([
          "isFollowing",
          loggedInUserId?.toString(),
          userId?.toString(),
        ])
      )
        queryClient.setQueryData(
          ["isFollowing", loggedInUserId?.toString(), userId?.toString()],
          !isFollowing
        );

      queryClient.invalidateQueries({
        queryKey: ["following", userId.toString()],
      });

      // queryClient.invalidateQueries({
      //   queryKey: ["following", loggedInUserId.toString()],
      // });

      queryClient.invalidateQueries({
        queryKey: ["followers", userId.toString()],
      });

      // queryClient.invalidateQueries({
      //   queryKey: ["followers", loggedInUserId.toString()],
      // });

      // queryClient.refetchQueries(["search"]);

      // Update
      queryClient.invalidateQueries({
        queryKey: ["stats", loggedInUserId.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: ["stats", userId.toString()],
      });
    },
    onError: (error) => {
      toast.error("An error occurred. Please try again later." + error);
    },

    enabled: !!loggedInUserId && !!userId,
  });
};

export const useExternalNFTs = () => {
  return useQuery({
    queryKey: ["external_nfts"],
    queryFn: async () => {
      const moments = await fetchExternalNFTs();

      return moments;
    },
  });
};

export const useExternalMints = ({
  externalNFTs,
  enabled,
  wallets,
  userId,
}) => {
  const { setExternalMints, setFidMints } = useUserStore();
  const { loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["external_mints", externalNFTs, enabled, wallets, userId],
    queryFn: async () => {
      if (!enabled) return [];

      // Test wallet
      // wallets = wallets.concat({
      //   address: "0x08a2e865ea81ed3a2fdbd317383b93c14c6a5724",
      // });

      const results = await Promise.all(
        wallets.map(async (wallet) => {
          const walletMints = await Promise.all(
            externalNFTs.map(async (nft) => {
              const allMints = await fetchExternalMints(
                wallet.address,
                nft.contract_address
              );

              // Find the specific mint that matches the token ID
              const specificMint = allMints.find(
                (mint) => mint.tokenId == nft.token_id
              );

              // Return wallet and nft if the specific mint is found
              if (specificMint) {
                return {
                  wallet,
                  nft: { ...specificMint, image: nft.image },
                };
              }
              return null;
            })
          );
          // Filter out null results
          return walletMints.filter((result) => result !== null);
        })
      );

      const flattendResults = results.flat();

      const external_mints = await insertExternalMints(userId, flattendResults);

      setExternalMints(external_mints);

      if (!external_mints && loggedInUser?.farcaster_id) {
        // Check if fid minted
        const fidMints = await getFIDMints(loggedInUser?.farcaster_id);
        setFidMints(fidMints);
        console.log(fidMints);
      }

      return external_mints;
    },
    enabled,
  });
};

export const useUnrewardedGuessXP = ({ fid }) => {
  const { setUnrewardedGuessXP } = useUserStore();

  return useQuery({
    queryKey: ["unrewarded_guess_xp", fid],
    queryFn: async () => {
      const unrewardedGuessXP = await getUnrewardedGuessXP(fid);
      setUnrewardedGuessXP(unrewardedGuessXP);
      return unrewardedGuessXP;
    },
    enabled: !!fid,
  });
};
