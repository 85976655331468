import { useQuery } from "@tanstack/react-query";
import { getActionByUserIdAndType } from "../../../api/profile";
import { getSuccessfulInvites } from "../../../api/user";

export const useInviteLink = ({ userId }) => {
  return useQuery({
    queryKey: ["invite-link", userId],
    queryFn: async () => {
      const inviteLink = await getActionByUserIdAndType({
        user_id: userId,
        action_type: "invite_friend",
        target_type: "user",
      });

      return inviteLink?.data;
    },
    enabled: !!userId,
    staleTime: Infinity,
  });
};

export const useSuccessfulInvites = ({ inviteId }) => {
  return useQuery({
    queryKey: ["successful-invites", inviteId],
    queryFn: async () => {
      const invites = await getSuccessfulInvites(inviteId);

      if (!invites || !invites?.success) {
        return 0;
      }

      return invites?.data?.use_count || 0;
    },
    enabled: !!inviteId,
  });
};
