import ShareIcon from "../assets/svg/share.svg?react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { ButtonGhost } from "../GlobalStyles";

/**
 * ShareButton component that handles sharing content.
 * @param {string} url - The URL to be shared
 * @param {string} title - The title of the content to be shared
 * @param {string} text - The text content to be shared
 * @param {Array} files - An array of files to be shared
 * @return {React.JSX.Element} - The ShareButton component
 */
export default function ShareButton({ url, title, text, files, noPadding }) {
  /**
   * Handles the click event for sharing the content
   */

  const handleClick = async () => {
    // Check if the browser has navigator
    if (!navigator) {
      return toast.error("Sharing is not supported by your browser");
    }

    // Check if the browser supports sharing links
    if (!navigator?.canShare || !navigator?.share) {
      return toast.error(
        "Woops, looks like your browser doesn't support sharing links."
      );
    }

    // Set the shared files
    const sharedFiles = files ? files : [];
    const shareData = { title, text, url };
    if (sharedFiles.length) shareData.files = sharedFiles;

    // Check if the data can be shared by the browser
    if (!navigator.canShare(shareData)) {
      return toast.error(
        "The data you're trying to share is not supported by your browser"
      );
    }

    // Share the data using the browser's share API
    await navigator.share(shareData).catch((err) => console.warn(err));
  };

  if (noPadding) {
    return (
      <div onClick={handleClick}>
        <ShareIcon height={20} width={20} stroke="white" strokeWidth={1.5} />
      </div>
    );
  }

  // Render the ShareButton component with the share icon
  return (
    <ButtonGhost onClick={handleClick}>
      <ShareIcon height={20} width={20} stroke="white" strokeWidth={1.5} />
    </ButtonGhost>
  );
}

ShareButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  files: PropTypes.array,
  noPadding: PropTypes.bool,
};
