import PropTypes from "prop-types";
import { theme } from "../../utils/theme";
import { BodyText } from "../../GlobalStyles";
import { useScrollStore } from "../../store/scrollStore";
import {
  Container,
  Tab,
  TabComponentContainer,
  TabContainer,
} from "./Tabs-Styles";
import { useMemo, useState } from "react";
import { useTabsStore } from "./tabsStore";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";
import { useLocation } from "react-router-dom";

export default function Tabs({ tabs, type }) {
  const { activeTab: activeTabStore, setActiveTab: setActiveTabStore } =
    useTabsStore();

  const activeTabMemo = useMemo(
    () => tabs.find((tab) => tab.id === activeTabStore)?.id,
    [activeTabStore, tabs]
  );

  const [activeTab, setActiveTab] = useState(activeTabMemo ?? tabs[0].id);

  const { scrollPosition } = useScrollStore();
  const { handleTracking } = useMixpanelTracker();
  const location = useLocation();

  const blurIsActive =
    type === "profile" ||
    type === "following" ||
    type === "followers" ||
    type === "performance" ||
    type === "wallets";

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setActiveTabStore(tabId);

    handleTracking(`Clicked ${tabId} tab`, {
      page: location.pathname,
    });
  };

  return (
    <Container>
      <TabContainer blur={blurIsActive || scrollPosition > 200}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            active={activeTab === tab.id}
            type={type}
          >
            {tab.icon}
            {tab.label && (
              <BodyText
                color={activeTab === tab.id ? "white" : theme.colors.grey}
              >
                {tab.label}
              </BodyText>
            )}
          </Tab>
        ))}
      </TabContainer>
      {tabs.map((tab) => (
        <TabComponentContainer key={tab.id} active={activeTab === tab.id}>
          {tab.component}
        </TabComponentContainer>
      ))}
    </Container>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      component: PropTypes.element,
    })
  ).isRequired,
  type: PropTypes.string,
};
