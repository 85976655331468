import PropTypes from "prop-types";
import FollowButton from "../../FollowButton";
import SubscribeButton from "../SubscribeButton";
import {
  ArtistPlaceholder,
  BUTTON_SIZES,
  GlassmorphPlaceholder,
} from "../../../constants/constants";
import { useEffect, useState } from "react";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import {
  useFollowMutate,
  useSubscribeMutate,
} from "../../../routes/Artist/hooks/artistQueries";
import SubscribeModal from "../../Modal/SubscribeModal";
import { useUserStore } from "../../../routes/Profile/store/userStore";
import { IconWithText } from "../../../GlobalStyles";
import {
  ArtistImage,
  Container,
  FollowAndSubscribeContainer,
  Overlay,
  ArtistPfp,
  BottomContainer,
  DetailsContainer,
  NameContainer,
} from "./ArtistHeader-Styles";

export default function ArtistHeader({ artist, isLoading }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const {
    addUserFollowedArtist,
    removeUserFollowedArtist,
    userFollowedArtists,
    userSubscriptions,
    addUserSubscriptions,
    removeUserSubscriptions,
  } = useUserStore();

  const [isFollowing, setIsFollowing] = useState(
    userFollowedArtists.some((art) => art?.id == artist?.artist_id) ?? false
  );

  const [isSubscribed, setIsSubscribed] = useState(
    userSubscriptions.some((art) => art?.id == artist?.artist_id) ?? false
  );

  const followMutation = useFollowMutate({
    loggedInUserId: loggedInUser?.id,
    artistId: artist?.artist_id,
  });
  const subscribeMutation = useSubscribeMutate({
    loggedInUserId: loggedInUser?.id,
    artistId: artist?.artist_id,
  });

  useEffect(() => {
    setIsFollowing(
      userFollowedArtists.some((art) => art?.id == artist?.artist_id) ?? false
    );
    setIsSubscribed(
      userSubscriptions.some((art) => art?.id == artist?.artist_id) ?? false
    );
  }, [artist, userFollowedArtists, userSubscriptions]);

  const handleFollowClick = async () => {
    try {
      if (!loggedInUser) return login();

      followMutation.mutate({ isFollowing });
      artist.id = artist.artist_id;

      if (isFollowing) {
        removeUserFollowedArtist(artist);
      } else {
        addUserFollowedArtist(artist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const showSubscribeButton = () => {
    if (!loggedInUser) return login();

    setSheetModalContent(
      <SubscribeModal
        artist={artist}
        handleSubscribeClick={handleSubscribeClick}
      />
    );
    toggleSheetModal();
  };

  const handleSubscribeClick = async () => {
    try {
      if (!loggedInUser) return login();

      subscribeMutation.mutate({ isSubscribed, isFollowing });

      artist.id = artist.artist_id;

      if (!isFollowing && !isSubscribed) {
        addUserFollowedArtist(artist);
        addUserSubscriptions(artist);
      } else if (!isSubscribed) {
        addUserSubscriptions(artist);
      } else if (isSubscribed) {
        removeUserSubscriptions(artist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <SkeletonElement
          height="268px"
          style={{ width: "100%", position: "absolute" }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <ArtistImage
        src={artist?.banner_img ?? artist?.event_image ?? GlassmorphPlaceholder}
        alt={artist?.name}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />
      <Overlay />
      <DetailsContainer>
        <BottomContainer>
          {/* Render artist name */}
          <IconWithText>
            <ArtistPfp
              src={
                artist?.profile_img ?? artist?.event_image ?? ArtistPlaceholder
              }
              alt={artist?.artist_name}
              onError={(e) => (e.target.src = ArtistPlaceholder)}
            />
            <NameContainer lineHeight="normal">
              {artist?.artist_name}
            </NameContainer>
          </IconWithText>
          <FollowAndSubscribeContainer>
            <FollowButton
              isFollowing={isFollowing}
              handleClick={handleFollowClick}
              size={BUTTON_SIZES.SMALL}
            />
            <SubscribeButton
              isSubscribed={isSubscribed}
              handleClick={
                !isSubscribed ? showSubscribeButton : handleSubscribeClick
              }
            />
          </FollowAndSubscribeContainer>
        </BottomContainer>
      </DetailsContainer>
    </Container>
  );
}

ArtistHeader.propTypes = {
  artist: PropTypes.object,
  isLoading: PropTypes.bool,
};
