import PropTypes from "prop-types";
import styled from "styled-components";
import ConfettiIcon from "../../assets/svg/confetti.svg?react";
import RetroIcon from "../../assets/svg/retro-moment.svg?react";
import EventIcon from "../../assets/svg/events.svg?react";
import MapPinIcon from "../../assets/svg/map-pin.svg?react";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import UserPastMoments, { PastMomentsContainer } from "./UserPastMoments";
import UserActiveMoments from "../Moments/UserActiveMoments";
import EmptyState from "../EmptyState";
import { BodyText } from "../../GlobalStyles";
import { useNavigate } from "react-router-dom";
import { theme } from "../../utils/theme";
import { useState } from "react";
import RectButtonIconText from "../RectButtonIconText";
import PartialEmptyState from "../PartialEmptyState";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

export default function UserMoments({ user, moments, isMomentsLoading }) {
  const { loggedInUser } = useAuth();

  // Check if the user is the owner of the profile
  const isOwnProfile = user?.id === loggedInUser?.id;

  const { activeMoments, pastMoments } = moments ?? {
    activeMoments: [],
    pastMoments: [],
  };

  const navigate = useNavigate();
  const [randomizer] = useState(Math.floor(Math.random() * 2));

  const firstEmptyDescription = () => {
    return (
      <BodyText color={theme.colors.grey}>
        Add to your Moments and get personalised event recommendations.
      </BodyText>
    );
  };

  const secondEmptyDescription = () => {
    return (
      <BodyText color={theme.colors.grey}>
        Check in at live music to add to your Moments! <br /> And earn{" "}
        <span style={{ color: theme.colors.green }}>XP.</span>
      </BodyText>
    );
  };

  if (isMomentsLoading) {
    return (
      <Container>
        <Container style={{ padding: "0 20px" }}>
          <SkeletonElement width="110px" />
          <SkeletonElement width="100%" height="275px" radius="8px" />
        </Container>
        <Container style={{ padding: "0 20px" }}>
          <SkeletonElement width="140px" />
          <PastMomentsContainer>
            {Array.from({ length: 12 }).map((_, index) => (
              <Container
                key={index}
                style={{ gap: "8px", alignItems: "center" }}
              >
                <SkeletonElement width="110px" height="160px" radius="8px" />
                <SkeletonElement width="100px" height="12px" />
                <SkeletonElement width="110px" />
              </Container>
            ))}
          </PastMomentsContainer>
        </Container>
      </Container>
    );
  }

  if (
    !moments ||
    (moments.activeMoments.length == 0 && moments.pastMoments.length == 0)
  ) {
    return (
      <EmptyState
        icon={
          <ConfettiIcon
            height={24}
            width={24}
            stroke={theme.colors.white}
            opacity={0.6}
          />
        }
        title="Collect music memories here!"
        description={
          randomizer === 0 ? firstEmptyDescription() : secondEmptyDescription()
        }
        button={
          isOwnProfile && (
            <RectButtonIconText
              icon={
                <RetroIcon
                  stroke={theme.colors.white}
                  height={14}
                  width={14}
                  strokeWidth={1.5}
                />
              }
              text={<BodyText weight="600">Add past Moments</BodyText>}
              onClick={() => {
                navigate("/search?retro=true&past=true");

                setTimeout(() => {
                  document.querySelector("input[name=search]")?.focus();
                }, 100);
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Container>
      {activeMoments?.length > 0 && (
        <UserActiveMoments activeMoments={activeMoments} user={user} />
      )}
      {pastMoments?.length > 0 && <UserPastMoments pastMoments={pastMoments} />}
      {pastMoments.length < 4 && (
        <PartialEmptyState
          icon={
            randomizer === 0 ? (
              <MapPinIcon
                height={24}
                width={24}
                stroke={theme.colors.white}
                opacity={0.6}
              />
            ) : (
              <EventIcon
                height={24}
                width={24}
                fill={theme.colors.white}
                opacity={0.6}
              />
            )
          }
          description={
            randomizer === 0 ? (
              <BodyText color={theme.colors.grey}>
                Check in at live music to add to your Moments! And earn{" "}
                <span style={{ color: theme.colors.green }}>XP.</span>
              </BodyText>
            ) : (
              <BodyText color={theme.colors.grey}>
                Get smarter event recommendations.
                <br />
                Add more Moments.
              </BodyText>
            )
          }
        />
      )}
    </Container>
  );
}

UserMoments.propTypes = {
  user: PropTypes.object,
  moments: PropTypes.object,
  isMomentsLoading: PropTypes.bool,
};
