import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BodyText, FooterText } from "../../../GlobalStyles";
import CloseLogo from "../../../assets/svg/close.svg?react";
import MomentifyLogo from "../../../assets/svg/momentify.svg?react";
import {
  GlassmorphPlaceholder,
  NearbyMeters,
} from "../../../constants/constants";
import useCheckIn from "../../../hooks/useCheckIn";
import useGeolocation from "../../../hooks/useGeolocation";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import { theme } from "../../../utils/theme";
import { getDistanceFromLatLong } from "../../../utils/utils";
import { OneLineText } from "../../Artist/ArtistEventDateBox/ArtistEventDateBox-Styles";
import {
  CheckInButton,
  CheckInContainer,
  CloseButton,
  EventDetailsContainer,
  EventImage,
  EventTextContainer,
  NearbyContainer,
} from "./CheckInToast-Styles";
import { useCheckMomentExists } from "../../../routes/EventsV2/hooks/checkInHooks";

const { colors } = theme;

export default function CheckInToast({ event, onClick }) {
  const [isToastOpen, setIsToastOpen] = useState(true);
  const { isLoading } = useCheckIn();
  const [isToday, setIsToday] = useState(false);
  const { loggedInUser, login } = useMomentifyLogin();

  const { data: hasMomentExisted } = useCheckMomentExists({
    eventId: event?.event_id,
    userId: loggedInUser?.id,
  });

  const { currentLocation } = useGeolocation();
  const navigate = useNavigate();

  const [isNearby, setIsNearby] = useState(false);
  const [distance, setDistance] = useState(0);
  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    if (!event) return;
    // Get event date
    const eventDate = moment(event?.date ?? event?.event_date);

    // Check if event is today using moments js
    const isToday = eventDate.isSame(moment(), "day");
    setIsToday(isToday);
    if (currentLocation && event) {
      const { latitude: lat1, longitude: lon1 } = currentLocation.coords;
      const distance = Math.max(
        getDistanceFromLatLong(
          lat1,
          lon1,
          event.latitude ?? event.geometry.coordinates[1],
          event.longitude ?? event.geometry.coordinates[0]
        ) - 100,
        0
      );
      setIsNearby(distance <= NearbyMeters);
      setDistance(distance);
      setIsEligible(isToday && distance < 50);
    } else {
      setIsNearby(false);
      setDistance(0);
      setIsEligible(false);
    }
  }, [event, currentLocation]);

  const handleOnClick = () => {
    if (!loggedInUser) return login();

    if (hasMomentExisted) return navigate(`/profile/${loggedInUser?.id}`);

    onClick();
  };

  const displayNearbyButton = isNearby && (
    <NearbyContainer>
      <BodyText>{`You can check in at ${Math.floor(distance)}m`}</BodyText>
    </NearbyContainer>
  );

  const displayCheckInButton = hasMomentExisted ? (
    <CheckInButton onClick={handleOnClick}>
      <MomentifyLogo height={20} width={20} fill="white" />
      <BodyText weight="bold">View your Moment</BodyText>
    </CheckInButton>
  ) : (
    <CheckInButton onClick={handleOnClick}>
      <BodyText weight="bold">Check In</BodyText>
      <FooterText color={colors.green}>+100xp</FooterText>
    </CheckInButton>
  );

  if (isLoading) return null;

  if ((isEligible || isNearby) && isToastOpen && isToday) {
    return (
      <CheckInContainer>
        <CloseButton
          onClick={() => {
            setIsToastOpen(false);
          }}
        >
          <CloseLogo height={16} width={16} stroke="white" />
        </CloseButton>
        <BodyText weight="600" color={colors.grey}>
          {isEligible
            ? "You're at an Event!"
            : isNearby
              ? "You're near an Event!"
              : null}
        </BodyText>
        <EventDetailsContainer>
          <EventImage
            src={event?.image ?? GlassmorphPlaceholder}
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          />
          <EventTextContainer>
            <OneLineText weight="600" style={{ marginBottom: "4px" }}>
              {event?.headline_artist}
            </OneLineText>
            <OneLineText
              color={colors.grey}
              size={theme.fontSizes.mobile.footer}
            >
              {event?.venue_name ?? event?.venue},{" "}
              {event?.country_name ?? event?.country}
            </OneLineText>
            <FooterText color={colors.grey}>
              Doors at {moment(event?.date).format("LT")}
            </FooterText>
          </EventTextContainer>
        </EventDetailsContainer>
        {isEligible ? displayCheckInButton : displayNearbyButton}
      </CheckInContainer>
    );
  }
}

CheckInToast.propTypes = {
  event: PropTypes.object,
  onClick: PropTypes.func,
  hasMomentExisted: PropTypes.bool,
};
