import { useMemo } from "react";
import { BodyText, Button } from "../../../GlobalStyles";
import { TicketButtonsContainer } from "./Ticket-Styles";
import PropTypes from "prop-types";
import { Minus, Plus } from "@phosphor-icons/react";
import { useBuyTicketStore } from "../buyTicketStore";

export default function TicketButtons({
  ticket,
  quantitySelected,
  handleQuantityChange,
  status,
  isLoading,
}) {
  const { quantity } = ticket || {};
  const { maxCount, userMints, userTickets, soldOut, success } = status || {};
  const { ticketsSelected } = useBuyTicketStore();

  const maxCountSelected = useMemo(() => {
    const userTicket = userTickets?.find((t) => t.type === ticket.type);

    const maxxed = userTicket?.remaining - quantitySelected <= 0 ? true : false;

    return maxxed;
  }, [userTickets, quantitySelected, ticket.type]);

  const totalRemaining = useMemo(() => {
    const totalRemainingTickets = userTickets.reduce(
      (acc, ticket) => acc + ticket.remaining,
      0
    );

    if (maxCount <= totalRemainingTickets) {
      return maxCount;
    }

    if (totalRemainingTickets <= userMints) {
      return totalRemainingTickets;
    }
    return totalRemainingTickets - userMints;
  }, [userTickets, userMints, maxCount]);

  const isMaxQuantity = ticketsSelected.length >= totalRemaining;

  const notAvailable =
    isLoading || !success || soldOut || userMints >= maxCount || quantity == 0;

  return (
    <TicketButtonsContainer>
      <Button
        onClick={() => handleQuantityChange(quantitySelected - 1, true)}
        disabled={quantitySelected === 0 || notAvailable}
      >
        <Minus size={16} weight="bold" />
      </Button>
      <BodyText weight="600">{quantitySelected}</BodyText>
      <Button
        primary
        onClick={() => handleQuantityChange(quantitySelected + 1)}
        disabled={
          quantitySelected >= quantity ||
          isMaxQuantity ||
          notAvailable ||
          maxCountSelected
        }
      >
        <Plus size={16} weight="bold" />
      </Button>
    </TicketButtonsContainer>
  );
}

TicketButtons.propTypes = {
  ticket: PropTypes.object,
  quantitySelected: PropTypes.number,
  handleQuantityChange: PropTypes.func,
  status: PropTypes.object,
  isLoading: PropTypes.bool,
};
