import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ArtistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.blue};
  gap: 8px;
  padding: 8px 16px;
  border-radius: 10px;
`;

export const SupportingArtists = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.blue};
  overflow: hidden;
  border-radius: 10px;
  gap: 12px;
`;

export const SupportingArtistImages = styled.div`
  display: flex;
  align-items: center;
`;

export const ExtraSupportingCount = styled.div`
  min-height: 40px;
  min-width: 40px;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(10px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SupportingArtistNames = styled.div``;

export const SeeAllArtists = styled(BodyText)`
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
`;
