import styled from "styled-components";
import { ButtonRect } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Content = styled(Header)`
  flex-direction: column;
  width: 85%;
`;

export const ModalButton = styled(ButtonRect)`
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.black};
  border: none;
`;
