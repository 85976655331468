import { BodyText, ButtonRect, HeaderL } from "../../../GlobalStyles";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { theme } from "../../../utils/theme";
import PlaneIcon from "../../../assets/svg/plane.svg?react";
import { Container, HeaderContainer } from "./TransferTicketModal-Styles";

export default function TransferTicketModal() {
  const { toggleSheetModal } = useGlobalModal();

  return (
    <Container>
      <HeaderContainer>
        <PlaneIcon stroke={theme.colors.green} height={24} width={24} />
        <HeaderL
          style={{
            textTransform: "uppercase",
          }}
        >
          Ticket Transfer
        </HeaderL>
      </HeaderContainer>
      <BodyText secondary>
        This feature is not ready yet. Hold on to these tickets and show them
        when you get to the venue.
      </BodyText>
      <ButtonRect primary onClick={toggleSheetModal}>
        Okay
      </ButtonRect>
    </Container>
  );
}
