import { useParams, useSearchParams } from "react-router-dom";
import {
  usePerformanceCollectiblesQuery,
  usePerformanceQuery,
} from "./hooks/performanceQueries.jsx";
import { Container } from "./Performance-Styles.jsx";
import { useRetroFlowStore } from "../Search/hooks/searchStore.jsx";
import RetroAddFlow from "../../components/Retro/RetroAddFlow/index.jsx";
import { useMemo } from "react";
import ErrorPage from "../Error/index.jsx";
import TopNavV2 from "../../components/TopNavV2/index.jsx";
import ShareButton from "../../components/ShareButton.jsx";
import PerformanceHeader from "../../components/Performance/PerformanceHeader/index.jsx";
import PerformanceTabs from "../../components/Performance/PerformanceTabs/index.jsx";

export default function PerformanceMoments() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const { isOpen } = useRetroFlowStore();
  const { data, error, isLoading, isFetched } = usePerformanceQuery({
    id,
    date,
  });
  const { data: collectibles, isLoading: isCollectiblesLoading } =
    usePerformanceCollectiblesQuery({ performanceId: id });

  const { performances } = data || {};
  const performance = performances?.length > 0 ? performances[0] : null;

  const withFanPhotos = useMemo(() => {
    return performances?.length > 0
      ? performances.filter(
          (performance) =>
            performance.fan_photo_urls && performance.fan_photo_urls?.length > 0
        )
      : [];
  }, [performances]);

  const { image, artist, venue } = performance || {};

  if (error) return <ErrorPage />;

  if (!performance && isFetched)
    return <ErrorPage description="We can't find this performance." />;

  return (
    <Container>
      <TopNavV2
        icon={image}
        title={artist}
        content={
          <ShareButton
            url={window.location.href}
            title={`${artist}'s Moments`}
            text={`Check out ${artist}'s Moments at ${venue}!`}
          />
        }
        isLoading={isLoading}
      />
      <PerformanceHeader data={data} isLoading={isLoading} />
      <PerformanceTabs
        moments={withFanPhotos}
        collectibles={collectibles}
        isMomentsLoading={isLoading}
        isCollectiblesLoading={isCollectiblesLoading}
      />
      {isOpen && <RetroAddFlow />}
    </Container>
  );
}
