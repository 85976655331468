import { HeaderContainer } from "../../../routes/Collectible/Collectible-Styles";
import { FooterText, IconWithText, Title } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import PosterIcon from "../../../assets/svg/poster-icon.svg?react";
import PhotoIcon from "../../../assets/svg/photo.svg?react";
import AirdropIcon from "../../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useSearchParams } from "react-router-dom";

const { colors } = theme;

export default function CollectibleHeader({ collectible, isLoading }) {
  const { id, type } = collectible || {};
  const [searchParams] = useSearchParams();
  const editionType = searchParams?.get("type");
  const isAirdrop = editionType === "airdrop";

  if (isLoading) {
    return (
      <HeaderContainer>
        <SkeletonElement height="24px" width="70px" />
        <HeaderContainer style={{ gap: "12px" }}>
          <SkeletonElement height="24px" width="80px" />
          <SkeletonElement height="24px" width="80px" />
        </HeaderContainer>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <Title>#{id}</Title>
      <HeaderContainer style={{ gap: "12px" }}>
        <IconWithText gap="8px">
          {type === "Poster" ? (
            <PosterIcon stroke={colors.grey} height="17px" width="17px" />
          ) : (
            <PhotoIcon stroke={colors.grey} height="17px" width="17px" />
          )}

          <FooterText
            color={colors.grey}
            style={{ textTransform: "capitalize" }}
          >
            {type === "Poster" ? "Poster" : "Photo"}
          </FooterText>
        </IconWithText>
        {editionType && (
          <IconWithText gap="8px">
            {isAirdrop ? (
              <AirdropIcon stroke={colors.grey} height={17} width={17} />
            ) : (
              <EditionIcon stroke={colors.grey} height={17} width={17} />
            )}
            <FooterText
              color={colors.grey}
              style={{ textTransform: "capitalize" }}
            >
              {editionType}
            </FooterText>
          </IconWithText>
        )}
      </HeaderContainer>
    </HeaderContainer>
  );
}

CollectibleHeader.propTypes = {
  collectible: PropTypes.object,
  isLoading: PropTypes.bool,
};
