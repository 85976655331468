import { useMemo } from "react";
import {
  BodyText,
  FooterText,
  FooterTextSm,
  IconWithText,
  OneLineText,
} from "../../../GlobalStyles";
import {
  ArtistImage,
  Container,
  EventContainer,
  EventDetails,
  ExtraSupportingCount,
  SupportingArtistImages,
  SupportingArtistNames,
  SupportingArtists,
  TicketCount,
  TicketDetails,
  TicketImage,
  TicketImageContainer,
  TicketPrice,
  TicketType,
  VenueAndDateDetails,
} from "./TicketItem-Styles";
import PropTypes from "prop-types";
import {
  ArtistPlaceholder,
  GlassmorphPlaceholder,
} from "../../../constants/constants";
import { theme } from "../../../utils/theme";
import Map from "../../../assets/svg/map.svg?react";
import Bookmark from "../../../assets/svg/bookmark.svg?react";
import { formatPrice } from "../../../utils/utils";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { fontStyles, fontSizes, colors } = theme;

export default function TicketItem({ ticket }) {
  const {
    type,
    price,
    count,
    headline_artist,
    artists,
    artists_lineup,
    image,
    venue,
    city,
    country,
    date,
  } = ticket || {};
  const [searchParams] = useSearchParams();
  const dateParam = searchParams.get("date");

  const formattedPrice = formatPrice(price, false);

  const headlineArtist = useMemo(() => {
    if (artists?.length > 0) {
      return artists?.find((artist) => artist?.artist_name === headline_artist);
    } else if (artists_lineup?.length > 0) {
      return artists_lineup?.find(
        (artist) => artist?.artist_name === headline_artist
      );
    }
  }, [artists, artists_lineup, headline_artist]);

  if (!ticket) return null;

  return (
    <Container>
      <TicketDetails>
        <TicketPrice>${formattedPrice}</TicketPrice>
        <TicketType>{type === "General admission" ? "GA" : type}</TicketType>
      </TicketDetails>
      <TicketImageContainer>
        <TicketCount>
          <FooterText
            style={{
              fontFamily: fontStyles.druk,
              fontWeight: 900,
            }}
          >
            {count}
          </FooterText>
        </TicketCount>
        <TicketImage
          src={image ?? GlassmorphPlaceholder}
          alt="Ticket"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
      </TicketImageContainer>
      <EventContainer>
        <EventDetails>
          <IconWithText gap="8px">
            <ArtistImage
              src={headlineArtist?.profile_img ?? ArtistPlaceholder}
              alt="Artist"
              onError={(e) => (e.target.src = ArtistPlaceholder)}
            />
            <BodyText weight="600">{headlineArtist?.artist_name}</BodyText>
          </IconWithText>
          <VenueAndDateDetails>
            <IconWithText gap="6px">
              <div>
                <Map fill={colors.white} height={10} width={10} />
              </div>
              <OneLineText style={{ fontSize: fontSizes.mobile.footerSm }}>
                {venue}, {city}, {country}
              </OneLineText>
            </IconWithText>
            <IconWithText gap="6px">
              <div>
                <Bookmark fill={colors.white} height={10} width={10} />
              </div>
              <OneLineText
                style={{
                  fontSize: fontSizes.mobile.footerSm,
                  color: colors.green,
                }}
              >
                {dateParam
                  ? moment(dateParam).format("DD MMM YYYY")
                  : moment(date).utc().format("DD MMM YYYY")}
              </OneLineText>
            </IconWithText>
          </VenueAndDateDetails>
        </EventDetails>
        {artists?.length > 1 && (
          <SupportingArtists>
            <SupportingArtistImages>
              {artists
                .filter((artist) => artist?.artist_name !== headline_artist)
                .map(
                  (artist, index) =>
                    index < 3 && (
                      <ArtistImage
                        style={{
                          width: 16,
                          height: 16,
                          translate: index > 0 && "-4px 0px",
                        }}
                        key={artist.id}
                        src={artist?.profile_img}
                        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
                      />
                    )
                )}
              {artists?.length > 3 && (
                <ExtraSupportingCount>
                  <FooterTextSm>{artists?.length - 3}</FooterTextSm>
                </ExtraSupportingCount>
              )}
            </SupportingArtistImages>
            <SupportingArtistNames>
              {artists
                .filter((artist) => artist?.artist_name !== headline_artist)
                .map(
                  (artist, index) =>
                    index < 3 && (
                      <FooterTextSm
                        key={artist?.id}
                        style={{
                          minWidth: "fit-content",
                        }}
                      >
                        {artist?.artist_name}
                        {index < 2 && artists?.length > 2 ? "," : ""}
                      </FooterTextSm>
                    )
                )}
            </SupportingArtistNames>
          </SupportingArtists>
        )}
      </EventContainer>
    </Container>
  );
}

TicketItem.propTypes = {
  ticket: PropTypes.object.isRequired,
};
