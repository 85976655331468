import PropTypes from "prop-types";
import { DescriptionItemsContainer } from "../../../routes/Collectible/Collectible-Styles";
import CollectibleDescriptionItem from "../../Collectibles/CollectibleDescriptionItem";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { Container, Content, Header } from "./TicketAdditionalInfo-Styles";
import { BodyText } from "../../../GlobalStyles";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { theme } from "../../../utils/theme";
import { useMemo, useState } from "react";

export default function TicketAdditionalInfo({ contractData, isLoading }) {
  const {
    symbol,
    royaltyPercentage,
    maxSupplyVIP,
    maxSupplyGA,
    contract_address,
    ticketType,
    TicketTiers,
  } = contractData || {};

  const ticketTier = useMemo(() => {
    return TicketTiers?.find((tier) => tier?.name === ticketType);
  }, [TicketTiers, ticketType]);

  const { maxResalePrice } = ticketTier || {};

  const [isOpen, setIsOpen] = useState(false);

  const toggleInfo = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return (
      <DescriptionItemsContainer>
        {Array.from({ length: 6 }).map((_, index) => (
          <SkeletonElement key={index} height="63px" width="100%" />
        ))}
      </DescriptionItemsContainer>
    );
  }

  return (
    <Container isOpen={isOpen}>
      <Header onClick={toggleInfo}>
        <BodyText bold>Additional info</BodyText>
        {isOpen ? (
          <CaretUp color={theme.colors.grey} weight="bold" />
        ) : (
          <CaretDown color={theme.colors.grey} weight="bold" />
        )}
      </Header>
      <Content isOpen={isOpen}>
        <DescriptionItemsContainer
          style={{
            margin: 0,
          }}
        >
          <CollectibleDescriptionItem
            label="Max resale price"
            value={`$ ${maxResalePrice ?? 0}`}
            icon={<USDCIcon height={16} width={16} />}
          />
          <CollectibleDescriptionItem
            label="Edition"
            value="ERC-721"
            icon={<EditionIcon />}
          />
          {royaltyPercentage && (
            <CollectibleDescriptionItem
              label="Creator earnings"
              value={`${royaltyPercentage}%`}
            />
          )}
          {(maxSupplyGA || maxSupplyVIP) && (
            <CollectibleDescriptionItem
              label="Total supply"
              value={(ticketType === "VIP" ? maxSupplyVIP : maxSupplyGA) ?? 0}
            />
          )}
          {contract_address && (
            <CollectibleDescriptionItem
              label="Contract address"
              value={`${contract_address.slice(0, 4)}...${contract_address.slice(-4)}`}
            />
          )}
          {symbol && (
            <CollectibleDescriptionItem
              label="Contract symbol"
              value={symbol}
            />
          )}
        </DescriptionItemsContainer>
      </Content>
    </Container>
  );
}

TicketAdditionalInfo.propTypes = {
  contractData: PropTypes.object,
  isLoading: PropTypes.bool,
};
