import { theme } from "../utils/theme";

export const ONBOARDING_IMAGES = {
  MOMENTS: "https://mediacontent.momentify.xyz/website/moments.webp",
  REWARDS: "https://mediacontent.momentify.xyz/website/rewards.webp",
  TRIBE: "https://mediacontent.momentify.xyz/website/tribe.webp",
  ARTISTS: "https://mediacontent.momentify.xyz/website/artists.webp",
};

const { MOMENTS, REWARDS, TRIBE, ARTISTS } = ONBOARDING_IMAGES;

export const CARDS = [
  {
    title: "Capture Moments",
    description:
      "Check in 📍, add video memories 📹, and create verified proofs 📃 that “You were there when..”",
    image: MOMENTS,
    color: theme.colors.onboardingGreen,
  },
  {
    title: "Support Artists",
    description:
      "Buy tickets 🎫 and memorabilia 🛍️ directly from the artists you love.",
    image: ARTISTS,
    color: theme.colors.onboardingBlue,
  },
  {
    title: "Earn Rewards",
    description:
      "Get points 🏆 (XP) for keeping track of the events 📅 you attend and content you share.",
    image: REWARDS,
    color: theme.colors.onboardingPink,
  },
  {
    title: "Find Your Tribe",
    description:
      "Get personalised event recommendations 🎤. Chat 💬 with other fans going to shows. ",
    image: TRIBE,
    color: theme.colors.onboardingSoftBlue,
  },
];
