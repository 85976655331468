import PropTypes from "prop-types";
import { theme } from "../../../utils/theme";
import MomentCard from "../MomentCard";
import useAuth from "../../../hooks/useAuth";
import CollectButton from "../CollectButton";
import { BodyText } from "../../../GlobalStyles";
import {
  BackButtonContainer,
  CollectCardContainer,
  MomentCardsContainer,
  VisibleCardsContainer,
} from "./MomentCardsView-Styles";
import BackButton from "../../BackButton";
import { GlassmorphPlaceholder } from "../../../constants/constants";

const { colors } = theme;

export default function MomentCardsView({
  moment,
  handleIsCard,
  isMomentsFeed,
}) {
  const { fan_photo_urls, user_id, transaction_reference } = moment || {};
  const { loggedInUser } = useAuth();
  const isOwner = loggedInUser?.id === user_id;

  return (
    <VisibleCardsContainer>
      <BackButtonContainer>
        <BackButton onClick={handleIsCard} />
      </BackButtonContainer>
      {!isMomentsFeed &&
      isOwner &&
      (!fan_photo_urls || fan_photo_urls?.length == 0) ? (
        <MomentCardsContainer isOwner={isOwner}>
          <MomentCard moment={moment} isOwner={isOwner} isFanPhoto />
        </MomentCardsContainer>
      ) : (
        <MomentCardsContainer isOwner={isOwner}>
          {fan_photo_urls?.length > 0 && (
            <MomentCard moment={moment} image={fan_photo_urls[0]} isFanPhoto />
          )}
          {!fan_photo_urls?.length && (
            <MomentCard moment={moment} image={GlassmorphPlaceholder} />
          )}
        </MomentCardsContainer>
      )}
      {isOwner && !isMomentsFeed && (
        <CollectCardContainer>
          <CollectButton moment={moment} />
          <BodyText color={colors.grey}>
            {!transaction_reference ? "Earn 25xp and unlock app features!" : ""}
          </BodyText>
        </CollectCardContainer>
      )}
    </VisibleCardsContainer>
  );
}

MomentCardsView.propTypes = {
  moment: PropTypes.object,
  handleIsCard: PropTypes.func,
  isMomentsFeed: PropTypes.bool,
};
