import useAuth from "../../hooks/useAuth";
import { useRecommendEvents } from "../../routes/Events/hooks/eventsQueries";
import { useUserStore } from "../../routes/Profile/store/userStore";

import { EventCard } from "./components/EventCard";
import { SkeletonElements } from "./components/SkeletonElements";
import { SmallHeader } from "./components/SmallHeader";

export const TopPicks = () => {
  const { loggedInUser } = useAuth();
  const { userCountry } = useUserStore();

  const recommendEventsQuery = useRecommendEvents({
    userId: loggedInUser?.id,
    userCountry: userCountry,
  });

  const headerText = loggedInUser?.id
    ? "Recommended events"
    : "Top picks around the world";

  const data = recommendEventsQuery?.data;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}
    >
      {recommendEventsQuery.isLoading ? (
        <SkeletonElements />
      ) : (
        <>
          <SmallHeader headerText={headerText} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              overflow: "scroll",
              marginRight: "-20px",
              paddingRight: 20,
              marginLeft: "-20px",
              paddingLeft: 20,
            }}
          >
            {data?.map((event, index) => (
              <EventCard
                event={event}
                key={event.event_id}
                isEvent={true}
                vertical_position={0}
                horizontal_position={index}
                header={headerText}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
