import PropTypes from "prop-types";
import { BodyText } from "../../../GlobalStyles";
import RoomButton from "../../RoomButton";
import { Container } from "./EventRoomInfo-Styles";

export default function EventRoomInfo({ event, participants }) {
  return (
    <Container>
      <RoomButton event={event} withCount />
      <BodyText>{participants} interested</BodyText>
    </Container>
  );
}

EventRoomInfo.propTypes = {
  event: PropTypes.object,
  participants: PropTypes.number,
};
