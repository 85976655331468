import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BodyText } from "../../../GlobalStyles";
import {
  followArtist,
  getFollowActionArtist,
  getSubscribeAction,
  subscribeToArtist,
  unfollowArtist,
  unsubscribeFromArtist,
} from "../../../api/activities";
import Close from "../../../assets/svg/close.svg?react";
import {
  ArtistPlaceholder,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../../constants/constants";
import {
  RECENT_SEARCH_TYPES,
  SEARCH_PARAMS,
  SEARCH_TABS,
} from "../../../constants/search";
import useGlobalModal from "../../../hooks/useGlobalModal";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import useNotification from "../../../hooks/useNotification";
import { useSaveSearchHistory } from "../../../routes/Search/hooks/searchQueries";
import { theme } from "../../../utils/theme";
import FollowButton from "../../FollowButton";
import SubscribeModal from "../../Modal/SubscribeModal";
import SubscribeButton from "../SubscribeButton";
import {
  ArtistDetails,
  ArtistIcon,
  Container,
  FollowSubContainer,
  PerformanceButton,
} from "./ArtistItem-Styles";
import { useUserStore } from "../../../routes/Profile/store/userStore";

const { colors } = theme;
const { ARTIST: ARTIST_TYPE } = RECENT_SEARCH_TYPES;
const { QUERY, TAB, ARTIST } = SEARCH_PARAMS;
const { EVENT } = SEARCH_TABS;

export default function ArtistItem({
  artist,
  isPostEvent,
  isSearchMode,
  handleRemoveHistory,
  innerRef,
}) {
  const { loggedInUser, login } = useMomentifyLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const { notifiable_token } = useNotification();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const {
    addUserFollowedArtist,
    removeUserFollowedArtist,
    userFollowedArtists,
    userSubscriptions,
    addUserSubscriptions,
    removeUserSubscriptions,
  } = useUserStore();

  const [isFollowing, setIsFollowing] = useState(
    userFollowedArtists.some((art) => art.id == artist.id)
  );

  const [isSubscribed, setIsSubscribed] = useState(
    userSubscriptions.some((art) => art.id == artist.id)
  );

  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  // Mutation to save search history
  const saveSearchHistoryMutation = useSaveSearchHistory();

  const handleFollowClick = async (e) => {
    e.preventDefault();

    if (!loggedInUser) {
      return login();
    }

    if (!isFollowing) {
      setIsFollowing(true);
      addUserFollowedArtist(artist);
      toast.success("Followed artist");

      await followArtist(loggedInUser.id, artist.id).catch((err) => {
        console.error(err);
        setIsFollowing(false);
        removeUserFollowedArtist(artist);
        return toast.error("An error occurred. Please try again later.");
      });

      // queryClient.invalidateQueries({
      //   queryKey: ["following", loggedInUser.id.toString()],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ["artist", artist.id.toString()],
      // });

      location.pathname.includes("events") &&
        (await queryClient.invalidateQueries({
          queryKey: ["event", location.pathname.split("/")[2]],
        }));

      return;
    }

    setIsFollowing(false);
    removeUserFollowedArtist(artist);
    toast.success("Unfollowed the artist");

    getFollowActionArtist(loggedInUser.id, artist.id)
      .then((res) => {
        if (!res.success)
          return toast.error("An error occurred. Please try again later.");
        unfollowArtist(loggedInUser.id, artist.id, res?.data?.id);
      })
      .catch((err) => {
        console.error(err);
        setIsFollowing(true);
        addUserFollowedArtist(artist);
        return toast.error("An error occurred. Please try again later.");
      });

    // queryClient.invalidateQueries({
    //   queryKey: ["following", loggedInUser.id.toString()],
    // });
    // queryClient.invalidateQueries({
    //   queryKey: ["artist", artist.id.toString()],
    // });

    location.pathname.includes("events") &&
      (await queryClient.invalidateQueries({
        queryKey: ["event", location.pathname.split("/")[2]],
      }));
  };

  const showSubscribeButton = (e) => {
    e.preventDefault();

    if (!loggedInUser) return login();

    setSheetModalContent(
      <SubscribeModal
        artist={artist}
        handleSubscribeClick={handleSubscribeClick}
      />
    );

    toggleSheetModal();
  };

  const handleSubscribeClick = async (e) => {
    e?.preventDefault();

    if (!loggedInUser) {
      return login();
    }

    if (!isFollowing && !isSubscribed) {
      setIsFollowing(true);
      setIsSubscribed(true);
      addUserFollowedArtist(artist);
      addUserSubscriptions(artist);

      await followArtist(loggedInUser.id, artist.id).catch((err) => {
        console.error(err);
        setIsFollowing(false);
        removeUserFollowedArtist(artist);
        return toast.error("An error occurred. Please try again later.");
      });
    }

    if (!isSubscribed) {
      setIsSubscribed(true);
      addUserSubscriptions(artist);
      toast.success("Subscribed to artist + 2xp");

      await subscribeToArtist(
        loggedInUser.id,
        artist.id,
        notifiable_token
      ).catch((err) => {
        console.error(err);
        setIsSubscribed(false);
        removeUserSubscriptions(artist);
        return toast.error("An error occurred. Please try again later.");
      });
    } else if (isSubscribed) {
      setIsSubscribed(false);
      removeUserSubscriptions(artist);
      toast.success("Unsubscribed from artist, XP removed");

      getSubscribeAction(loggedInUser.id, artist.id)
        .then((res) => {
          if (!res.success)
            return toast.error("An error occurred. Please try again later.");
          unsubscribeFromArtist(
            loggedInUser.id,
            artist.id,
            res?.data?.id,
            notifiable_token
          );
        })
        .catch((err) => {
          console.error(err);
          setIsSubscribed(true);
          addUserSubscriptions(artist);
          return toast.error("An error occurred. Please try again later.");
        });
    }

    // queryClient.removeQueries({
    //   queryKey: ["following", loggedInUser.id.toString()],
    // });
    // queryClient.removeQueries({
    //   queryKey: ["artist", artist.id.toString()],
    // });
    location.pathname.includes("events") &&
      (await queryClient.invalidateQueries({
        queryKey: ["event", location.pathname.split("/")[2]],
      }));
  };

  const handleItemClick = () => {
    if (!isSearchMode) return navigate(`/artists/${artist.id}`);

    const input = document.querySelector("input[name=search]");

    if (input) input.value = artist.artist_name;

    searchParams.set(QUERY, artist.artist_name);
    searchParams.set(TAB, EVENT);
    searchParams.set(ARTIST, artist.id);
    setSearchParams(searchParams);

    saveSearchHistoryMutation.mutate({
      loggedInUser,
      type: ARTIST_TYPE,
      value: artist,
    });
  };

  const handlePerformanceClick = () => {
    navigate(
      `/performances/${artist.performance_id}?date=${window.location.href.split("date=")[1]}`
    );
  };

  return (
    <Container ref={innerRef}>
      <ArtistDetails onClick={handleItemClick}>
        <ArtistIcon
          loading="lazy"
          src={artist?.profile_img || artist?.image || ArtistPlaceholder}
          alt="artist"
          onError={(e) => (e.target.src = ArtistPlaceholder)}
        />
        <BodyText truncate>{artist.artist_name}</BodyText>
      </ArtistDetails>
      <FollowSubContainer>
        {!handleRemoveHistory ? (
          <>
            <FollowButton
              isFollowing={isFollowing}
              handleClick={handleFollowClick}
              type={
                location.pathname.includes("events")
                  ? BUTTON_TYPES.SQUARE
                  : BUTTON_TYPES.RECTANGLE
              }
              size={BUTTON_SIZES.SMALL}
            />
            <SubscribeButton
              isSubscribed={isSubscribed}
              handleClick={
                !isSubscribed ? showSubscribeButton : handleSubscribeClick
              }
            />
          </>
        ) : (
          <Close
            stroke={colors.grey}
            height={16}
            width={16}
            onClick={handleRemoveHistory}
          />
        )}
        {isPostEvent && (
          <PerformanceButton onClick={handlePerformanceClick}>
            <BodyText>See Photos</BodyText>
          </PerformanceButton>
        )}
      </FollowSubContainer>
    </Container>
  );
}

ArtistItem.propTypes = {
  artist: PropTypes.object,
  isPostEvent: PropTypes.bool,
  isSearchMode: PropTypes.bool,
  handleRemoveHistory: PropTypes.func,
  innerRef: PropTypes.any,
};
