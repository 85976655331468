import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 0 16px;
  background: ${({ theme, bg }) =>
    bg
      ? `linear-gradient(#11111fae,#11111Fae, #11111Fae, ${theme.colors.black}, ${theme.colors.black}), url(${bg})`
      : "transparent"};
  background-size: cover;
  background-position: center;
  gap: 16px;
`;

export const Header = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

export const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  padding: 0 20px 16px;
`;
