import PropTypes from "prop-types";
import {
  AvatarPlaceholder,
  GlassmorphPlaceholder,
  UsernameTypes,
} from "../../../constants/constants";
import { theme } from "../../../utils/theme";
import { getUsernameByType } from "../../../utils/username";
import {
  getLatWithLetter,
  getLonWithLetter,
  setImageOfEvent,
  setImageofMoment,
} from "../../../utils/utils";
import {
  BodyText,
  DrukSpan,
  DrukWideSpan,
  FooterText,
} from "../../../GlobalStyles";
import Polaroids from "../../../assets/svg/polaroids.svg?react";
import Badge from "../../../assets/svg/badge.svg?react";
import OnchainIcon from "../../../assets/svg/onchain.svg?react";
import RetroIcon from "../../../assets/svg/retro-moment.svg?react";
import _moment from "moment";
import MomentifyQrCode from "../MomentifyQrCode";
import Play from "../../../assets/svg/play.svg?react";
import { differenceInHours } from "date-fns";
import MomentCardUpload from "../MomentCardUpload";
import {
  Avatar,
  CardContainer,
  CardName,
  Footer,
  FooterIconsContainer,
  FooterInfo,
  FooterInfoRight,
  FooterInfoText,
  Header,
  HeaderInfo,
  MomentContainer,
  MomentImage,
  MomentInfo,
  MomentInfoContainer,
  MomentInfoLeft,
  MomentInfoRight,
  Overlay,
  QuestionMark,
} from "./MomentCard-Styles";

const { fontSizes } = theme;

/**
 * Renders a MomentCard component with the given moment, performance, event, image, isFanPhoto, user, and noAccount props.
 *
 * @param {Object} moment - The moment data
 * @param {Object} performance - The performance data
 * @param {Object} event - The event data
 * @param {string} image - The image URL
 * @param {boolean} isFanPhoto - Indicates if the photo is from a fan
 * @param {Object} user - The user data
 * @param {boolean} noAccount - Indicates if the user has no account
 * @return {React.JSX.Element} The rendered MomentCard component
 */
function MomentCard({
  moment,
  performance,
  event,
  image,
  isFanPhoto,
  isOwner,
  user,
  noAccount,
}) {
  const artistName = (
    moment?.performance_artist ??
    moment?.headline_artist ??
    event?.headline_artist ??
    ""
  ).toUpperCase();
  const username =
    moment || user
      ? getUsernameByType(user ?? moment, UsernameTypes.USERNAME)
      : "@username";
  const isVideo = moment?.fan_photo_urls?.some((url) => url.includes(".mp4"));
  const momentVideo =
    moment?.fan_photo_urls?.length > 0 && moment?.fan_photo_urls[0];
  const momentImage =
    isFanPhoto && !image
      ? null
      : moment
        ? setImageofMoment(moment, image)
        : image ?? setImageOfEvent(event);
  const songName = moment?.song_title;
  const avatar = moment?.avatar ?? user?.avatar ?? AvatarPlaceholder;
  const within48Hours =
    differenceInHours(new Date(), new Date(moment?.created_at)) < 48;

  const latAndLng = moment
    ? `${getLatWithLetter(moment?.latitude)}, ${getLonWithLetter(moment?.longitude)}`
    : !event?.geometry
      ? `${getLatWithLetter(event?.latitude)}, ${getLonWithLetter(event?.longitude)}`
      : `${getLatWithLetter(event?.geometry.coordinates[1])}, ${getLonWithLetter(event?.geometry.coordinates[0])}`;

  const handleSongRedirect = () => {
    if (!moment?.song_url) return;

    window.open(moment?.song_url, "_blank");
  };

  return (
    <CardContainer>
      {/* Moment Image */}
      <Overlay />
      {isVideo && isFanPhoto ? (
        <MomentImage
          as="video"
          src={`${momentVideo}#t=0.01`}
          muted
          preload="metadata"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          style={{
            pointerEvents: "none",
          }}
        />
      ) : (
        <MomentImage
          bg={momentImage}
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
      )}
      {within48Hours &&
        isFanPhoto &&
        isOwner &&
        (!image || moment?.fan_photo_urls) && <MomentCardUpload />}
      {/* Moment Container */}
      <MomentContainer blur={noAccount}>
        {/* Render Question Mark if user has no account */}
        {noAccount && <QuestionMark>?</QuestionMark>}
        {/* Moment Header */}
        <Header>
          <HeaderInfo>
            <CardName>{artistName}</CardName>
            <span>
              {noAccount ? (
                <BodyText>#CHECK IN NO.</BodyText>
              ) : (
                <>
                  <DrukWideSpan size={fontSizes.mobile.body}>
                    #{" "}
                    {moment?.moment_position ||
                      moment?.official_checkin_number ||
                      1}
                  </DrukWideSpan>{" "}
                  <BodyText>
                    /{" "}
                    {moment?.total_moments_per_performance ||
                      moment?.total_moments_per_event ||
                      1}
                  </BodyText>
                </>
              )}
            </span>
          </HeaderInfo>
        </Header>
        {/* Moment Info */}
        <MomentInfoContainer>
          <MomentInfo>
            {/* Moment Info Left Side */}
            <MomentInfoLeft>
              <DrukSpan size="16px">
                {(
                  moment?.venue_name ??
                  event?.venue_name ??
                  event?.venue
                )?.toUpperCase()}
              </DrukSpan>
              {/* Moment Location */}
              <DrukSpan size="16px">
                {/* If user has no account, show "???° N, ???° W (GEO LOCATION)" */}
                {noAccount ? "???° N, ???° W (GEO LOCATION)" : latAndLng}
              </DrukSpan>
            </MomentInfoLeft>
            {/* Moment Info Right Side */}
            <MomentInfoRight>
              {/* Moment Date */}
              <DrukSpan size="16px">
                {performance
                  ? _moment(new Date()).format("DDo MMM yyyy").toUpperCase()
                  : _moment(new Date(moment?.created_at ?? event?.date))
                      .format("Do MMM yyyy")
                      .toUpperCase()}
              </DrukSpan>
              {/* Moment Time */}
              <DrukSpan size="16px">
                {/* If user has no account, show "?? : ?? (TIME)" */}
                {noAccount
                  ? "?? : ?? (TIME)"
                  : performance
                    ? _moment(new Date()).format("HH:mm")
                    : _moment(
                        new Date(moment?.created_at ?? event?.date)
                      ).format("HH:mm")}
              </DrukSpan>
            </MomentInfoRight>
          </MomentInfo>
          {/* Moment Footer */}
          <Footer blur={momentImage}>
            <FooterInfo>
              {/* Song Name */}
              {songName && (
                <FooterInfoText onClick={handleSongRedirect}>
                  <Play height={16} width={16} fill="white" />
                  <FooterText>{songName}</FooterText>
                </FooterInfoText>
              )}
              {/* Username */}
              <FooterInfoText>
                <Avatar
                  src={avatar}
                  onError={(e) => (e.target.src = AvatarPlaceholder)}
                />
                <FooterText>{username}</FooterText>
              </FooterInfoText>
            </FooterInfo>
            {/* Footer Info Right Side */}
            <FooterInfoRight>
              {/* Icons */}
              <FooterIconsContainer>
                {moment?.transaction_reference ? (
                  <OnchainIcon
                    height={14}
                    width={14}
                    stroke={theme.colors.white}
                  />
                ) : moment?.type === "retro" ? (
                  <RetroIcon
                    height={14}
                    width={14}
                    stroke={theme.colors.white}
                  />
                ) : (
                  <RetroIcon height={14} width={14} stroke="null" />
                )}
                {isFanPhoto ? (
                  <Polaroids
                    height={14}
                    width={14}
                    stroke={theme.colors.white}
                  />
                ) : (
                  <Badge height={14} width={14} stroke={theme.colors.white} />
                )}
              </FooterIconsContainer>
              {/* Moment Qr Code */}
              <MomentifyQrCode
                size={35}
                value={`${window.location.origin}/events/${
                  moment?.event_id ?? event?.event_id ?? event?.id
                }?date=${moment?.event_date && moment?.event_date.split("+")[0]}`}
              />
            </FooterInfoRight>
          </Footer>
        </MomentInfoContainer>
      </MomentContainer>
    </CardContainer>
  );
}

MomentCard.propTypes = {
  moment: PropTypes.object,
  performance: PropTypes.object,
  event: PropTypes.object,
  user: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  image: PropTypes.string,
  isFanPhoto: PropTypes.bool,
  isOwner: PropTypes.bool,
  noAccount: PropTypes.bool,
  isVideo: PropTypes.bool,
};

export default MomentCard;
