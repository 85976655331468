import mixpanel from "mixpanel-browser";
import { isProd } from "./utils";
const token = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;

export function initTracker() {
  mixpanel.init(token, {
    debug: isProd ? false : true,
    track_pageview: true,
    persistence: "localStorage",
  });
}

export function identifyTrackableUser(user) {
  const { id, username, avatar, email, google } = user || {};

  if (!id || !username) return;

  mixpanel.identify(id);

  mixpanel.people.set({
    $name: username,
    $avatar: avatar,
    $email: email || google?.email,
  });
}

export function sendTrackingData(type, payload) {
  mixpanel.track(type, payload);
}
