import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useOnboardingStore = create(
  persist(
    (set) => ({
      step: 0,
      setStep: (value) => set(() => ({ step: value })),
      isOnboarded: false,
      setIsOnboarded: (value) => set(() => ({ isOnboarded: value, step: 0 })),
    }),
    {
      name: "onboarding-store",
    }
  )
);
