import PropTypes from "prop-types";
import {
  GlassmorphPlaceholder,
  VenuePlaceholder,
} from "../../../constants/constants";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { IconWithText } from "../../../GlobalStyles";
import {
  VenueImageContainer,
  VenueImage,
  Container,
  Overlay,
  VenuePfp,
  BottomContainer,
  DetailsContainer,
  NameContainer,
} from "./VenueHeader-Styles";

export default function VenueHeader({ venue, events, isLoading }) {
  const { venue_name } = venue || {};

  if (isLoading) {
    return (
      <Container>
        <SkeletonElement
          height="268px"
          style={{ width: "100%", position: "absolute" }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <VenueImageContainer>
        {events?.length > 0 ? (
          events?.slice(0, 3).map((event) => (
            <VenueImage
              key={event.id}
              src={event.image ?? GlassmorphPlaceholder}
              alt={venue_name}
              onError={(e) => (e.target.src = GlassmorphPlaceholder)}
              style={{
                width:
                  events?.length >= 3
                    ? "33%"
                    : events?.length === 2
                      ? "50%"
                      : "100%",
                opacity: 0.3,
                background: GlassmorphPlaceholder,
              }}
            />
          ))
        ) : (
          <VenueImage
            src={GlassmorphPlaceholder}
            alt={venue_name}
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            width={"100%"}
          />
        )}
      </VenueImageContainer>
      <Overlay />
      <DetailsContainer>
        <BottomContainer>
          {/* Render artist name */}
          <IconWithText>
            <VenuePfp
              src={VenuePlaceholder}
              onError={(e) => (e.target.src = VenuePlaceholder)}
            />
            <NameContainer lineHeight="normal">{venue_name}</NameContainer>
          </IconWithText>
        </BottomContainer>
      </DetailsContainer>
    </Container>
  );
}

VenueHeader.propTypes = {
  venue: PropTypes.object,
  events: PropTypes.array,
  isLoading: PropTypes.bool,
};
