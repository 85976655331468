import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { searchAllArtist, searchArtists } from "../../../api/artist";
import { searchEvents } from "../../../api/events";
import { searchPastPerformances } from "../../../api/performances";
import {
  clearSearchHistory,
  getSearchHistory,
  removeOneSearchHistory,
  saveSearchHistory,
  searchFans,
} from "../../../api/profile";
import { getNearestVenue, searchVenues } from "../../../api/venues";
import { RECENT_SEARCH_TYPES, SEARCH_TABS } from "../../../constants/search";
import { useUserStore } from "../../Profile/store/userStore";

const { EVENT } = SEARCH_TABS;
const { TEXT } = RECENT_SEARCH_TYPES;

export const useSearchHistory = ({ loggedInUser, enabled = true }) => {
  return useQuery({
    queryKey: ["searchHistory"],
    queryFn: async () => {
      if (!loggedInUser?.id) throw Error("User not logged in");

      const searchHistory = await getSearchHistory(loggedInUser?.id);

      if (!searchHistory?.success) return [];

      // Remove duplicates based on the value of the query
      const uniqueSearchHistory = searchHistory.data.filter(
        (item, index, self) => {
          if (!item.query.value) return false;

          let value;

          if (item.query.type !== TEXT)
            value = item.query.value.id; // Use ID for uniqueness
          else value = item.query.value; // Use query value for uniqueness

          // Return true if the value is not duplicated
          return (
            index ===
            self.findIndex(
              (t) => t.query.value === value || t.query.value?.id === value
            )
          );
        }
      );

      return uniqueSearchHistory;
    },
    enabled: !!loggedInUser?.id && enabled,
  });
};

export const useSaveSearchHistory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["saveSearchHistory"],
    mutationFn: ({ loggedInUser, type, value }) => {
      if (!loggedInUser?.id) throw Error("User not logged in");

      if (!type || !value) throw Error("Search type or value not provided");

      return saveSearchHistory(loggedInUser?.id, type, value);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["searchHistory"],
        refetchType: "all",
      });
    },
  });
};

export const useClearSearchHistory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["clearSearchHistory"],
    mutationFn: ({ loggedInUser }) => {
      if (!loggedInUser?.id) throw Error("User not logged in");

      return clearSearchHistory(loggedInUser?.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["searchHistory"] });
    },
  });
};

export const useRemoveSearchHistory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["removeSearchHistory"],
    mutationFn: ({ loggedInUser, type, value }) => {
      if (!loggedInUser?.id) throw Error("User not logged in");

      if (!type || !value) throw Error("Query type or value not provided");

      return removeOneSearchHistory(loggedInUser?.id, type, value);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["searchHistory"] });
    },
  });
};

export const performSearch = async ({
  type,
  query,
  country,
  city,
  year,
  month,
  userId,
  artistId,
  venueId,
  signal,
  pageParam,
  limit,
  latitude,
  longitude,
  quickSearch = false,
}) => {
  switch (type) {
    case "event":
      return (
        searchEvents(
          query,
          userId,
          artistId,
          venueId,
          country,
          city,
          year,
          month,
          signal,
          limit,
          pageParam,
          latitude,
          longitude,
          quickSearch
        ) ?? []
      );
    case "artist":
      if (!query) return [];
      return searchArtists(query, userId, signal, limit, pageParam) ?? [];
    case "venue":
      return searchVenues(query, country, city, limit, pageParam) ?? [];
    case "fan":
      if (!query) return [];
      return searchFans(query, userId, signal, limit, pageParam) ?? [];
    default:
      throw new Error("Invalid search type");
  }
};

export const useSearch = ({
  query,
  type,
  country,
  city,
  year,
  month,
  artistId,
  isPast,
  userId,
}) => {
  return useQuery({
    queryKey: [
      "search",
      {
        query,
        type,
        country,
        city,
        year,
        month,
        artistId,
        isPast,
      },
    ],
    queryFn: async ({ signal }) => {
      try {
        if (type === EVENT && isPast) {
          return await searchPastPerformances(
            query,
            artistId,
            year,
            month,
            country,
            city,
            signal
          );
        }

        return performSearch({
          type,
          query,
          country,
          city,
          year,
          month,
          userId,
          artistId,
          signal,
        });
      } catch (err) {
        console.warn(err);
        throw new Error(err);
      }
    },
    enabled:
      !!query ||
      !!type ||
      !!country ||
      !!city ||
      !!year ||
      !!month ||
      !!artistId ||
      !!isPast,
  });
};

export const useInfiniteSearch = ({
  query,
  type,
  country,
  city,
  year,
  month,
  artistId,
  venueId,
  isPast,
  userId,
  limit = 30,
  latitude,
  longitude,
  quickSearch = true,
  isRetro,
}) => {
  return useInfiniteQuery({
    queryKey: [
      "search",
      {
        query,
        type,
        country,
        city,
        year,
        month,
        artistId,
        venueId,
        isPast,
        quickSearch,
      },
    ],
    queryFn: async ({ pageParam = 0, signal }) => {
      if (
        (!query || query === "") &&
        !country &&
        !city &&
        !year &&
        !month &&
        !artistId &&
        !venueId
      )
        return [];
      try {
        return performSearch({
          type,
          query,
          country,
          city,
          year,
          month,
          userId,
          artistId,
          venueId,
          signal,
          pageParam,
          limit,
          latitude,
          longitude,
          quickSearch,
        });
      } catch (err) {
        console.warn(err);
        throw new Error(err);
      }
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage && lastPage?.length < limit) {
        return undefined;
      }
      return (allPages?.length ?? 0) * limit;
    },
    // staleTime: Infinity,
    enabled:
      ((!!query ||
        !!type ||
        !!country ||
        !!city ||
        !!year ||
        !!month ||
        !!artistId ||
        !venueId) &&
        !isPast &&
        !isRetro) ||
      type !== EVENT,
  });
};

export const useSearchAllArtists = ({ query, limit = 50 }) => {
  return useInfiniteQuery({
    queryKey: ["search-artists", query],
    queryFn: async ({ pageParam = 0, signal }) => {
      return await searchAllArtist(query, signal, limit, pageParam);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage && lastPage?.length < limit) {
        return undefined;
      }
      return (allPages?.length ?? 0) * limit;
    },
  });
};

export const useInfiniteRetroSearch = ({
  query,
  artistId,
  year,
  month,
  country,
  city,
  limit = 30,
  isRetro,
  isPast,
  type,
}) => {
  return useInfiniteQuery({
    queryKey: [
      "retro-search",
      { query, artistId, year, month, country, city, limit },
    ],
    queryFn: async ({ pageParam = 0, signal }) => {
      year = typeof pageParam === "number" ? year : pageParam?.year;
      let offset =
        typeof pageParam === "number" ? pageParam : pageParam?.offset;

      const {
        performances,
        hasMore,
        year: currentYear,
      } = await searchPastPerformances(
        query,
        artistId,
        year,
        month,
        country,
        city,
        signal,
        limit,
        offset
      );

      return { performances, hasMore, year: currentYear };
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.hasMore && lastPage.year > 0 && (!year || year <= 2001)) {
        // Decrease year and reset offset when there are no more pages
        return { year: lastPage.year - 1, offset: 0 };
      }

      // Continue fetching the next set of results if more exist
      return lastPage.hasMore
        ? { year: lastPage.year, offset: allPages.length * limit }
        : undefined;
    },
    enabled: (!!isRetro || !!isPast) && type === EVENT,
  });
};

export const useNearestVenue = ({ latitude, longitude }) => {
  const { setVenueCountry, setVenueCity } = useUserStore();
  return useQuery({
    queryKey: ["nearest-venue", latitude, longitude],
    queryFn: async () => {
      const nearestVenue = await getNearestVenue(latitude, longitude);

      if (nearestVenue.data.length > 0) {
        setVenueCountry(nearestVenue?.data[0]?.country ?? null);
        setVenueCity(nearestVenue?.data[0]?.city ?? null);
      }

      return nearestVenue;
    },
    enabled: !!latitude && !!longitude,
  });
};
