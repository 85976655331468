import PropTypes from "prop-types";
import { ButtonRectXs, FooterTextSm } from "../../../GlobalStyles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { Link, useSearchParams } from "react-router-dom";

export default function CollectibleTxn({ txnHash, isLoading }) {
  const [searchParams] = useSearchParams();
  const isAirdrop = searchParams?.get("type") === "airdrop";
  const transactionLink = isAirdrop
    ? "https://sepolia.basescan.org/tx/"
    : "https://basescan.org/tx/";

  if (isLoading) {
    return (
      <SkeletonElement
        height="26px"
        width="110px"
        radius="8px"
        style={{ alignSelf: "center" }}
      />
    );
  }

  if (!txnHash) {
    return null;
  }

  return (
    <ButtonRectXs
      style={{ width: "fit-content", alignSelf: "center" }}
      as={Link}
      to={`${transactionLink}${txnHash}`}
      target="_blank"
    >
      <FooterTextSm weight="600">See transaction</FooterTextSm>
    </ButtonRectXs>
  );
}

CollectibleTxn.propTypes = {
  txnHash: PropTypes.string,
  isLoading: PropTypes.bool,
};
