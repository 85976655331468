import { Container } from "./CollectibleThumbnail-Styles";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import CollectibleThumbnailHeader from "./CollectibleThumbnailHeader";
import CollectibleThumbnailImage from "./CollectibleThumbnailImage";
import CollectibleThumbnailDetails from "./CollectibleThumbnailDetails";

export default function CollectibleThumbnail({ collectible, isExternal }) {
  const {
    id,
    image,
    edition_type = "airdrop",
    user_ids,
    user_id,
    contract_address,
    token_id,
  } = collectible || {};
  const location = useLocation();

  const url = isExternal
    ? `collectibles/${contract_address}/${token_id}`
    : location.pathname?.includes("profile")
      ? `collectibles/${id}?type=${edition_type}`
      : `/profile/${user_id ?? user_ids?.[0]}/collectibles/${id}?type=${edition_type}`;

  return (
    <Container as={Link} to={url}>
      <CollectibleThumbnailHeader
        collectible={collectible}
        isExternal={isExternal}
      />
      <CollectibleThumbnailImage image={image} />
      <CollectibleThumbnailDetails
        collectible={collectible}
        isExternal={isExternal}
      />
    </Container>
  );
}

CollectibleThumbnail.propTypes = {
  collectible: PropTypes.object,
  isExternal: PropTypes.bool,
};
