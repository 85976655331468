import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { BodyText, FooterText, IconWithText } from "../../../GlobalStyles";
import {
  HeaderContainer,
  UserIcon,
} from "../../../routes/Collectible/Collectible-Styles";
import moment from "moment";
import { AvatarPlaceholder } from "../../../constants/constants";
import { theme } from "../../../utils/theme";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function CollectibleOwner({ owner, created_at, isLoading }) {
  const { id, username, avatar } = owner || {};
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <HeaderContainer style={{ margin: "8px 0" }}>
        <IconWithText>
          <SkeletonElement height="17px" width="80px" />
          <SkeletonElement height="20px" width="20px" radius="50%" />
          <SkeletonElement height="17px" width="80px" />
        </IconWithText>
        <SkeletonElement height="17px" width="80px" />
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <IconWithText onClick={() => navigate(`/profile/${id}`)}>
        <BodyText weight="600">Owned by</BodyText>
        <IconWithText gap="8px">
          <UserIcon
            src={avatar || AvatarPlaceholder}
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
          <FooterText>{username}</FooterText>
        </IconWithText>
      </IconWithText>
      <BodyText color={theme.colors.green}>
        {moment(created_at).format("DD MMM YYYY")}
      </BodyText>
    </HeaderContainer>
  );
}

CollectibleOwner.propTypes = {
  owner: PropTypes.object,
  created_at: PropTypes.string,
  isLoading: PropTypes.bool,
};
