import styled from "styled-components";
import { Section } from "../../GlobalStyles";
import { RowFlex } from "../../components/Events/YourEvent/YourEvent-Style";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";
import { theme } from "../../utils/theme";

export const EventSection = styled(Section)`
  font-family: ${theme.fontStyles.suisse};
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

export const EventContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 16px;
`;

export const EventSkeleton = () => {
  return (
    <EventSection>
      <SkeletonElement height="268px" backgroundColor="#ffffff14" />
      <EventContentContainer>
        <SkeletonElement
          height="24px"
          width="180px"
          backgroundColor="#ffffff14"
        />
        <SkeletonElement
          height="14px"
          width="180px"
          backgroundColor="#ffffff14"
          style={{ marginTop: "-8px" }}
        />

        <SkeletonElement
          height="14px"
          width="260px"
          backgroundColor="#ffffff14"
        />
        <RowFlex style={{ justifyContent: "space-between", marginTop: "-8px" }}>
          <SkeletonElement
            height="14px"
            width="220px"
            backgroundColor="#ffffff14"
          />
          <SkeletonElement
            height="14px"
            width="120px"
            backgroundColor="#ffffff14"
          />
        </RowFlex>
        <SkeletonElement
          height="14px"
          width="120px"
          backgroundColor="#ffffff14"
        />
        <SkeletonElement
          height="100px"
          width="100%"
          backgroundColor="#ffffff14"
          style={{ marginTop: "-8px" }}
        />

        <SkeletonElement
          height="14px"
          width="120px"
          backgroundColor="#ffffff14"
        />
        <SkeletonElement
          height="50px"
          width="100%"
          backgroundColor="#ffffff14"
          style={{ marginTop: "-8px" }}
        />
      </EventContentContainer>
    </EventSection>
  );
};
