import { useQuery } from "@tanstack/react-query";
import { checkUserTickets } from "../../../api/ticketing";

export const useTicketsStatusQuery = ({ userId, eventId, artistId }) => {
  return useQuery({
    queryKey: ["tickets-status", { userId, eventId, artistId }],
    queryFn: async () => {
      return await checkUserTickets({ userId, eventId, artistId });
    },
    enabled: !!userId && !!eventId && !!artistId,
  });
};
