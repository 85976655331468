import moment from "moment-timezone";

export const getOneVenue = async (venueId, venueName, userId) => {
  const localTime = moment().local().format("YYYY-MM-DD HH:mm:ss");
  const userTimezone = moment.tz.guess();
  const userTime = moment
    .tz(localTime, "YYYY-MM-DD HH:mm:ss", userTimezone)
    .format("YYYY-MM-DD HH:mm:ss");

  let response = await fetch(
    `${import.meta.env.VITE_API_URL}/venues/${venueId}/${venueName}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        date_time_param: userTime,
      }),
    }
  );

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch venue data" };
  }

  return { success: true, data: payload.data };
};

export const searchVenues = async (
  query,
  country,
  city,
  limit = 20,
  offset = 0
) => {
  try {
    // Fetch venues
    let venues = await fetch(
      `${import.meta.env.VITE_API_URL}/venues/search?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          country,
          city,
        }),
      }
    );
    // Parse response
    venues = await venues.json();

    if (!venues.success) {
      console.error(venues.error);
      return { success: false, error: "Failed to fetch venues data", data: [] };
    }

    // Return venues
    return venues.data;
  } catch (err) {
    console.error(err);
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};

export const getNearestVenue = async (latitude, longitude) => {
  let response = await fetch(`${import.meta.env.VITE_API_URL}/nearest-venue`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      latitude,
      longitude,
    }),
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch venue data" };
  }

  return { success: true, data: payload.data };
};

export const getNearbyVenues = async (country_param = null) => {
  let response = await fetch(
    `${import.meta.env.VITE_API_URL}/venues/nearby-venues`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_param,
      }),
    }
  );

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch venue data" };
  }

  return { success: true, data: payload.data };
};

export const getVenueMoments = async ({ venueId, userId }) => {
  try {
    // Fetch moments
    let moments = await fetch(
      `${import.meta.env.VITE_API_URL}/venues/${venueId}/moments?userId=${userId}`
    );

    if (!moments.ok) {
      return { success: false, error: "Failed to fetch moments" };
    }

    // Parse response
    moments = await moments.json();

    if (!moments.success) {
      console.error(moments?.error);
      return { success: false, error: "Failed to fetch moments" };
    }

    // Return moments
    return moments;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    console.error(err);
    return { success: false, error: "Failed to fetch moments" };
  }
};

export const getVenueCollectibles = async ({ venueId }) => {
  try {
    // Fetch collectibles
    let collectibles = await fetch(
      `${import.meta.env.VITE_API_URL}/venues/${venueId}/collectibles`
    );

    if (!collectibles.ok) {
      return { success: false, error: "Failed to fetch collectibles" };
    }

    // Parse response
    collectibles = await collectibles.json();

    if (!collectibles.success) {
      console.error(collectibles?.error);
      return { success: false, error: "Failed to fetch collectibles" };
    }

    // Return collectibles
    return collectibles;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    console.error(err);
    return { success: false, error: "Failed to fetch collectibles" };
  }
};
