import styled from "styled-components";
import { Title } from "../../../GlobalStyles";

export const HeaderContainer = styled.div`
  width: 100%;
  min-height: 268px;
  max-height: 268px;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px 20px;
`;

export const EventImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(12, 13, 23, 0.3) 0%,
    rgba(12, 13, 23, 0) 50%,
    rgba(12, 13, 23, 0.5) 100%
  );
  z-index: 2;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  gap: 16px;
`;
export const BottomContainer = styled(TopContainer)`
  align-items: flex-end;
`;

export const NameContainer = styled(Title)`
  display: inline-block;
  max-width: ${({ width }) => width || `calc(100vw - 100px)`};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RoomChatButtonContainer = styled(ButtonContainer)`
  top: 16px;
  right: 20px;
  gap: 2px;
`;

export const InterestedButtonContainer = styled(ButtonContainer)`
  bottom: 16px;
  right: 20px;
  text-align: center;
  gap: 2px;
`;
