import PropTypes from "prop-types";
import {
  GlassmorphPlaceholder,
  MOMENT_TYPES,
} from "../../../constants/constants";
import {
  Border,
  Container,
  MomentImage,
  MomentVideo,
  OnchainLogo,
  RetroLogo,
  UploadButton,
  VideoLength,
} from "./MomentThumbnailCard-Styles";
import useMomentPhotoUpload from "../../../hooks/useMomentPhotoUpload";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import MomentMediaDropdown from "../../Dropdown/MomentMediaDropdown";
import { FooterText } from "../../../GlobalStyles";
import { useRef, useState } from "react";
import { formatVideoLength } from "../../../utils/utils";

/**
 * Renders a thumbnail card for a given moment.
 *
 * @param {Object} moment - the moment object
 * @param {string} type - the type of the card
 * @return {React.JSX.Element} the rendered thumbnail card component
 */
export default function MomentThumbnailCard({
  moment,
  type = "past",
  size,
  inModal,
}) {
  const {
    fan_photo_urls,
    transaction_reference,
    type: momentType,
    user_id,
  } = moment;
  const hasFanPhoto = fan_photo_urls?.length > 0;
  const momentAsset = hasFanPhoto ? fan_photo_urls[0] : GlassmorphPlaceholder;
  const isVideo = fan_photo_urls?.some((photo) => photo.includes("mp4"));
  const [duration, setDuration] = useState(null);

  const { setMoment } = useMomentPhotoUpload();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const isOwnProfile = user_id === loggedInUser?.id;
  const video = useRef(null);

  const handleNavigate = () => {
    if (inModal) return;

    navigate(`moments/${moment?.moment_id}`);
  };
  const handleButtonClick = () => {
    setMoment(moment);
  };

  const handleLoadedMetadata = () => {
    const videoDuration = video.current.duration;
    setDuration(formatVideoLength(videoDuration));
  };

  if (type === "active") {
    return (
      <Border type={type} size={size}>
        {isOwnProfile && !inModal && (
          <UploadButton onClick={handleButtonClick}>
            <MomentMediaDropdown
              isVideo={isVideo}
              type={hasFanPhoto ? "edit" : "add"}
            />
          </UploadButton>
        )}
        <Container onClick={handleNavigate}>
          {momentAsset && momentAsset.includes(".mp4") ? (
            <MomentImage
              as="video"
              src={`${momentAsset}#t=0.01`}
              muted
              preload="metadata"
              style={{
                pointerEvents: "none",
              }}
            />
          ) : hasFanPhoto ? (
            <MomentImage src={fan_photo_urls[0]} alt="Moment" />
          ) : (
            <></>
          )}
          {momentType === MOMENT_TYPES.RETRO ? (
            <RetroLogo />
          ) : momentType === MOMENT_TYPES.ONCHAIN || transaction_reference ? (
            <OnchainLogo />
          ) : (
            <></>
          )}
        </Container>
      </Border>
    );
  }

  return (
    <Border type={type}>
      <Container darken={hasFanPhoto}>
        {momentAsset && momentAsset.includes(".mp4") ? (
          <MomentVideo
            src={`${momentAsset}#t=0.01`}
            muted
            preload="metadata"
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            onLoadedMetadata={handleLoadedMetadata}
            style={{
              pointerEvents: "none",
            }}
            ref={video}
          />
        ) : hasFanPhoto ? (
          <MomentImage
            src={momentAsset}
            alt="Moment"
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          />
        ) : (
          <></>
        )}
        {momentType === MOMENT_TYPES.RETRO ? (
          <RetroLogo />
        ) : momentType === MOMENT_TYPES.ONCHAIN || transaction_reference ? (
          <OnchainLogo />
        ) : (
          <></>
        )}
        {duration && (
          <VideoLength>
            <FooterText>{duration}</FooterText>
          </VideoLength>
        )}
      </Container>
    </Border>
  );
}

MomentThumbnailCard.propTypes = {
  moment: PropTypes.object.isRequired,
  type: PropTypes.string,
  noImage: PropTypes.bool,
  size: PropTypes.string,
  inModal: PropTypes.bool,
};
