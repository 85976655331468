import styled from "styled-components";
import { BACKGROUND_IMAGES } from "../../constants/constants";
import { Section } from "../../GlobalStyles";

export const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  position: relative;
  background: url(${BACKGROUND_IMAGES.ERROR});
  background-size: cover;
  background-position: center;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 30px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 8px;
  margin: 40px 0 24px 0;
`;
