import { useQuery } from "@tanstack/react-query";
import { performanceMomentsLoader } from "../loader";
import { getPerformanceCollectibles } from "../../../api/performances";

export const usePerformanceQuery = ({ id, date }) => {
  return useQuery({
    queryKey: ["performance", id],
    queryFn: async () => {
      return await performanceMomentsLoader(id, date);
    },
  });
};

export const usePerformanceCollectiblesQuery = ({ performanceId }) => {
  return useQuery({
    queryKey: [
      "performance-collectibles",
      {
        performanceId,
      },
    ],
    queryFn: async () => {
      const collectibles = await getPerformanceCollectibles({ performanceId });

      if (!collectibles?.success) return [];

      return collectibles?.data || [];
    },
  });
};
