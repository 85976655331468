import {
  ChainIconContainer,
  Image,
  ImageContainer,
} from "../../../routes/Collectible/Collectible-Styles";
import PropTypes from "prop-types";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import BaseIcon from "../../../assets/svg/base.svg?react";

export default function CollectibleImage({ image, chain, isLoading }) {
  if (isLoading) {
    return <SkeletonElement height="350px" width="100%" radius="8px" />;
  }

  return (
    <ImageContainer>
      {image && (
        <Image
          src={image}
          alt="collectible"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
      )}
      {chain && (
        <ChainIconContainer>
          <BaseIcon />
        </ChainIconContainer>
      )}
    </ImageContainer>
  );
}

CollectibleImage.propTypes = {
  image: PropTypes.string,
  chain: PropTypes.string,
  isLoading: PropTypes.bool,
};
