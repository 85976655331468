import { create } from "zustand";

export const useUserStore = create((set) => ({
  userParticipated: [],
  setUserParticipated: (value) => set(() => ({ userParticipated: value })),
  addUserParticipated: (value) =>
    set((state) => ({
      userParticipated: [...state.userParticipated, value],
    })),
  removeUserParticipated: (value) =>
    set((state) => ({
      userParticipated: state.userParticipated.filter((u) => u !== value),
    })),

  userFollowedArtists: [],
  setUserFollowedArtists: (value) =>
    set(() => ({ userFollowedArtists: value })),
  addUserFollowedArtist: (value) =>
    set((state) => ({
      userFollowedArtists: [...state.userFollowedArtists, value],
    })),
  removeUserFollowedArtist: (value) =>
    set((state) => ({
      userFollowedArtists: state.userFollowedArtists.filter(
        (u) => u.id !== (value.id ?? value.artist_id)
      ),
    })),

  userFollowedUsers: [],
  setUserFollowedUsers: (value) => set(() => ({ userFollowedUsers: value })),
  addUserFollowedUsers: (value) =>
    set((state) => ({
      userFollowedUsers: [...state.userFollowedUsers, value],
    })),
  removeUserFollowedUsers: (value) =>
    set((state) => ({
      userFollowedUsers: state.userFollowedUsers.filter(
        (u) => u.id !== (value.id ?? value.artist_id)
      ),
    })),

  userSubscriptions: [],
  setUserSubscriptions: (value) => set(() => ({ userSubscriptions: value })),
  addUserSubscriptions: (value) =>
    set((state) => ({
      userSubscriptions: [...state.userSubscriptions, value],
    })),
  removeUserSubscriptions: (value) =>
    set((state) => ({
      userSubscriptions: state.userSubscriptions.filter(
        (u) => u.id !== (value.id ?? value.artist_id)
      ),
    })),

  userCountry: "",
  userCity: "",
  setUserCountry: (value) => set(() => ({ userCountry: value })),
  setUserCity: (value) => set(() => ({ userCity: value })),

  venueCountry: null,
  venueCity: null,
  setVenueCountry: (value) => set(() => ({ venueCountry: value })),
  setVenueCity: (value) => set(() => ({ venueCity: value })),

  externalMints: [],
  setExternalMints: (value) => set(() => ({ externalMints: value })),

  fidMints: [],
  setFidMints: (value) => set(() => ({ fidMints: value })),

  unrewardedGuessXP: [],
  setUnrewardedGuessXP: (value) => set(() => ({ unrewardedGuessXP: value })),
}));
