import isPropValid from "@emotion/is-prop-valid";
import { useOAuthTokens, usePrivy } from "@privy-io/react-auth";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyleSheetManager, styled } from "styled-components";
import { GlobalStyles } from "../../GlobalStyles";

import { registerSW } from "virtual:pwa-register";
import LoadingScreen from "../../components/LoadingScreen";
import { LoadingScreenV2 } from "../../components/LoadingScreenV2";
import CenteredGlobalModal from "../../components/Modal/CenterModal";
import SheetModal from "../../components/Modal/SheetModal";
import Navbar from "../../components/Navbar";
import { useAllLocationsQuery } from "../../components/Settings/hooks/locationQueries";
import {
  NotificationPermissionState,
  PermissionState,
} from "../../enums/permission";
import { useMomentsFeed } from "../../hooks/momentQueries";
import useAuth from "../../hooks/useAuth";
import useGlobalModal from "../../hooks/useGlobalModal";
import useNotification from "../../hooks/useNotification";
import {
  isCinnyAuthenticated,
  isCinnySupported,
} from "../../utils/cinny-utils";
import { identifyUser, initFullStory } from "../../utils/fs-utils";
import { theme } from "../../utils/theme";
import {
  useExternalMints,
  useExternalNFTs,
  useUnrewardedGuessXP,
  useUserMessages,
  useUserMoments,
  useUserNotifications,
  useUserParticipated,
  useUserStats,
} from "../Profile/hooks/profileQueries";
import { useSearchHistory } from "../Search/hooks/searchQueries";
import { ROUTES_WITH_NO_NAVBAR } from "./constants";
import { useUserStore } from "../Profile/store/userStore";
import { useImageEditorStore } from "../../components/ImageEditor/imageEditorStore";
import {
  useFollowing,
  useSubscription,
} from "../Following/hooks/followingQueries";
import useGeolocation from "../../hooks/useGeolocation";
import ImageEditor from "../../components/ImageEditor";
import { useVideoEditorStore } from "../../components/VideoEditor/videoEditorStore";
import VideoEditor from "../../components/VideoEditor";
import useGeolocationPermission from "../Events/hooks/geoLocationHooks";
import { useLogLocation } from "./hooks/locationHooks";
import { useNavigate } from "react-router-dom";
import useMarketplace from "../../hooks/useMarketplace";
import { isProd } from "../../utils/utils";
import { useScrollStore } from "../../store/scrollStore";
import { useRestriction } from "../../hooks/useRestriction";
import { useDisclosure } from "@mantine/hooks";
import { CodeRestrictionModal } from "../../components/CodeRestrictionModal";
import { useWertCheckout } from "../../components/Checkout/hooks/useWertCheckout";
import { useLinkedWallets } from "../../hooks/useLinkedWallets";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background: ${theme.colors.backgroundOverlay};
  background-color: rgba(17, 17, 31, 1);
  background-blend-mode: multiply;
`;

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 3000;

const retry = async (fn, maxRetries, delay) => {
  let attempts = 0;
  while (attempts < maxRetries) {
    try {
      return await fn();
    } catch (error) {
      attempts += 1;
      if (attempts >= maxRetries) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

export default function Root() {
  const navigation = useNavigation();
  const location = useLocation();
  const { ready, user } = usePrivy();
  const navigate = useNavigate();
  const { toggleMarketplace, isOpen } = useMarketplace();
  const { fetchCurrentUser, setLoggedInUser, loggedInUser, updateCurrentUser } =
    useAuth();
  const { isCenterModalOpen } = useGlobalModal();
  const { setScrollPosition } = useScrollStore();
  const { getBrowserNotifState, askNotificationPermission } = useNotification();
  const { isOpen: isImageEditorOpen } = useImageEditorStore();
  const { isOpen: isVideoEditorOpen } = useVideoEditorStore();

  const [initializationComplete, setInitializationComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [swError, setSwError] = useState(null);

  const { setCountry, setCity, getCurrentPosition, currentLocation } =
    useGeolocation();
  const permState = useGeolocationPermission();

  const [opened, { open, close }] = useDisclosure(false);
  const { phoneNumber } = useRestriction(opened, open, close);

  const { setUserSpotifyAccessToken, setUserCity, setUserCountry } =
    useUserStore();
  let updateIntervalId;

  const logLocationMutation = useLogLocation({
    user_id: loggedInUser?.id,
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    accuracy: currentLocation?.coords?.accuracy,
  });

  // Get current location every 10 seconds if permission is granted
  useEffect(() => {
    if (permState === PermissionState.GRANTED) {
      const getPositionIntervalId = setInterval(
        () => getCurrentPosition(),
        1000
      ); // 10 seconds

      const logLocationIntervalId = setInterval(() => {
        if (loggedInUser) logLocationMutation.mutate();
      }, 12000); // 3 minutes

      return () => {
        clearInterval(getPositionIntervalId);
        clearInterval(logLocationIntervalId);
      };
    }
  }, [permState, loggedInUser]);

  useEffect(() => {
    if (!ready) return;

    fetchCurrentUser()
      .then(async (user) => {
        initFullStory();

        if (!user) {
          setInitializationComplete(true);
          return;
        }

        identifyUser(user);

        setLoggedInUser(user);

        // Set logged in user id in local storage
        localStorage.setItem("loggedInUserID", JSON.stringify(user.id));

        if (isCinnySupported() && !isCinnyAuthenticated()) {
          import("../../utils/federated-cinny-utils").then(async (module) => {
            await module.initMatrix(user);
          });
        }

        const notifState = await getBrowserNotifState();
        if (notifState == NotificationPermissionState.GRANTED) {
          askNotificationPermission();
        }

        const storedCountryName = localStorage.getItem("countryName");
        const userStoredCountry = user?.last_country;

        const storedCityName = localStorage.getItem("cityName");
        const userStoredCity = user?.last_city;

        setUserCity(storedCityName ?? userStoredCity ?? null);
        setUserCountry(storedCountryName ?? userStoredCountry ?? null);
        setCountry(storedCountryName ?? userStoredCountry ?? null);
        setCity(storedCityName ?? userStoredCity ?? null);
        if (loggedInUser)
          updateCurrentUser({
            last_country: storedCountryName ?? userStoredCountry ?? null,
            last_city: storedCityName ?? userStoredCity ?? null,
          });

        setInitializationComplete(true);
      })
      .catch((err) => {
        setInitializationComplete(true);
        console.error(err);
        identifyUser();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  useEffect(() => {
    if (isOpen && !isProd) {
      toggleMarketplace();
    }
  }, [navigate, window.location.pathname]);

  // Reset the scroll position when you visit a new page
  useEffect(() => {
    const root = document.getElementById("root");

    root.scrollTop = 0;

    setScrollPosition(0);
  }, [location.pathname]);

  // Always call the hooks
  const momentsFeedQuery = useMomentsFeed({
    userId: loggedInUser?.id,
    enabled: initializationComplete,
  });

  const searchHistoryQuery = useSearchHistory({
    loggedInUser,
    enabled: initializationComplete,
  });

  const userStatsQuery = useUserStats({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: initializationComplete && !!loggedInUser,
  });

  const userNotificationsQuery = useUserNotifications({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: initializationComplete && !!loggedInUser,
  });

  const userMessagesQuery = useUserMessages({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: initializationComplete && !!loggedInUser,
  });

  const userParticipatedQuery = useUserParticipated({
    loggedInUserId: loggedInUser?.id,
  });

  const userFollowingQuery = useFollowing({
    userId: loggedInUser?.id,
    enabled: true,
  });

  const userSubscriptionQuery = useSubscription({
    userId: loggedInUser?.id,
    enabled: true,
  });

  useUserMoments({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: true,
  });

  const { linkedWallets } = useLinkedWallets();
  const externalNFTsQuery = useExternalNFTs();

  useExternalMints({
    externalNFTs: externalNFTsQuery?.data,
    enabled: !externalNFTsQuery.isLoading && !!linkedWallets && !!loggedInUser,
    wallets: linkedWallets,
    userId: loggedInUser?.id,
  });

  useUnrewardedGuessXP({
    fid: loggedInUser?.farcaster_id,
  });

  useAllLocationsQuery();

  useWertCheckout();

  // const ipInfoQuery = useIpInfo();

  const isProfileLoading =
    userStatsQuery.isLoading ||
    userNotificationsQuery.isLoading ||
    userMessagesQuery.isLoading ||
    searchHistoryQuery.isLoading ||
    momentsFeedQuery.isLoading ||
    userParticipatedQuery.isLoading ||
    userFollowingQuery.isLoading ||
    userSubscriptionQuery.isLoading;

  // Last test
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        // Listen for updatefound event on the registration
        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;

          if (newWorker) {
            // Listen for state changes on the new service worker
            newWorker.addEventListener("statechange", () => {
              console.log(`New service worker state: ${newWorker.state}`);
              if (newWorker.state === "installing") {
                setLoading(true);
                console.log("Service Worker is installing.");
              } else if (newWorker.state === "installed") {
                setLoading(false);
                console.log("Service Worker installed.");
              } else if (newWorker.state === "activating") {
                setLoading(true);
                console.log("Service Worker is activating.");
              } else if (newWorker.state === "activated") {
                setLoading(false);
                console.log("Service Worker activated.");
              } else if (newWorker.state === "redundant") {
                setLoading(false);
                console.log("Service Worker is redundant.");
              }
            });
          }
        });
      });
    }
  }, []);

  // Register Service Worker with retry logic
  useEffect(() => {
    const handleUpdate = async (registration) => {
      console.log("Service worker update found:", registration);
      try {
        setLoading(true);
        await retry(() => registration.update(), MAX_RETRIES, RETRY_DELAY_MS);

        window.location.reload();
      } catch (error) {
        console.error("Service worker update failed:", error);
        toast.error("Service worker update failed. Please try again later.");
      }
    };

    const handleRegister = async () => {
      try {
        const swRegistration = await retry(
          () =>
            registerSW({
              onNeedRefresh() {
                console.log("New content available, refreshing...");
                setLoading(true);
                window.location.reload();
              },
              onOfflineReady() {
                console.log("App is ready to work offline.");
              },
              immediate: true,
              onRegistered(r) {
                if (r) {
                  // Check for updates every minute
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                  updateIntervalId = setInterval(
                    () => {
                      r.update();
                    },
                    10 * 60 * 1000
                  );

                  const handleVisibilityChange = () => {
                    if (
                      document.visibilityState === "visible" ||
                      !document.hidden
                    ) {
                      r.update();
                    }
                  };

                  document.addEventListener(
                    "visibilitychange",
                    handleVisibilityChange
                  );

                  return () => {
                    document.removeEventListener(
                      "visibilitychange",
                      handleVisibilityChange
                    );
                  };
                }
              },
              onRegisterError(error) {
                console.error("Service worker registration error:", error);
                toast.error(
                  "Service worker registration failed. Please try again later."
                );
                setSwError(error);
              },
              onUpdate: handleUpdate,
            }),
          MAX_RETRIES,
          RETRY_DELAY_MS
        );

        return swRegistration;
      } catch (error) {
        console.error(
          "Service worker registration failed after retries:",
          error
        );
        toast.error(
          "Service worker registration failed after multiple attempts. Please try again later."
        );
        setSwError(error);
      }
    };

    handleRegister();

    return () => {
      if (updateIntervalId) {
        clearInterval(updateIntervalId);
      }
      document.removeEventListener("visibilitychange", handleRegister);
    };
  }, []);

  useOAuthTokens({
    onOAuthTokenGrant: async (tokens) => {
      console.log(tokens);
      setUserSpotifyAccessToken(tokens.accessToken);
    },
  });

  return (
    <>
      {opened && (
        <CodeRestrictionModal
          opened={opened}
          open={open}
          close={close}
          phoneNumber={phoneNumber}
        />
      )}
      <StyleSheetManager shouldForwardProp={isPropValid}>
        <GlobalStyles />
        {!ready ||
        isProfileLoading ||
        !initializationComplete ||
        loading ||
        swError ? (
          <LoadingScreenV2 fullScreen loading={loading} />
        ) : (
          <>
            <Container>
              {navigation.state === "loading" ? <LoadingScreen /> : <Outlet />}
              <ToastContainer
                theme="dark"
                position={toast.POSITION.BOTTOM_CENTER}
                limit={1}
              />
              {isCenterModalOpen && <CenteredGlobalModal />}
              <SheetModal />

              {isImageEditorOpen && <ImageEditor />}
              {isVideoEditorOpen && <VideoEditor />}
              {!ROUTES_WITH_NO_NAVBAR.includes(location.pathname) && <Navbar />}
            </Container>
          </>
        )}
      </StyleSheetManager>
    </>
  );
}
