import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subheader } from "../../GlobalStyles";
import ScrollableMoments from "../../components/Moments/ScrollableMoments";
import { useEventsFeed, useMomentsFeed } from "../../hooks/momentQueries";
import useAuth from "../../hooks/useAuth";
import useGlobalModal from "../../hooks/useGlobalModal";
import ErrorPage from "../Error";
import { EmptyEvents } from "./EmptyEvents";
import {
  MomentsFeedContainer,
  NavContainer,
  NavLine,
  NavOption,
  NavOptionsContainer,
} from "./MomentsFeed-Styles";
import { Overlay } from "../../components/Moments/Moment-Styles";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

/**
 * A React component for rendering a moments feed with navigation tabs for "For You" and "Your events".
 *
 * @return {Promise} The events feed data if successful, or false if there's an error.
 */
export default function MomentsFeed() {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { openSheetPWAModal } = useGlobalModal();
  const [isFYP, setIsFYP] = useState(true);
  const momentsFeedRef = useRef(null);

  const momentsFeedQuery = useMomentsFeed({ userId: loggedInUser?.id });
  const eventsFeedQuery = useEventsFeed({ userId: loggedInUser?.id });
  const listRef = useRef(null);
  const { handleTracking } = useMixpanelTracker();

  // Open global modal after loading
  useEffect(() => {
    handleTracking("Visited Moments Feed");
    openSheetPWAModal();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFYPClick = () => {
    if (isFYP) {
      listRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else setIsFYP(true);
  };

  const handleEventsClick = useCallback(() => {
    if (!isFYP) {
      listRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      if (!loggedInUser) return navigate("/login");

      setIsFYP(false);
    }
  }, [eventsFeedQuery]);

  const NavComponent = useMemo(() => {
    return (
      <NavContainer>
        <NavOptionsContainer>
          <NavOption onClick={handleFYPClick}>
            <Subheader
              weight={isFYP && 600}
              style={{
                opacity: isFYP ? 1 : 0.6,
              }}
            >
              For you
            </Subheader>
            {isFYP && <NavLine />}
          </NavOption>
          <NavOption onClick={handleEventsClick}>
            <Subheader
              weight={!isFYP && 600}
              style={{
                opacity: !isFYP ? 1 : 0.6,
              }}
            >
              Your events
            </Subheader>
            {!isFYP && <NavLine />}
          </NavOption>
        </NavOptionsContainer>
      </NavContainer>
    );
  }, [isFYP, handleFYPClick, handleEventsClick]);

  // Move this to component
  // if (momentsFeedQuery?.isLoading) return <LoadingScreen />;
  const allUnseenMoments = useMemo(
    () =>
      momentsFeedQuery?.data?.pages.flatMap((page) => page.unseenMoments || []),
    [momentsFeedQuery?.data?.pages]
  );
  const allSeenMoments = useMemo(
    () =>
      momentsFeedQuery?.data?.pages.flatMap((page) => page.seenMoments || []),
    [momentsFeedQuery?.data?.pages]
  );

  useEffect(() => {
    if (
      allUnseenMoments.length <= 10 &&
      !momentsFeedQuery.isFetchingNextPage &&
      momentsFeedQuery.hasNextPage
    )
      momentsFeedQuery?.fetchNextPage();
  }, [allUnseenMoments, momentsFeedQuery]);

  if (momentsFeedQuery?.error || eventsFeedQuery?.error) return <ErrorPage />;

  // Render moments feed component
  return (
    <MomentsFeedContainer ref={momentsFeedRef}>
      {NavComponent}
      {isFYP && (
        <ScrollableMoments
          moments={allUnseenMoments}
          seenMoments={allSeenMoments}
          isMomentsFeed
          isLikesFetched
          isLoading={
            momentsFeedQuery?.isLoading || momentsFeedQuery?.isFetching
          }
          isFYP={true}
          listRef={listRef}
          fetchNextPage={momentsFeedQuery?.fetchNextPage}
          hasNextPage={momentsFeedQuery?.hasNextPage}
          isFetchingNextPage={momentsFeedQuery?.isFetchingNextPage}
        />
      )}
      {!isFYP &&
        (eventsFeedQuery?.data?.pages?.flat().length > 0 ||
        eventsFeedQuery?.isLoading ? (
          <ScrollableMoments
            moments={eventsFeedQuery?.data?.pages?.flat()}
            isMomentsFeed
            isLoading={
              eventsFeedQuery?.isLoading || eventsFeedQuery?.isFetching
            }
            isFYP={false}
            listRef={listRef}
            fetchNextPage={eventsFeedQuery?.fetchNextPage}
            hasNextPage={eventsFeedQuery?.hasNextPage}
            isFetchingNextPage={eventsFeedQuery?.isFetchingNextPage}
          />
        ) : (
          <EmptyEvents />
        ))}
      <Overlay />
    </MomentsFeedContainer>
  );
}
