import { FiltersContainer } from "./UserTickets-Styles";
import { USER_TICKETS_FILTERS } from "../../../constants/constants";
import UserTicketsFilter from "./UserTicketFilter";

const { ALL, UPCOMING, PAST } = USER_TICKETS_FILTERS;

export default function UserTicketsFilters() {
  return (
    <FiltersContainer>
      <UserTicketsFilter filter={ALL} />
      <UserTicketsFilter filter={UPCOMING} />
      <UserTicketsFilter filter={PAST} />
    </FiltersContainer>
  );
}
