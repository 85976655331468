import moment from "moment-timezone";

export const getOneArtist = async (artistId, userId) => {
  const localTime = moment().local().format("YYYY-MM-DD HH:mm:ss");
  const userTimezone = moment.tz.guess();
  const userTime = moment
    .tz(localTime, "YYYY-MM-DD HH:mm:ss", userTimezone)
    .format("YYYY-MM-DD HH:mm:ss");

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/artists/${artistId}?userId=${userId}&date_time_param=${userTime}`
  ).catch((err) => {
    if (err !== "AbortError") {
      console.error(err);
    }
    return { success: false, error: "Failed to fetch artist data" };
  });
  const payload = await response.json();
  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { data: payload.data };
};

export const getIfUserSubscribedToArtist = async (userId, artistId, signal) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/${userId}/artist/subscriptions/${artistId}`,
    {
      signal,
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to unsubscribe from artist" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const getIfUserFollowsArtist = async (userId, artistId, signal) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/${userId}/artist/follows/${artistId}`,
    {
      signal,
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to follow artist" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const searchArtists = async (
  query,
  userId,
  signal,
  limit = 20,
  offset = 0
) => {
  try {
    // Fetch artists
    let artists = await fetch(
      `${import.meta.env.VITE_API_URL}/artists/search?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          userId,
        }),
        signal,
      }
    );

    // Parse response
    artists = await artists.json();
    if (!artists.success) {
      console.error(artists.error);
      return { success: false, error: "Failed to fetch artist data", data: [] };
    }

    // Return artists
    return artists.data;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};

export const searchAllArtist = async (
  query,
  signal,
  limit = 20,
  offset = 0
) => {
  try {
    // Fetch artists
    let artists = await fetch(
      `${import.meta.env.VITE_API_URL}/artists/searchAll?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
        }),
        signal,
      }
    );

    // Parse response
    artists = await artists.json();
    if (!artists.success) {
      console.error(artists.error);
      return { success: false, error: "Failed to fetch artist data", data: [] };
    }

    // Return artists
    return artists.data;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};

export const getArtistEvents = async (artistId, signal) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/artists/${artistId}/events`,
    {
      signal,
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to fetch artist data" };
  });

  if (!response.ok) {
    return { success: false, error: "Failed to fetch artist data" };
  }

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const fetchNearbyArtists = async (
  latitude,
  longitude,
  country,
  userId
) => {
  try {
    // Fetch nearby artists
    let events = await fetch(`${import.meta.env.VITE_API_URL}/artist/nearby`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latitude,
        longitude,
        country,
        userid: userId,
      }),
    });

    // Parse response
    events = await events.json();
    return events;
  } catch (err) {
    throw new Error(err);
  }
};

export const createAndGetArtists = async (artists) => {
  try {
    // Fetch artists
    let ids = await fetch(
      `${import.meta.env.VITE_API_URL}/artists/get-and-create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          artists,
        }),
      }
    );

    // Parse response
    ids = await ids.json();
    if (!ids.success) {
      console.error(ids.error);
      return { success: false, error: "Failed to fetch artist ids", data: [] };
    }

    // Return artists
    return ids.data;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};

export const getArtistMoments = async ({ artistId, userId }) => {
  try {
    // Fetch moments
    let moments = await fetch(
      `${import.meta.env.VITE_API_URL}/artists/${artistId}/moments?userId=${userId}`
    );

    if (!moments.ok) {
      return { success: false, error: "Failed to fetch moments" };
    }

    // Parse response
    moments = await moments.json();

    if (!moments.success) {
      console.error(moments?.error);
      return { success: false, error: "Failed to fetch moments" };
    }

    // Return moments
    return moments;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    console.error(err);
    return { success: false, error: "Failed to fetch moments" };
  }
};

export const getArtistCollectibles = async ({ artistId }) => {
  try {
    // Fetch collectibles
    let collectibles = await fetch(
      `${import.meta.env.VITE_API_URL}/artists/${artistId}/collectibles`
    );

    if (!collectibles.ok) {
      return { success: false, error: "Failed to fetch collectibles" };
    }

    // Parse response
    collectibles = await collectibles.json();

    if (!collectibles.success) {
      console.error(collectibles?.error);
      return { success: false, error: "Failed to fetch collectibles" };
    }

    // Return collectibles
    return collectibles;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    console.error(err);
    return { success: false, error: "Failed to fetch collectibles" };
  }
};
