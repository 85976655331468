import Tabs from "../../Tabs";
import ArrowLineUp from "../../../assets/svg/arrow-line-up.svg?react";
import ArrowLineDown from "../../../assets/svg/arrow-line-down.svg?react";
import WalletsTab from "../../Tabs/WalletsTab";
import PropTypes from "prop-types";

export default function WalletsTabs({ isLoading }) {
  const tabs = [
    {
      id: "pay",
      icon: <ArrowLineUp height={16} stroke="white" />,
      label: "Pay",
      component: <WalletsTab isLoading={isLoading} />,
    },
    {
      id: "receive",
      icon: <ArrowLineDown height={16} stroke="white" />,
      label: "Receive",
      component: <WalletsTab isLoading={isLoading} />,
    },
  ];

  return <Tabs tabs={tabs} type="wallets" />;
}

WalletsTabs.propTypes = {
  isLoading: PropTypes.bool,
};
