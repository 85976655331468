import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { HeaderContainer } from "./CollectibleThumbnail-Styles";
import PosterIcon from "../../../assets/svg/poster-icon.svg?react";
import PhotoIcon from "../../../assets/svg/photo.svg?react";
import AirdropIcon from "../../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";

const { colors } = theme;

export default function CollectibleThumbnailHeader({
  collectible,
  isExternal,
}) {
  const { id, type, edition_type, token_id } = collectible || {};

  return (
    <HeaderContainer>
      <FooterText weight="600">#{isExternal ? token_id : id}</FooterText>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {type === "Poster" || isExternal ? (
          <PosterIcon stroke={colors.grey} />
        ) : (
          <PhotoIcon stroke={colors.grey} height={14} width={14} />
        )}
        {edition_type === "airdrop" && !isExternal ? (
          <AirdropIcon stroke={colors.grey} />
        ) : (
          <EditionIcon stroke={colors.grey} height={14} width={14} />
        )}
      </div>
    </HeaderContainer>
  );
}

CollectibleThumbnailHeader.propTypes = {
  collectible: PropTypes.object,
  isExternal: PropTypes.bool,
};
