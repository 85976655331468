import styled from "styled-components";
import { Title } from "../../GlobalStyles";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: ${({ isSticky }) => (isSticky ? "sticky" : "fixed")};
  top: 0;
  left: 0;
  z-index: 39;
  padding: 16px 20px;
  gap: 16px;
  background: ${({ blur }) => (!blur ? "transparent" : "#11111FE5")};
  backdrop-filter: ${({ blur }) => (!blur ? "none" : "blur(5px)")};
  -webkit-backdrop-filter: ${({ blur }) => (!blur ? "none" : "blur(5px)")};
  transition: 0.3s ease;
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const ContentContainer = styled(DetailsContainer)`
  gap: 16px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.3s ease;
  min-width: fit-content;
`;

export const NavImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  align-self: start;
  margin-right: 16px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.3s ease;
`;

export const NavTitle = styled(Title)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.3s ease;
`;
