import styled from "styled-components";
import { FooterText } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 125px;
  max-height: 125px;
  border-radius: 10px;
  background: #ffffff14;
  text-align: left;
  overflow: hidden;
`;

export const TicketDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;
  min-width: 36px;
  width: 36px;
  max-width: 36px;
`;

export const TicketPrice = styled(FooterText)`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: 900;
  transform: rotate(-90deg) translateY(105%);
  transform-origin: left bottom;
  position: absolute;
  top: 23px;
  left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30px;
  text-align: right;
`;

export const TicketType = styled(FooterText)`
  font-weight: 600;
  transform: rotate(-90deg);
  transform-origin: left top;
  position: absolute;
  bottom: -8px;
  left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 72px;
  text-align: left;
`;

export const TicketImageContainer = styled.div`
  min-height: 100%;
  max-height: 125px;
  min-width: 125px;
  width: 125px;
  max-width: 125px;
  position: relative;
`;

export const TicketNumberDot = styled.div`
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: #11111f33;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TicketCount = styled(TicketNumberDot)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const TicketTokenId = styled(TicketNumberDot)`
  position: absolute;
  bottom: 4px;
  left: 4px;
`;

export const TicketImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  height: 100%;
  flex: 1;
`;

export const VenueAndDateDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ArtistImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

export const SupportingArtists = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
  padding: 6.5px 8px;
  background: ${({ theme }) => theme.colors.greyBtn};
  overflow: hidden;
`;

export const SupportingArtistImages = styled.div`
  display: flex;
  align-items: center;
`;

export const ExtraSupportingCount = styled.div`
  min-height: 16px;
  min-width: 16px;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(10px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  translate: -4px 0px;
`;

export const SupportingArtistNames = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  gap: 4px;
`;
