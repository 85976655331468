import PropTypes from "prop-types";
import { Title } from "../../../GlobalStyles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function CollectibleTitle({ edition_name, isLoading }) {
  if (isLoading) {
    return <SkeletonElement height="24px" width="100px" />;
  }

  if (!edition_name) {
    return null;
  }

  return (
    <Title style={{ textTransform: "uppercase" }} truncate>
      {edition_name}
    </Title>
  );
}

CollectibleTitle.propTypes = {
  edition_name: PropTypes.string,
  isLoading: PropTypes.bool,
};
