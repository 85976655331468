import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BookmarkIcon from "../../../assets/svg/bookmark.svg?react";
import MapIcon from "../../../assets/svg/map.svg?react";
import { BodyText, IconWithText, OneLineText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import EventCountdown from "../../Events/EventCountdown";
import InterestedButton from "../../Events/InterestedButton";
import { DivisionContainer } from "./EventInfo-Styles";
import useAuth from "../../../hooks/useAuth";

const { colors } = theme;

export default function EventInfo({ event }) {
  const { loggedInUser } = useAuth();

  const handleInterestedButtonVisibility = () => {
    const presentDate = new Date();
    const eventDate = new Date(event.date);

    if (presentDate > eventDate) return false;

    if (loggedInUser) return true;

    return false;
  };

  return (
    <DivisionContainer direction="row" gap="16px" justify="space-between">
      {/* Venue and Date */}
      <DivisionContainer gap="4px">
        <IconWithText
          as={event?.combined_venue_id ? Link : "div"}
          to={`/venues/${event?.combined_venue_id}/${event?.venue}`}
        >
          <MapIcon fill={colors.white} height={16} width={16} />
          <OneLineText>{`${event?.venue}, ${event?.city}`}</OneLineText>
        </IconWithText>
        <DivisionContainer direction="row" gap="12px" align="center">
          <IconWithText>
            <BookmarkIcon
              stroke={colors.white}
              fill={colors.white}
              height={16}
              width={16}
            />
            <BodyText color={colors.softGreen}>
              {moment(event.date).format("ddd D MMM")}
            </BodyText>
            <BodyText color={colors.grey}>
              {moment(event.date).format("LT")}
            </BodyText>
          </IconWithText>
          <DivisionContainer align="flex-end">
            <EventCountdown date={event.date} />
          </DivisionContainer>
        </DivisionContainer>
      </DivisionContainer>
      {handleInterestedButtonVisibility() && (
        <DivisionContainer>
          <InterestedButton event={event} size={20} />
        </DivisionContainer>
      )}
    </DivisionContainer>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object,
};
