import moment from "moment/moment";
import { generateMatrixID } from "../utils/matrix-utils";

export const getUserById = async (id, signal) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${id}`,
      {
        signal,
      }
    );
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
  } catch (err) {
    if (err.code === 20) return null;
    console.error(err);
    return null;
  }
};

export const getUserParticipated = async (id) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/participated/${id}`
    );
    const body = await response.json();

    if (response.status !== 200) throw Error(body.message);

    return body;
  } catch (err) {
    if (err.code === 20) return null;
    console.error(err);
    return null;
  }
};

export const insertUser = async (user) => {
  const response = await fetch(`${import.meta.env.VITE_API_URL}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      privyId: user.id,
      discord: user.discord,
      twitter: user.twitter,
      google: user.google,
      mobileNumber: user.phone?.number,
      walletId: user.wallet?.address,
      mxid: generateMatrixID(user.id),
    }),
  });
  const body = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  if (response.status !== 200) throw Error(body.message);

  return body;
};

export const getUserByPrivyId = async (privyId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/privy-id/${privyId}`
  );
  const body = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  if (response.status !== 200) throw Error(body.message);

  return body;
};

export const uploadUserImage = async (userId, image) => {
  let imageUploaded = await fetch(
    `${import.meta.env.VITE_API_URL}/users/${userId}/avatar/upload`,
    {
      method: "POST",
      body: image,
    }
  ).catch((error) => {
    console.error(error);
    return false;
  });

  if (!imageUploaded || imageUploaded.status !== 200) {
    return false;
  }

  imageUploaded = await imageUploaded.json();

  if (!imageUploaded) {
    return false;
  }

  return imageUploaded;
};

export const updateUserDetails = async (userId, detail) => {
  if (!userId || !detail) return;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/${userId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(detail),
    }
  );

  const body = await response.json();

  if (response.status !== 200) throw Error(body.message);

  return body;
};

export const getDuplicateUsername = async (username) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/username/${username}`
  );

  const duplicate = await response.json();

  if (response.status !== 200) throw Error(duplicate.message);

  return duplicate;
};

export const setEmailLinkAction = async ({ user_id }) => {
  const payload = {
    action_type: "link_email",
    target_type: "user",
    target_id: user_id,
    user_id: user_id,
  };

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const setFarcasterLinkAction = async ({ user_id }) => {
  const payload = {
    action_type: "link_farcaster",
    target_type: "user",
    target_id: user_id,
    user_id: user_id,
  };

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const setInviteFriendAction = async ({ user_id }) => {
  const payload = {
    action_type: "invite_friend",
    target_type: "user",
    user_id: user_id,
  };

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const confirmInviteFriendAction = async ({
  user_id,
  action_id,
  target_id,
}) => {
  const payload = {
    action_type: "invite_friend",
    target_type: "user",
    user_id: user_id,
    action_id: action_id,
    target_id: target_id,
  };

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/confirm-invite`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getActionByTypeAndId = async ({ action_id, action_type }) => {
  if (!action_id || !action_type) return false;

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activityById/${action_type}/${action_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getActionByUserIdAndType = async ({
  user_id,
  action_type,
  target_type,
}) => {
  if (!user_id || !action_type || !target_type) return false;

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity/${user_id}?action_type=${action_type}&target_type=${target_type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserXp = async (user_id, signal) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/xp/${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      }
    );

    return result.json();
  } catch (err) {
    if (err.code === 20) return false;
    console.error(err);
    return false;
  }
};

export const setUserAction = async ({
  action_type,
  user_id,
  creator_id,
  target_type,
  target_id,
  artist_id,
  artist_ids,
  hasInterested,
  performersClaimed,
  hasSubscribed,
}) => {
  const payload = {
    action_type: action_type,
    target_type: target_type,
    target_id: target_id,
    artist_id: artist_id,
    creator_id: creator_id,
    user_id: user_id,
    hasInterested: hasInterested,
    performersClaimed: performersClaimed,
    artist_ids: artist_ids,
    hasSubscribed: hasSubscribed,
  };

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const removeUserAction = async ({
  action_id,
  action_type,
  user_id,
  creator_id,
  target_type,
  target_id,
  artist_id,
  artist_ids,
}) => {
  const payload = {
    action_id: action_id,
    action_type: action_type,
    target_type: target_type,
    target_id: target_id,
    artist_id: artist_id,
    creator_id: creator_id,
    user_id: user_id,
    artist_ids: artist_ids,
  };
  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const acceptUserTerms = async (userId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/accept-terms`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserFollowingCount = async (userId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/following/count`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserFollowing = async (userId, loggedInUserId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/following?loggedInUserId=${loggedInUserId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserSubscriptions = async (userId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/subscriptions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserFollowers = async (userId, loggedInUserId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/followers?loggedInUserId=${loggedInUserId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const searchFans = async (
  query,
  userId,
  signal,
  limit = 20,
  offset = 0
) => {
  try {
    // Fetch fans
    let fans = await fetch(
      `${import.meta.env.VITE_API_URL}/fans/search?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          userId,
        }),
        signal,
      }
    );

    // Parse response
    fans = await fans.json();

    if (!fans.success) {
      console.error(fans.error);
      return { success: false, error: "Failed to fetch artist data", data: [] };
    }

    // Return fans
    return fans.data;
  } catch (err) {
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};

export const getIfUserFollowsFan = async (userId, fanId, signal) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/follows/${fanId}`,
      {
        signal,
      }
    );

    const payload = await response.json();

    if (!payload.success) {
      console.error(payload.error);
      return { success: false, error: "Failed to fetch if user follows fan" };
    }

    return { success: true, data: payload.data };
  } catch (err) {
    if (err.code === 20) return false;
    console.error(err);
    return { success: false, error: "Failed to fetch if user follows fan" };
  }
};

export const getUserMessages = async (userId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const getUserLatestMessages = async (userId, signal) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/latest-messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal: signal ?? null,
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const getUserArtistMessages = async (userId, artistId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/artist-messages/${artistId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const readUserLatestMessage = async (userId, messageId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/latest-messages/${messageId}/read`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          timestamp: moment().format("YYYY-MM-DD HH:mm:ssZ"),
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const removeUserLatestMessage = async (userId, senderId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/latest-messages/${senderId}/remove`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const readUserMessage = async (notificationId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/notifications/${notificationId}/read`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          timestamp: moment().format("YYYY-MM-DD HH:mm:ssZ"),
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const removeUserMessage = async (notificationId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/notifications/${notificationId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserNotifications = async (userId, signal) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/notifications`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      }
    );

    return result.json();
  } catch (err) {
    if (err.code === 20) return false;
    console.error(err);
    return false;
  }
};

export const subscribeToTopic = async (userId, topic, token) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/notifications/subscribe`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          topic,
          token,
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const attachUserNotifiableToken = async (token, userId) => {
  if (!token || !userId) return;

  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/user/${userId}/notifications/attachToken`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const saveSearchHistory = async (userId, type, value) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/search/history`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          type,
          value,
        }),
      }
    );

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to save search history" };
    }

    return { success: true };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to save search history" };
  }
};

export const getSearchHistory = async (userId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/search/history/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to get search history" };
    }

    const res = await result.json();

    if (!res.success) {
      console.error(res.error);
      return { success: false, error: "Failed to get search history" };
    }

    return { success: true, data: res.data };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to get search history" };
  }
};

export const getUserStats = async (userId, signal) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/stats`,
      {
        signal,
      }
    );

    if (response.status !== 200) throw Error(response.error);
    const body = await response.json();

    if (!body.success) throw Error(body.message);

    return body.data;
  } catch (err) {
    if (err.code === 20) return null;
    console.error(err);
    return null;
  }
};

export const removeOneSearchHistory = async (userId, type, value) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/search/history/remove`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          type,
          value,
        }),
      }
    );

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to remove search history" };
    }

    const res = await result.json();

    if (!res.success) {
      console.error(res.error);
      return { success: false, error: "Failed to clear search history" };
    }

    return { success: true };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to remove search history" };
  }
};

export const clearSearchHistory = async (userId) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/search/history/clear`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
        }),
      }
    );

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to clear search history" };
    }

    const res = await result.json();

    if (!res.success) {
      console.error(res.error);
      return { success: false, error: "Failed to clear search history" };
    }

    return { success: true };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to clear search history" };
  }
};

export const getXpHistory = async (userId, limit) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/xp-statement?limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to get XP statement" };
    }

    const res = await result.json();

    if (!res.success) {
      console.error(res.error);
      return { success: false, error: "Failed to get XP statement" };
    }

    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to get XP statement" };
  }
};
