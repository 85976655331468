import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 292px;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
`;

export const CardImageContainer = styled.div`
  min-height: 292px;
  height: 292px;
  max-height: 292px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.blueOpaque};
`;

export const SkipContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const CardImage = styled.img`
  min-height: 292px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StepCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : "transparent"};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 20px 24px;
  gap: 12px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  min-height: 0;
  height: 221px;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.blueOpaque};
`;
