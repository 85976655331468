import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useEffect, useMemo } from "react";
import useAuth from "./useAuth";

export const useLinkedWallets = () => {
  const { wallets, ready: walletsReady } = useWallets();
  const { user, ready } = usePrivy();
  const { updateCurrentUser, loggedInUser } = useAuth();
  const isReady = ready && walletsReady;

  const linkedWallets = useMemo(
    () =>
      isReady &&
      user?.linkedAccounts.filter(
        (acc) => acc.type === "wallet" || acc.type === "smart_wallet"
      ),
    [user?.linkedAccounts, isReady]
  );

  //  Get all linked wallets and check if they are connected
  const updatedLinkedWallets = useMemo(
    () =>
      isReady &&
      linkedWallets?.map((linkedWallet) => {
        // Check if the linked wallet exists in the connected wallets array
        const isConnected = wallets?.some(
          (connectedWallet) => connectedWallet.address === linkedWallet.address
        );

        const isSmart = linkedWallet.type === "smart_wallet";

        // Return the linked wallet object if not connected
        return {
          ...linkedWallet,
          connected: isSmart ? true : isConnected,
        };
      }),
    [linkedWallets, wallets, isReady]
  );

  useEffect(() => {
    if (updatedLinkedWallets) {
      const walletAddresses = updatedLinkedWallets.map(
        (wallet) => wallet.address
      );

      if (loggedInUser) {
        updateCurrentUser({ wallet_addresses: walletAddresses });
      }
    }
  }, [updatedLinkedWallets]);

  return { ready: isReady, linkedWallets: updatedLinkedWallets };
};
