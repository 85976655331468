import PropTypes from "prop-types";
import { Container } from "./ArtistDetails-Styles";
import ArtistSocials from "../ArtistSocials";
import ShareButton from "../../ShareButton";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function ArtistDetails({ artist, isLoading }) {
  return (
    <Container>
      <ArtistSocials artist={artist} isLoading={isLoading} />
      {isLoading ? (
        <SkeletonElement height="28px" width="28px" radius="50%" />
      ) : (
        <ShareButton
          url={window.location.href}
          title={artist?.artist_name}
          text={`Check out this artist profile - ${artist?.artist_name}`}
        />
      )}
    </Container>
  );
}

ArtistDetails.propTypes = {
  artist: PropTypes.object,
  isLoading: PropTypes.bool,
};
