import PropTypes from "prop-types";
import { Container } from "./UserTickets-Styles";
import UserTicketsHeader from "./UserTicketsHeader";
import UserTicketsContent from "./UserTicketsContent";
import { useUserTicketsStore } from "./stores/userTicketsStore";
import { useMemo } from "react";
import { USER_TICKETS_FILTERS } from "../../../constants/constants";
import moment from "moment";

const { ALL, UPCOMING, PAST } = USER_TICKETS_FILTERS;

export default function UserTickets({ tickets, isTicketsLoading }) {
  const { activeFilter } = useUserTicketsStore();

  const filteredTickets = useMemo(() => {
    if (isTicketsLoading) return [];

    if (activeFilter === ALL) return tickets;
    else if (activeFilter === UPCOMING || !activeFilter) {
      return tickets
        ?.filter((ticket) => moment(ticket?.date).isAfter(Date.now()))
        ?.sort((a, b) => new Date(a.date) - new Date(b.date));
    } else if (activeFilter === PAST) {
      return tickets
        ?.filter((ticket) => moment(ticket?.date).isBefore(Date.now()))
        ?.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    return tickets;
  }, [activeFilter, tickets, isTicketsLoading]);

  // Render the list of events if the user has events.
  return (
    <Container>
      <UserTicketsHeader
        totalTickets={filteredTickets?.length}
        isLoading={isTicketsLoading}
      />
      <UserTicketsContent
        tickets={filteredTickets}
        isLoading={isTicketsLoading}
      />
    </Container>
  );
}

UserTickets.propTypes = {
  user: PropTypes.object,
  tickets: PropTypes.array,
  isTicketsLoading: PropTypes.bool,
};
