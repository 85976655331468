import styled from "styled-components";
import { ButtonRect, Section } from "../../GlobalStyles";
import { BACKGROUND_IMAGES } from "../../constants/constants";

export const LoginSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px;
  position: relative;
  background: linear-gradient(
      black 0%,
      black 15%,
      rgba(0, 0, 0, 0.5) 50%,
      black 85%,
      black 100%
    ),
    url(${BACKGROUND_IMAGES.LOGIN});
  background-size: cover;
  background-position: center;
  gap: 20px;
`;

export const Navbar = styled.div`
  display: flex;
  gap: 12px;
`;

export const LogoImage = styled.img`
  height: 32px;
  background: transparent;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const CenterContainer = styled(HeaderContainer)`
  gap: 20px;
`;

export const BetaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const BottomContainer = styled(CenterContainer)`
  gap: 40px;
`;

export const LoginButton = styled(ButtonRect)`
  background: ${({ theme }) => theme.colors.green};
  min-width: 160px;
  align-self: center;
`;
