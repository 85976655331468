import { HeaderContainer } from "../../../routes/Collectible/Collectible-Styles";
import {
  BodyText,
  FooterText,
  IconWithText,
  Title,
} from "../../../GlobalStyles";
import TwoTicketsIcon from "../../../assets/svg/two-tickets.svg?react";
import { theme } from "../../../utils/theme";
import { formatPrice } from "../../../utils/utils";
import PropTypes from "prop-types";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { colors } = theme;

export default function TicketHeader({ type, price, count, isLoading }) {
  if (isLoading) {
    return (
      <HeaderContainer>
        <SkeletonElement height="24px" width="100px" />
        <HeaderContainer style={{ gap: "12px" }}>
          <SkeletonElement height="24px" width="80px" />
          <SkeletonElement height="24px" width="80px" />
        </HeaderContainer>
      </HeaderContainer>
    );
  }
  return (
    <HeaderContainer>
      <IconWithText gap="8px">
        <Title
          style={{
            maxWidth: 200,
          }}
          truncate
        >
          {type}
        </Title>
        <FooterText secondary>x{count}</FooterText>
      </IconWithText>
      <HeaderContainer style={{ gap: "12px" }}>
        <IconWithText gap="8px">
          <TwoTicketsIcon height={12} width={12} stroke={colors.grey} />
          <FooterText
            color={colors.grey}
            style={{ textTransform: "capitalize" }}
          >
            Ticket
          </FooterText>
        </IconWithText>
        <BodyText weight="600">${formatPrice(price, false)}</BodyText>
      </HeaderContainer>
    </HeaderContainer>
  );
}

TicketHeader.propTypes = {
  type: PropTypes.string,
  price: PropTypes.number,
  count: PropTypes.number,
  isLoading: PropTypes.bool,
};
