import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { theme } from "../../../utils/theme";
import { Content, Header, Item } from "./TotalSummary-Styles";
import { BodyText, HeaderL, HeaderSm } from "../../../GlobalStyles";
import { formatPrice } from "../../../utils/utils";
import { useCheckoutStore } from "../checkoutStore";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";

const { colors } = theme;

export default function TotalSummary() {
  const { total, ticketsTotal, momentifyFee } = useCheckoutStore();
  const { ticketsSelected } = useBuyTicketStore();

  const formattedTotal = formatPrice(total);
  const formattedTotalTickets = formatPrice(ticketsTotal + momentifyFee, false);
  const free = total === 0 && ticketsSelected?.length > 0;

  return (
    <AnimatedDropdown
      header={
        <Header>
          <BodyText>Purchase total</BodyText>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {free ? (
              <HeaderL lineHeight="1">FREE!</HeaderL>
            ) : (
              <>
                <HeaderL lineHeight="1">${formattedTotal[0]}</HeaderL>
                <HeaderSm>.{formattedTotal[1]}</HeaderSm>
              </>
            )}
          </div>
        </Header>
      }
      content={
        <Content>
          <Item>
            <BodyText secondary>Tickets + fees</BodyText>
            <BodyText>
              {free ? "it's FREE!" : `$${formattedTotalTickets}`}
            </BodyText>
          </Item>
        </Content>
      }
      bg={colors.glass}
      isHeaderCenter
    />
  );
}
