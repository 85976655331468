import PropTypes from "prop-types";
import { styled } from "styled-components";
import EventIcon from "../../assets/svg/events.svg?react";
import SearchIcon from "../../assets/svg/search.svg?react";
import MapPinIcon from "../../assets/svg/map-pin.svg?react";
import { EventItemTypes } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";
import EventItem from "../Events/EventItem";
import { ColumnFlex, RowFlex } from "../Events/YourEvent/YourEvent-Style";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import EmptyState from "../EmptyState";
import RectButtonIconText from "../RectButtonIconText";
import { theme } from "../../utils/theme";
import { BodyText, FooterText } from "../../GlobalStyles";
import { useNavigate } from "react-router-dom";
import PartialEmptyState from "../PartialEmptyState";
import { useState } from "react";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px;
  flex: 1;
`;

/**
 * Component for rendering the user's events.
 *
 * Renders the user's events and handles rerendering after a certain time period.
 *
 * @param {Object} user - the user whose events are being rendered
 * @param {Array} events - the list of events to be rendered
 * @return {React.JSX.Element} the component for rendering the user's events
 */
export default function UserEvents({ user, events, isEventsLoading }) {
  // const [rerender, setRerender] = useState(false);
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const [randomizer] = useState(Math.floor(Math.random() * 2));

  // Whether or not the user is viewing their own profile.
  const isOwnProfile = loggedInUser?.id == user?.id;

  // Whether or not the user has any events to render.
  const noEvents = !events || events.length == 0 || events[0] == null;

  if (isEventsLoading)
    return (
      <CardContainer>
        {Array.from({ length: 4 }, (_, index) => index).map((index) => (
          <div key={index + "skeleton"}>
            <RowFlex style={{ marginBottom: "1rem" }}>
              <SkeletonElement width="58px" height="58px" radius="10px" />
              <ColumnFlex style={{ gap: "4px" }}>
                <SkeletonElement
                  style={{ marginBottom: "10px" }}
                  width="80px"
                  height="16px"
                  radius="10px"
                />
                <SkeletonElement width="120px" height="10px" radius="10px" />
                <SkeletonElement width="120px" height="10px" radius="10px" />
              </ColumnFlex>
            </RowFlex>
            <RowFlex style={{ marginBottom: "1rem" }}>
              <SkeletonElement width="58px" height="58px" radius="10px" />
              <ColumnFlex style={{ gap: "4px" }}>
                <SkeletonElement
                  style={{ marginBottom: "10px" }}
                  width="100px"
                  height="16px"
                  radius="10px"
                />
                <SkeletonElement width="160px" height="10px" radius="10px" />
                <SkeletonElement width="120px" height="10px" radius="10px" />
              </ColumnFlex>
            </RowFlex>
            <RowFlex>
              <SkeletonElement width="58px" height="58px" radius="10px" />
              <ColumnFlex style={{ gap: "4px" }}>
                <SkeletonElement
                  style={{ marginBottom: "10px" }}
                  width="180px"
                  height="16px"
                  radius="10px"
                />
                <SkeletonElement width="200px" height="10px" radius="10px" />
                <SkeletonElement width="150px" height="10px" radius="10px" />
              </ColumnFlex>
            </RowFlex>
          </div>
        ))}
      </CardContainer>
    );

  // Render the empty tab if the user doesn't have any events.
  if (noEvents) {
    return (
      <EmptyState
        icon={<EventIcon height={24} width={24} fill="white" opacity={0.6} />}
        title="No Gigs Planned?"
        description="Add your future gig plans here and speak to other fans."
        button={
          isOwnProfile && (
            <RectButtonIconText
              icon={
                <SearchIcon
                  stroke={theme.colors.white}
                  height={14}
                  width={14}
                />
              }
              text={<BodyText weight="600">Search events</BodyText>}
              onClick={() => {
                navigate("/search?tab=event");

                setTimeout(() => {
                  document.querySelector("input[name=search]")?.focus();
                }, 100);
              }}
            />
          )
        }
      />
    );
  }

  // Render the list of events if the user has events.
  return (
    <CardContainer>
      <BodyText weight="600">
        Events
        <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
          ({events?.length})
        </FooterText>
      </BodyText>
      {events.map((event) => (
        <EventItem
          key={event.id}
          event={event}
          isOwnProfile={isOwnProfile}
          type={EventItemTypes.PROFILE}
        />
      ))}
      {events.length < 5 && (
        <PartialEmptyState
          icon={
            randomizer === 0 ? (
              <MapPinIcon
                height={24}
                width={24}
                stroke={theme.colors.white}
                opacity={0.6}
              />
            ) : (
              <EventIcon height={24} width={24} fill="white" opacity={0.6} />
            )
          }
          description={
            randomizer === 0
              ? "Check in at these events to earn XP."
              : "Bookmark events to receive personalised event recommendations."
          }
        />
      )}
    </CardContainer>
  );
}

UserEvents.propTypes = {
  user: PropTypes.object,
  events: PropTypes.array,
  isEventsLoading: PropTypes.bool,
};
