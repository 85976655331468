import { useState, useEffect, useCallback, useRef } from "react";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { checkWhitelisted, logRestrictionCode } from "../api/restriction";
import { useDebouncedValue } from "@mantine/hooks";

export function useRestriction(opened, open, close) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [debouncedPhoneNumber] = useDebouncedValue(phoneNumber, 500);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [parsedNumber, setParsedNumber] = useState(null);
  const buttonRef = useRef(null);
  const selectRef = useRef(null);
  const whitelistedQuery = useWhitelisted(parsedNumber, isPhoneValid);

  // Remove the updateButtonState callback

  useEffect(() => {
    if (buttonRef.current) {
      const isWhitelisted = whitelistedQuery.data?.isWhitelisted;
      const isValidating = whitelistedQuery.isLoading;

      // Disable button when validating or when not whitelisted or phone is invalid
      buttonRef.current.disabled =
        isValidating || !(isWhitelisted && isPhoneValid);

      buttonRef.current.style.color =
        isValidating || !(isWhitelisted && isPhoneValid)
          ? "gray"
          : "hsl(162, 60%, 65%)";

      buttonRef.current.innerHTML = isValidating ? "Validating" : "Submit";

      // Disable input field while validating
      const phoneInput = document.getElementById("phone-number-input");
      if (phoneInput) {
        phoneInput.disabled = isValidating;
      }

      if (!isWhitelisted && isPhoneValid && !isValidating) {
        open();
      }
    }
  }, [whitelistedQuery.data, whitelistedQuery.isLoading, isPhoneValid]);

  const checkCountryCode = useCallback(() => {
    if (selectRef.current) {
      const newCountryCode = selectRef.current.value;

      if (newCountryCode !== selectedCountryCode) {
        setSelectedCountryCode(newCountryCode);
        setPhoneNumber("");
        const button = buttonRef.current;
        if (button) {
          button.disabled = true;
          button.style.color = "gray";
        }
      }
    }
  }, [selectedCountryCode]);

  const findElements = useCallback(() => {
    const spans = document.querySelectorAll("span");
    let button = null;

    spans.forEach((span) => {
      if (span.textContent.trim() === "Submit") {
        button = span.closest("button");
      }
    });
    if (button) buttonRef.current = button;

    const select = document.querySelector("select");
    if (select) selectRef.current = select;

    if (buttonRef.current && selectRef.current) {
      checkCountryCode();
      return true;
    }
    return false;
  }, [checkCountryCode]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (findElements()) {
        clearInterval(intervalId);
      }
    }, 100);

    // Add a MutationObserver to watch for DOM changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          findElements();
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      clearInterval(intervalId);
      observer.disconnect();
    };
  }, [findElements]);

  useEffect(() => {
    const handlePhoneChange = (event) => {
      if (event.target.id === "phone-number-input") {
        setPhoneNumber(event.target.value);
        const button = buttonRef.current;
        if (button) {
          button.disabled = true;
          button.style.color = "gray";
        }
      }
    };

    document.addEventListener("input", handlePhoneChange);
    document.addEventListener("change", checkCountryCode);

    // Initial check
    checkCountryCode();

    return () => {
      document.removeEventListener("input", handlePhoneChange);
      document.removeEventListener("change", checkCountryCode);
    };
  }, [checkCountryCode]);

  useEffect(() => {
    const validatePhoneNumber = () => {
      if (!debouncedPhoneNumber || !selectedCountryCode) {
        setIsPhoneValid(false);
        setParsedNumber(null);
        return;
      }

      try {
        const parsedNumber =
          phoneNumber.length > 7
            ? parsePhoneNumber(debouncedPhoneNumber, selectedCountryCode)
            : null;

        const valid =
          parsedNumber &&
          isValidPhoneNumber(parsedNumber.number, selectedCountryCode)
            ? true
            : false;

        setIsPhoneValid(valid);
        setParsedNumber(valid ? parsedNumber.number : null);

        const updateButtonState = () => {
          const spans = document.querySelectorAll("span");
          let button = null;

          spans.forEach((span) => {
            if (span.textContent.trim() === "Submit") {
              button = span.closest("button");
            }
          });
          if (button) {
            button.disabled = true;
            button.style.color = "gray";
          }
        };

        updateButtonState();
      } catch (error) {
        console.error("Error validating phone number:", error);
        setIsPhoneValid(false);
        setParsedNumber(null);
      }
    };

    validatePhoneNumber();
  }, [debouncedPhoneNumber, selectedCountryCode]);

  return {
    phoneNumber: parsedNumber,
    selectedCountryCode,
    isPhoneValid,
    setPhoneNumber,
    setSelectedCountryCode,
  };
}

export const useWhitelisted = (phoneNumber, valid) => {
  return useQuery({
    queryKey: ["whitelisted", phoneNumber],
    queryFn: async () => {
      const whitelistedNumber = await checkWhitelisted(phoneNumber);

      return whitelistedNumber;
    },
    enabled: valid,
    staleTime: Infinity,
  });
};

export const useWhitelistCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["whitelist-code"],
    mutationFn: async ({ phoneNumber, code }) => {
      let whitelistedNumber;

      whitelistedNumber = await logRestrictionCode(phoneNumber, code);

      return whitelistedNumber;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["whitelisted"] });
    },
    staleTime: Infinity,
  });
};
