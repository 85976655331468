import { usePrivy } from "@privy-io/react-auth";
import { NavBarTypes } from "../../constants/constants";
import { Container } from "./Wallets-Styles";
import useAuth from "../../hooks/useAuth";
import { useLinkedWallets } from "../../hooks/useLinkedWallets";
import ErrorPage from "../Error";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";
import WalletsTabs from "../../components/WalletsPage/WalletsTabs";
import TopNavV2 from "../../components/TopNavV2";

export default function Wallets() {
  const { authenticated, user } = usePrivy();
  const { loggedInUser } = useAuth();
  const { linkedWallets, ready } = useLinkedWallets();
  const navigate = useNavigate();
  const { handleTracking } = useMixpanelTracker();

  useEffect(() => {
    handleTracking("Visited Wallets Page");
  }, []);

  useEffect(() => {
    if (!ready) return;

    const redirectToLogin = () => {
      if (!authenticated && !user && !loggedInUser) {
        navigate(`/login`);
      }
    };

    redirectToLogin();
  }, [authenticated, user, loggedInUser, navigate]);

  if (!linkedWallets || linkedWallets?.length === 0) return <ErrorPage />;

  return (
    <Container>
      <TopNavV2 title={NavBarTypes.WALLET} isSticky />
      <WalletsTabs isLoading={!ready} />
    </Container>
  );
}
