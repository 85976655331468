import PropTypes from "prop-types";
import { Section, Container } from "./BuyTicket-Styles";
import { useBuyTicketStore } from "./buyTicketStore";
import { useCheckoutStore } from "../Checkout/checkoutStore";
import { useEffect } from "react";
import TopNavV2 from "../TopNavV2";
import EventDetails from "./EventDetails";
import Tickets from "./Tickets";
import Summary from "./Summary";
import { useTicketsStatusQuery } from "./hooks/buyTicketQueries";
import useAuth from "../../hooks/useAuth";
import { useAlchemyQuery } from "../../routes/Profile/hooks/profileQueries";

export default function BuyTicket({ event, ticketsData }) {
  const { id: eventId, headline_artist_id } = event || {};
  const { contract } = ticketsData || {};
  const { loggedInUser } = useAuth();
  const {
    reset: resetBuyTicketStore,
    setEvent,
    setContract,
    resetTicketsSelected,
  } = useBuyTicketStore();
  const { reset: resetCheckoutStore, resetTotal } = useCheckoutStore();
  const { data: status, isLoading: statusLoading } = useTicketsStatusQuery({
    userId: loggedInUser?.id,
    eventId: eventId,
    artistId: headline_artist_id,
  });

  useAlchemyQuery();

  useEffect(() => {
    resetTicketsSelected();
    resetTotal();
  }, []);

  useEffect(() => {
    if (!event || !contract) return;

    setEvent(event);
    setContract(contract);
  }, [event, contract]);

  const handleBack = () => {
    resetBuyTicketStore();
    resetCheckoutStore();
  };

  return (
    <Section>
      <TopNavV2 title="Buy ticket" onBack={handleBack} isSticky isBlurred />
      <Container>
        <EventDetails event={event} />
        <Tickets
          data={ticketsData}
          status={status}
          statusLoading={statusLoading}
        />
      </Container>
      <Summary status={status} statusLoading={statusLoading} />
    </Section>
  );
}

BuyTicket.propTypes = {
  event: PropTypes.object.isRequired,
  ticketsData: PropTypes.object.isRequired,
};
