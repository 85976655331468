import PropTypes from "prop-types";
import {
  CardContent,
  CardImage,
  CardImageContainer,
  Container,
  SkipContainer,
  StepCircle,
  StepsContainer,
} from "./OnboardingCard-Styles";
import {
  BodyText,
  ButtonRect,
  FooterText,
  HeaderXl,
} from "../../../GlobalStyles";
import { CARDS } from "../../../constants/onboarding-cards";
import { useOnboardingStore } from "../onboardingStore";
import { theme } from "../../../utils/theme";

export default function OnboardingCard({ data }) {
  const { image, title, description, color } = data || {};
  const { step, setStep, setIsOnboarded } = useOnboardingStore();
  const isLast = step === CARDS.length - 1;

  const handleNext = ({ isSkip }) => {
    if (isLast || isSkip) {
      setIsOnboarded(true);
      return;
    }

    setStep(step + 1);
  };

  return (
    <Container>
      <CardImageContainer>
        <SkipContainer onClick={() => handleNext({ isSkip: true })}>
          <FooterText secondary>Skip</FooterText>
        </SkipContainer>
        <CardImage src={image} alt={title} />
        <StepsContainer>
          {CARDS.map((card, index) => (
            <StepCircle key={index} isActive={index === step} />
          ))}
        </StepsContainer>
      </CardImageContainer>
      <CardContent color={color}>
        <HeaderXl
          style={{
            textTransform: "uppercase",
          }}
        >
          {title}
        </HeaderXl>
        <BodyText color={theme.colors.onboardingTextColor}>
          {description}
        </BodyText>
        <ButtonRect
          primary
          style={{
            marginTop: "auto",
            minWidth: 0,
            width: 220,
            background: "#F7F7F733",
          }}
          onClick={handleNext}
        >
          {isLast ? "Finish" : "Next"}
        </ButtonRect>
      </CardContent>
    </Container>
  );
}

OnboardingCard.propTypes = {
  data: PropTypes.object,
};
