import PropTypes from "prop-types";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import { Image, ImageContainer } from "./CollectibleThumbnail-Styles";

export default function CollectibleThumbnailImage({ image }) {
  return (
    <ImageContainer>
      <Image
        src={image ?? GlassmorphPlaceholder}
        alt="collectible"
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />
    </ImageContainer>
  );
}

CollectibleThumbnailImage.propTypes = {
  image: PropTypes.string,
};
