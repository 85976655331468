import { BodyText, Title } from "../../../GlobalStyles";
import { Container, HeaderContainer } from "./ProcessingWertModal-Styles";
import TwoTickets from "../../../assets/svg/two-tickets.svg?react";
import { theme } from "../../../utils/theme";

export default function ProcessingWertModal() {
  return (
    <Container>
      <HeaderContainer>
        <TwoTickets height={24} width={24} stroke={theme.colors.green} />
        <Title>Processing</Title>
      </HeaderContainer>
      <BodyText>Please wait while we process your order.</BodyText>
      <BodyText>This may take a few seconds. Do not close the app.</BodyText>
    </Container>
  );
}
