import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import { useUserTicketsStore } from "./stores/userTicketsStore";
import { USER_TICKETS_FILTERS } from "../../../constants/constants";

export default function UserTicketsFilter({ filter }) {
  const { activeFilter, setActiveFilter } = useUserTicketsStore();
  const isActive =
    activeFilter === filter ||
    (!activeFilter && filter === USER_TICKETS_FILTERS.ALL);

  const handleFilter = () => {
    setActiveFilter(filter);
  };

  return (
    <FooterText
      onClick={handleFilter}
      bold
      secondary={!isActive}
      style={{
        textTransform: "capitalize",
      }}
    >
      {filter}
    </FooterText>
  );
}

UserTicketsFilter.propTypes = {
  filter: PropTypes.string,
};
