export const fetchCollectibles = async (userId, signal) => {
  if (!userId) return false;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/user/${userId}/collectibles`,
    {
      signal,
    }
  ).catch((err) => {
    if (err.code === 20) return false;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return [];
  }

  const collectibles = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectibles || !collectibles.success) return [];

  return collectibles?.data || [];
};

export const fetchSingleCollectible = async (
  userId,
  collectibleId,
  type = "airdrop"
) => {
  if (!userId || !collectibleId) return null;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/user/${userId}/collectible/${collectibleId}/${type}`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const collectible = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectible || !collectible.success) return null;

  return collectible?.data || null;
};

export const fetchSingleExternalCollectible = async (
  userId,
  collectibleId,
  tokenId
) => {
  if (!userId || !tokenId || !collectibleId) return null;
  console.log(userId, tokenId, collectibleId);
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/external-nfts/${userId}/${collectibleId}/${tokenId}`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const collectible = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectible || !collectible.success) return null;

  return collectible?.data || null;
};

export const fetchExternalNFTs = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/external-nfts`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const nfts = await response.json().catch((err) => {
    console.error(err);
  });

  return nfts?.data || null;
};

export const fetchExternalMints = async (owner, contractAddress) => {
  const response = await fetch(
    `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/onchain/mints`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ owner, contractAddress }),
    }
  ).catch((err) => {
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const mints = await response.json().catch((err) => {
    console.error(err);
  });

  return mints?.data || null;
};

export const insertExternalMints = async (userId, externalMints) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/insert-external-nfts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, externalMints }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const getFIDMints = async (fid) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/get-fid-mints`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fid }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const updateExternalMint = async (transactionHash) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/update-external-mint`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ transactionHash }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};
