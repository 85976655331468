import { BodyText } from "../../../GlobalStyles";
import { Container } from "./PaymentMethods-Styles";
import { PAYMENT_METHODS } from "../../../constants/constants";
import PaymentMethod from "../PaymentMethod";
import PropTypes from "prop-types";

const { WALLET, CREDIT_CARD } = PAYMENT_METHODS;

export default function PaymentMethods() {
  return (
    <Container>
      <BodyText weight="600">Payment method</BodyText>
      <PaymentMethod method={CREDIT_CARD} />
      <PaymentMethod method={WALLET} disabled />
    </Container>
  );
}

PaymentMethods.propTypes = {
  isCards: PropTypes.bool,
};
