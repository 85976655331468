// Replace this with your app's required chains
import { http, createConfig as createWagmiConfig } from "wagmi";
import { base, baseSepolia } from "wagmi/chains";
import { coinbaseWallet } from "@wagmi/connectors";

export const wagmiConfig = createWagmiConfig({
  chains: [base, baseSepolia], // Pass your required chains as an array
  connectors: [
    coinbaseWallet({
      appName: "Momentify",
      preference: "smartWalletOnly",
      version: "4",
    }),
  ],
  transports: {
    [base.id]: http(),
    [baseSepolia.id]: http(),
    // For each of your required chains, add an entry to `transports` with
    // a key of the chain's `id` and a value of `http()`
  },
});
