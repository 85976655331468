import {
  useNewlyAppended,
  useNewlyAppendedPopular,
} from "../../routes/Events/hooks/eventsQueries";
import useAuth from "../../hooks/useAuth";
import useGeolocation from "../../hooks/useGeolocation";
import { SmallHeader } from "./components/SmallHeader";
import { WideEventCard } from "./components/WideEventCard";
import { useEffect, useState } from "react";
import { WideSkeleton } from "./components/WideSkeleton";
import { BodyText } from "../../GlobalStyles";

export const NewEventFrom = () => {
  const { currentLocation } = useGeolocation();
  const { loggedInUser } = useAuth();
  const [event, setEvent] = useState(null);

  const newlyAppendedQuery = useNewlyAppended({
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    userId: loggedInUser?.id ?? null,
  });

  const newlyAppendedPopularQuery = useNewlyAppendedPopular();

  const data =
    newlyAppendedQuery?.data?.length > 0
      ? newlyAppendedQuery?.data
      : newlyAppendedPopularQuery?.data;

  useEffect(() => {
    if (data && data.length > 0) {
      const randomIndex = Math.floor(Math.random() * data.length);
      setEvent(data[randomIndex]);
    } else setEvent([]);
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}
    >
      {newlyAppendedQuery.isLoading || event == null ? (
        <WideSkeleton />
      ) : event != null && !Array.isArray(event) ? (
        <>
          <SmallHeader
            headerText={
              <BodyText weight="600">
                New event from <u>{event.artist_name}</u>
              </BodyText>
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              overflow: "scroll",
              background: "none",
            }}
          >
            <WideEventCard
              event={event}
              key={event?.event_id}
              isEvent={true}
              vertical_position={0}
              horizontal_position={0}
              header={"New event from"}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
