import { useMutation } from "@tanstack/react-query";

import { logLocation } from "../../../api/user";

export const useLogLocation = ({ user_id, latitude, longitude, accuracy }) => {
  return useMutation({
    mutationKey: ["logLocation"],
    mutationFn: async () => {
      return logLocation(user_id, latitude, longitude, accuracy);
    },
  });
};
