import styled from "styled-components";
import PropTypes from "prop-types";
import FacebookIcon from "../../assets/svg/facebook.svg?react";
import InstagramIcon from "../../assets/svg/instagram.svg?react";
import SpotifyIcon from "../../assets/svg/spotify.svg?react";
import TwitterIcon from "../../assets/svg/twitter-x.svg?react";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import { theme } from "../../utils/theme";

const ArtistSocialsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ArtistSocialItemContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

export default function ArtistSocials({ artist, isLoading }) {
  if (isLoading) {
    return (
      <ArtistSocialsContainer>
        <SkeletonElement height="28px" width="28px" radius="50%" />
        <SkeletonElement height="28px" width="28px" radius="50%" />
        <SkeletonElement height="28px" width="28px" radius="50%" />
        <SkeletonElement height="28px" width="28px" radius="50%" />
      </ArtistSocialsContainer>
    );
  }

  if (
    !artist ||
    (!artist?.spotify_url &&
      !artist?.facebook_url &&
      !artist?.twitter_url &&
      !artist?.instagram_url)
  )
    return null;

  return (
    <ArtistSocialsContainer>
      {artist?.spotify_url && (
        <ArtistSocialItemContainer href={artist.spotify_url} target="_blank">
          <SpotifyIcon height={24} width={24} fill={theme.colors.white} />
        </ArtistSocialItemContainer>
      )}
      {artist?.facebook_url && (
        <ArtistSocialItemContainer href={artist.facebook_url} target="_blank">
          <FacebookIcon height={24} width={24} />
        </ArtistSocialItemContainer>
      )}
      {artist?.twitter_url && (
        <ArtistSocialItemContainer href={artist.twitter_url} target="_blank">
          <TwitterIcon height={24} width={24} />
        </ArtistSocialItemContainer>
      )}
      {artist?.instagram_url && (
        <ArtistSocialItemContainer href={artist.instagram_url} target="_blank">
          <InstagramIcon height={24} width={24} />
        </ArtistSocialItemContainer>
      )}
    </ArtistSocialsContainer>
  );
}

ArtistSocials.propTypes = {
  artist: PropTypes.object,
  isLoading: PropTypes.bool,
};
