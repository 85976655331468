import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import { useEventStore } from "../../routes/Events/stores/eventStore";
import { theme } from "../../utils/theme";
import { useToggleInterested } from "./hooks/interestedQueries";
import { useUserStore } from "../../routes/Profile/store/userStore";
import { useState } from "react";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

const Button = styled.button`
  display: grid;
  place-content: center;
  background: transparent;
  border: none;
  z-index: 2;
  width: fit-content;
  height: fit-content;
`;

/**
 * InterestedButton component to display and handle user interest in an event
 * @param {Object} event - The event object
 * @param {string} size - The size of the button
 * @param {string} color - The color of the button
 * @return {React.JSX.Element} The rendered InterestedButton component
 */
export default function InterestedButton({ event, size, color }) {
  const { loggedInUser, login } = useMomentifyLogin();

  const queryClient = useQueryClient();
  const toggleInterestedMutation = useToggleInterested();
  const { setNearbyArtists } = useEventStore();

  const { userParticipated, addUserParticipated, removeUserParticipated } =
    useUserStore();
  const eventId = event?.id ?? event?.event_id;
  const [interested, setInterested] = useState(
    userParticipated.includes(eventId)
  );
  const { handleTracking } = useMixpanelTracker();

  // Determine the event ID

  // // Effect  to check if the user is interested in the event on component mount

  // useEffect(() => {
  //   const checkIfInterested = async () => {
  //     if (userParticipated.includes(eventId)) {
  //       setInterested(true);
  //     } else {
  //       setInterested(false);
  //     }
  //   };

  //   checkIfInterested();
  // }, [eventId, userParticipated]);

  // Handle user interest in the event
  const handleInterested = async (e) => {
    e.preventDefault();

    // If user is not logged in, display an error message
    if (!loggedInUser) return login();

    // Function to handle error messages
    const errorHandling = (err) => {
      toast.error("Something went wrong!");
      console.error(err);
      return false;
    };

    // Toggle the user's interest status
    if (interested) {
      removeUserParticipated(eventId);
      handleTracking("Unbookmarked an event", {
        "Event ID": eventId,
      });
    } else {
      addUserParticipated(eventId);
      handleTracking("Bookmarked an event", {
        "Event ID": eventId,
      });
    }

    setInterested((interested) => !interested);

    toggleInterestedMutation.mutateAsync(
      {
        interested,
        eventId,
        loggedInUser,
        errorHandling,
        event,
      },
      {
        onSuccess: () => {
          queryClient.removeQueries({
            queryKey: ["profile", loggedInUser.id.toString()],
          });

          queryClient.invalidateQueries({
            queryKey: ["interested_events"],
            type: "all",
          });

          const queryKeys = [
            "interested_events",
            "nearby_events",
            "event",
            "london_events",
            "nearby_artists",
            "recommended_events",
            "coming_soon",
          ];

          queryKeys.forEach((baseQueryKey) => {
            // Get all queries with keys that start with the base query key
            queryClient
              .getQueryCache()
              .getAll()
              .forEach(({ queryKey }) => {
                if (queryKey[0] === baseQueryKey) {
                  // Get the current data for the query
                  const currentData = queryClient.getQueryData(queryKey);

                  if (currentData) {
                    let updatedData;
                    if (
                      baseQueryKey === "nearby_artists" &&
                      Array.isArray(currentData.events)
                    ) {
                      // Handle the special case for nearby_artists
                      updatedData = {
                        ...currentData,
                        events: currentData.events.map((eventData) => {
                          return (eventData?.event_id ?? eventData?.id) ==
                            eventId
                            ? { ...eventData, is_interested: !interested }
                            : eventData;
                        }),
                      };

                      setNearbyArtists(updatedData);
                    } else if (Array.isArray(currentData)) {
                      // Update the is_interested attribute in the array data
                      updatedData = currentData.map((eventData) => {
                        return (eventData?.event_id ?? eventData?.id) == eventId
                          ? { ...eventData, is_interested: !interested }
                          : eventData;
                      });
                    } else {
                      // Update the is_interested attribute in the non-array data
                      updatedData =
                        (currentData?.event_id ?? currentData?.id) == eventId
                          ? {
                              ...currentData,
                              is_interested: !interested,
                              interested_count: interested
                                ? currentData.interested_count - 1
                                : currentData.interested_count + 1,
                            }
                          : currentData;
                    }

                    // Set the updated data back to the query cache
                    queryClient.setQueryData(queryKey, updatedData);
                  }
                }
              });
          });

          setTimeout(() => {
            queryClient.invalidateQueries({
              queryKey: ["interested_events"],
              type: "all",
            });
          }, 4000);
        },
      }
    );
  };

  if (!event) return null;

  // Render the button with the appropriate styles and handlers
  return (
    <Button onClick={handleInterested}>
      <Bookmark
        height={size ?? 16}
        width={size ?? 16}
        fill={interested ? theme.colors.green : "#11111F33"}
        stroke={interested ? theme.colors.green : (color ?? "white")}
      />
    </Button>
  );
}

InterestedButton.propTypes = {
  event: PropTypes.object.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  isFilled: PropTypes.bool,
};
