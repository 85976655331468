import useAuth from "./useAuth";
import {
  identifyTrackableUser,
  initTracker,
  sendTrackingData,
} from "../utils/mixpanel";
import { isProd } from "../utils/utils";

function useMixpanelTracker() {
  const { loggedInUser } = useAuth();

  const handleTracking = async (type, payload, user) => {
    if (!isProd) return;

    if (!type) return;

    initTracker();
    identifyTrackableUser(user || loggedInUser);
    sendTrackingData(type, payload);
  };

  return {
    handleTracking,
  };
}

export default useMixpanelTracker;
