import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 10px;
`;
