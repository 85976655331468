import { HeaderL } from "../../GlobalStyles";
import { Container, HeaderContainer } from "./Checkout-Styles";
import PaymentMethods from "./PaymentMethods";
import { useCheckoutStore } from "./checkoutStore";
import OrderSummary from "./OrderSummary";
import TotalSummary from "./TotalSummary";
import { PAYMENT_METHODS } from "../../constants/constants";
import { useEffect } from "react";
import { useBuyTicketStore } from "../BuyTicket/buyTicketStore";
import CheckoutButton from "./CheckoutButton";

export default function Checkout() {
  const { ticketsSelected } = useBuyTicketStore();
  const { paymentMethod, setPaymentMethod, total, isMinting } =
    useCheckoutStore();

  const free = total === 0 && ticketsSelected?.length > 0;

  useEffect(() => {
    if (!paymentMethod && !free) setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD);
  }, []);

  return (
    <Container
      style={{
        padding: isMinting ? 24 : "0 24px 24px",
      }}
    >
      <HeaderContainer>
        <HeaderL style={{ textTransform: "uppercase" }}>Checkout</HeaderL>
        <OrderSummary />
      </HeaderContainer>
      {!free && <PaymentMethods />}
      <TotalSummary />
      <CheckoutButton />
    </Container>
  );
}
