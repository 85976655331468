import PropTypes from "prop-types";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { useEffect, useState } from "react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import Bookmark from "../../../assets/svg/bookmark.svg?react";
import {
  EventsTabContainer,
  Container,
  EventsHeader,
  SeeAllEvents,
} from "./EventsTab-Styles";
import { theme } from "../../../utils/theme";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowRight } from "@phosphor-icons/react";
import EmptyState from "../../EmptyState";
import EventItem from "../../Events/EventItem";
import useAuth from "../../../hooks/useAuth";
import { EventItemTypes } from "../../../constants/constants";
import ArtistEventDateBox from "../../Artist/ArtistEventDateBox";
import {
  ArtistEventContainer,
  ArtistEventDetailsContainer,
  ArtistEventDetais,
} from "../../Artist/ArtistEventDateBox/ArtistEventDateBox-Styles";

export default function EventsTab({ events, isLoading }) {
  const { loggedInUser } = useAuth();
  const location = useLocation();

  // Display all events if showAllEvents is true, otherwise display the first 3 events
  const [displayedEvents, setDisplayedEvents] = useState([]);

  // Check if first event date is today
  const [isSameDay, setIsSameDay] = useState(false);
  const [isLiveNow, setIsLiveNow] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!events || isLoading || !events?.length) return;

    // Check if first event date is today
    const firstEventDate = moment(
      events[0]?.event_date ?? events[0]?.date
    ).utc();
    const today = moment();

    if (
      firstEventDate.isSame(today, "d") &&
      today.isAfter(firstEventDate, "h")
    ) {
      setIsSameDay(true);
      setIsLiveNow(true);
    } else if (firstEventDate.isSame(today, "d")) setIsSameDay(true);
  }, [events, isLoading, isSameDay]);

  useEffect(() => {
    if (!events || isLoading) return;

    setDisplayedEvents(events?.slice(isSameDay ? 1 : 0, 5));
  }, [events, isLoading, isSameDay]);

  // Toggle showAllEvents state
  const handleSeeAllByArtist = () => {
    const { artist_name, artist_id } = events?.[0] || {};

    navigate(`/search?query=${artist_name}&tab=event&artist=${artist_id}`);
  };

  const handleSeeAllByVenue = () => {
    const { combined_venue_id } = events?.[0] || {};

    navigate(`/search?tab=event&venue=${combined_venue_id}`);
  };

  const handleSeeAll = () => {
    location.pathname.includes("artists")
      ? handleSeeAllByArtist()
      : handleSeeAllByVenue();
  };

  if (isLoading) {
    return (
      <Container>
        <EventsTabContainer>
          <SkeletonElement height="21px" width="130px" />
          {Array.from({ length: 3 }, (_, index) => index).map((index) => (
            <ArtistEventContainer key={index}>
              <ArtistEventDetailsContainer>
                <SkeletonElement height="58px" width="58px" radius="10px" />
                <ArtistEventDetais style={{ gap: "6px" }}>
                  <SkeletonElement height="16px" width="200px" />
                  <SkeletonElement height="16px" width="150px" />
                </ArtistEventDetais>
              </ArtistEventDetailsContainer>
              <SkeletonElement height="20px" width="20px" />
            </ArtistEventContainer>
          ))}
        </EventsTabContainer>
      </Container>
    );
  }

  if (!events || events?.length === 0)
    return (
      <EmptyState
        icon={<Bookmark height={24} width={24} stroke="white" opacity={0.6} />}
        title="No upcoming events"
        description={`There are no upcoming events ${location.pathname.includes("artists") ? "for this artist." : "at this venue."}`}
        style={{
          paddingTop: "15%",
        }}
      />
    );

  return (
    <Container>
      {isSameDay && (
        <EventsTabContainer>
          <BodyText weight="600">{isLiveNow ? "Live now!" : "Today"}</BodyText>
          {location.pathname.includes("artists") ? (
            <ArtistEventDateBox event={events[0]} />
          ) : (
            <EventItem
              event={events[0]}
              isOwnProfile={loggedInUser && true}
              type={EventItemTypes.VENUE}
            />
          )}
        </EventsTabContainer>
      )}
      {events?.length === 1 && isSameDay ? null : (
        <EventsTabContainer>
          <EventsHeader>
            <BodyText weight="600">Next Events</BodyText>
            {events.length > 5 && (
              <SeeAllEvents onClick={handleSeeAll}>
                <FooterText color={theme.colors.grey}>View all</FooterText>
                <ArrowRight size={14} color={theme.colors.grey} />
              </SeeAllEvents>
            )}
          </EventsHeader>
          {/* Map through and render each displayed event */}
          {displayedEvents?.map((event) =>
            location.pathname.includes("artists") ? (
              <ArtistEventDateBox key={event.event_id} event={event} />
            ) : (
              <EventItem
                key={event.id}
                event={event}
                isOwnProfile={loggedInUser && true}
                type={EventItemTypes.VENUE}
              />
            )
          )}
        </EventsTabContainer>
      )}
    </Container>
  );
}

EventsTab.propTypes = {
  events: PropTypes.array,
  isLoading: PropTypes.bool,
};
