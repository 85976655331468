import styled from "styled-components";
import { ButtonRectSm } from "../../../GlobalStyles";
import { PerformanceButton } from "../PerformanceItem/PerformanceItem-Styles";

export const AddMomentButton = styled(ButtonRectSm)`
  display: flex;
  align-items: center;
  align-self: center;

  &:disabled {
    background: ${({ theme }) => theme.colors.blueShade};
    border: none;
    opacity: 1;
  }
`;

export const AddIcon = styled(PerformanceButton)`
  padding: 3px;
  border-radius: 6px;
`;
