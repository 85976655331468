import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../assets/svg/plus.svg?react";
import { FooterText } from "../../../GlobalStyles";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import { useRetroFlowStore } from "../../../routes/Search/hooks/searchStore";
import { theme } from "../../../utils/theme";
import {
  PerformanceArtist,
  PerformanceButton,
  PerformanceButtonsContainer,
  PerformanceDateContainer,
  PerformanceDetails,
  PerformanceDetailsContainer,
  PerformanceDetailsDiv,
  PerformanceItemContainer,
  PerformanceVenue,
} from "./PerformanceItem-Styles";
import { useMemo } from "react";
const { colors } = theme;

export default function PerformanceItem({ performance, innerRef }) {
  const { performance_id, artist, date, city, venue, event_date } =
    performance || {};
  const navigate = useNavigate();
  const { isOpen, setIsOpen, setPerformance } = useRetroFlowStore();
  const { login, loggedInUser } = useMomentifyLogin();
  const is12am = useMemo(
    () =>
      moment(event_date).utc().isSame(moment(event_date).utc().startOf("day")),
    [event_date]
  );

  const handleRedirect = () => {
    navigate(`/performances/${performance_id}?date=${date}`);
  };

  const handleButtonClick = () => {
    if (isOpen) return;

    if (!loggedInUser) return login();

    setIsOpen(true);

    setPerformance(performance);
  };

  return (
    <PerformanceItemContainer key={performance_id} ref={innerRef}>
      <PerformanceDetailsContainer onClick={handleRedirect}>
        <PerformanceDateContainer>
          {/* Render the month */}
          <FooterText color={colors.green}>
            {moment(date).format("YYYY")}
          </FooterText>
          {/* Render the day */}
          <FooterText color={colors.green} weight="600">
            {moment(date).format("DD MMM")}
          </FooterText>
        </PerformanceDateContainer>
        <PerformanceDetails>
          <PerformanceDetailsDiv>
            <PerformanceArtist>{artist}</PerformanceArtist>
          </PerformanceDetailsDiv>
          <PerformanceDetailsDiv>
            <PerformanceVenue color={colors.grey}>{venue}</PerformanceVenue>
            <FooterText color={colors.grey}>
              {city && `${city}`}{" "}
              {event_date &&
                !is12am &&
                `• ${moment(event_date).utc().format("LT")}`}
            </FooterText>
          </PerformanceDetailsDiv>
        </PerformanceDetails>
      </PerformanceDetailsContainer>
      <PerformanceButtonsContainer>
        <PerformanceButton onClick={handleButtonClick}>
          <PlusIcon
            height={12}
            width={12}
            stroke={colors.white}
            strokeWidth={2}
          />
        </PerformanceButton>
      </PerformanceButtonsContainer>
    </PerformanceItemContainer>
  );
}

PerformanceItem.propTypes = {
  performance: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  innerRef: PropTypes.any,
};
