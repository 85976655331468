import styled from "styled-components";
// import ProfileNav from "../../components/Profile/ProfileNav";
import { NavBarTypes } from "../../constants/constants";
import { Section, BodyText, ButtonRect } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { useNavigate } from "react-router-dom";
import MessageItem from "../../components/Messages/MessageItem";
import useAuth from "../../hooks/useAuth";
import TopNav from "../../components/TopNav";
import { useEffect, useState } from "react";
import Yawn from "../../assets/svg/yawn.svg?react";
import SearchIcon from "../../assets/svg/search.svg?react";
import useNotification from "../../hooks/useNotification";
import { NotificationPermissionState } from "../../enums/permission";
import NotificationModal from "../../components/Modal/NotificationModal";
import useGlobalModal from "../../hooks/useGlobalModal";
import { useUserMessages } from "./hooks/messagesQueries";
import EmptyState from "../../components/EmptyState";
import RectButtonIconText from "../../components/RectButtonIconText";
import BellRingingIcon from "../../assets/svg/bell-ringing.svg?react";
import PartialEmptyState from "../../components/PartialEmptyState";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

const { colors } = theme;

const BadgesSection = styled(Section)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 16px;
  // padding: 16px;
  align-items: ${({ hasBadges }) => !hasBadges && "center"};
  justify-content: ${({ hasBadges }) => !hasBadges && "center"};
  flex: ${({ hasBadges }) => !hasBadges && "1"};
`;

/**
 * Function to render the user badges section based on the attained and next attainable badges.
 *
 * @return {React.JSX.Element} The user badges section component
 */
export default function Messages() {
  // Get the badges data from the loader
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const {
    notificationPermissionState,
    getBrowserNotifState,
    askNotificationPermission,
  } = useNotification();
  const { openSheetModal, setSheetModalContent } = useGlobalModal();
  const { handleTracking } = useMixpanelTracker();
  const _messages = useUserMessages({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: true,
  });

  const [messages, setMessages] = useState(_messages?.data);

  const handlePopSelectedItem = (messageId) => {
    setMessages(messages?.filter((row) => row.message_id != messageId));
  };

  const viewMessageOnClick = (sender_id) => {
    navigate(`/profile/messages/${sender_id}`);
  };

  useEffect(() => {
    handleTracking("Checked Messages");
    getBrowserNotifState().then((permState) => {
      if (permState == NotificationPermissionState.GRANTED) {
        askNotificationPermission();
      }
    });
  }, []);

  // Render the user notification center
  return (
    <BadgesSection>
      {/* Render the profile navigation for badges */}
      <TopNav type={NavBarTypes.MESSAGES} />
      {/* Render the badges container based on the existence of attained or next attainable badges */}
      <BadgesContainer hasBadges={true}>
        {_messages?.isLoading && (
          <>
            {Array.from({ length: 12 }).map((_, index) => (
              <MessageItem
                key={index}
                userId={loggedInUser?.id}
                message={{
                  sender_id: "",
                  sender_name: "",
                  message: "",
                  img: "",
                  created_at: "",
                  read_at: true,
                  message_id: "",
                }}
                isLoading={true}
              />
            ))}
          </>
        )}
        {messages?.length > 0 && (
          <>
            {loggedInUser?.id &&
              messages?.map((message, index) => (
                <MessageItem
                  viewMessageOnClick={() =>
                    viewMessageOnClick(message.sender_id)
                  }
                  handlePopSelectedItem={handlePopSelectedItem}
                  key={index}
                  message={message}
                  userId={loggedInUser?.id}
                  refetch={_messages?.refetch}
                />
              ))}
            {[NotificationPermissionState.PROMPT].includes(
              notificationPermissionState
            ) && (
              <PartialEmptyState
                icon={
                  <BellRingingIcon
                    stroke={colors.white}
                    width="24px"
                    height="24px"
                    opacity={0.6}
                  />
                }
                description="Turn push notifications on."
                button={
                  <ButtonRect
                    style={{
                      marginTop: "12px",
                    }}
                    onClick={() => {
                      setSheetModalContent(
                        <NotificationModal
                          askNotificationPermission={askNotificationPermission}
                        />
                      );
                      openSheetModal();
                    }}
                  >
                    <BodyText weight="600">Turn on</BodyText>
                  </ButtonRect>
                }
              />
            )}
          </>
        )}
      </BadgesContainer>
      {!messages?.length && !_messages?.isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            gap: "12px",
            flex: "1",
            padding: "0 20px",
          }}
        >
          <EmptyState
            icon={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              ) ? (
                <BellRingingIcon
                  height={24}
                  width={24}
                  stroke="white"
                  opacity={0.6}
                />
              ) : (
                <Yawn height={24} width={24} stroke="white" opacity={0.6} />
              )
            }
            title={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              )
                ? "Turn push notifications on"
                : "This looks boring!"
            }
            description={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              )
                ? "Receive messages from your favourite artists!"
                : "Subscribe to artists you love to learn about their gigs."
            }
            button={
              [NotificationPermissionState.PROMPT].includes(
                notificationPermissionState
              ) ? (
                <ButtonRect
                  style={{
                    marginTop: "12px",
                  }}
                  onClick={() => {
                    setSheetModalContent(
                      <NotificationModal
                        askNotificationPermission={askNotificationPermission}
                      />
                    );
                    openSheetModal();
                  }}
                >
                  <BodyText weight="600">Turn on</BodyText>
                </ButtonRect>
              ) : (
                <RectButtonIconText
                  icon={
                    <SearchIcon
                      stroke={theme.colors.white}
                      height={14}
                      width={14}
                    />
                  }
                  text={<BodyText weight="600">Search artists</BodyText>}
                  onClick={() => {
                    navigate("/search?tab=artist");

                    setTimeout(() => {
                      document.querySelector("input[name=search]")?.focus();
                    }, 100);
                  }}
                />
              )
            }
          />
        </div>
      )}
    </BadgesSection>
  );
}
