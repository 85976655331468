import { create } from "zustand";

export const useBuyTicketStore = create((set) => ({
  isOpen: false,
  setIsOpen: (value) => set(() => ({ isOpen: value })),
  event: null,
  setEvent: (value) => set(() => ({ event: value })),
  contract: null,
  setContract: (value) => set(() => ({ contract: value })),
  ticketsSelected: [],
  updateTicketSelected: (ticket, isRemove) => {
    set((state) => ({
      ticketsSelected: isRemove
        ? state.ticketsSelected.filter((t, index) => {
            const ticketIndex = state.ticketsSelected.findIndex(
              (item) => item.id === ticket.id
            );
            return index !== ticketIndex;
          })
        : [...state.ticketsSelected, ticket],
    }));
  },
  resetTicketsSelected: () => {
    set(() => ({ ticketsSelected: [] }));
  },

  reset: () => {
    set({
      isOpen: false,
      contract: null,
      event: null,
      ticketsSelected: [],
    });
  },
}));
