import PropTypes from "prop-types";
import Momentify from "../../../assets/svg/momentify.svg?react";
import Coinbase from "../../../assets/svg/coinbase.svg?react";
import Metamask from "../../../assets/svg/metamask.svg?react";

export default function WalletCardIcon({ type }) {
  if (!type) return null;

  if (type === "privy")
    return (
      <div>
        <Momentify height={14} width={23.19} fill="white" />
      </div>
    );

  if (type === "coinbase_wallet" || type === "coinbase_smart_wallet")
    return (
      <div>
        <Coinbase height={16} width={16} fill="white" />
      </div>
    );

  if (type === "metamask")
    return (
      <div>
        <Metamask height={18} width={18} fill="white" />
      </div>
    );

  return null;
}

WalletCardIcon.propTypes = {
  type: PropTypes.string,
};
