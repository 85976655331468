import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 9.5px 2px 8px;
  overflow: hidden;
`;

export const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;

export const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 8px;
`;
