import { useMemo } from "react";
import { SmallHeader } from "./components/SmallHeader";
import { useNearbyVenues } from "../../routes/Events/hooks/eventsQueries";
import { useUserStore } from "../../routes/Profile/store/userStore";
import { VenueCard } from "./components/VenueCard";
import useGeolocation from "../../hooks/useGeolocation";
import { getDistanceFromLatLong } from "../../utils/utils";
import { NearbyVenuesSkeleton } from "./components/NearbyVenuesSkeleton";

export const NearbyVenues = () => {
  const { userCountry } = useUserStore();
  const { currentLocation } = useGeolocation();
  const nearbyVenuesQuery = useNearbyVenues({
    country_param: userCountry,
  });
  const allVenuesQuery = useNearbyVenues({ country_param: null });

  const headerText =
    nearbyVenuesQuery?.data?.length > 0
      ? "Venues near you"
      : "Venues around the world";

  const data = useMemo(() => {
    if (
      nearbyVenuesQuery?.data?.length > 0 &&
      currentLocation?.coords?.latitude &&
      currentLocation?.coords?.longitude
    ) {
      const lat1 = currentLocation?.coords?.latitude;
      const lon1 = currentLocation?.coords?.longitude;
      const sortedVenues = nearbyVenuesQuery.data
        .map((venue) => ({
          ...venue,
          distance: Math.max(
            getDistanceFromLatLong(
              lat1,
              lon1,
              venue.latitude,
              venue.longitude
            ) - 100,
            0
          ),
        }))
        .sort((a, b) => a.distance - b.distance);

      return sortedVenues.slice(0, 5);
    } else if (allVenuesQuery?.data?.length > 0) {
      const shuffledVenues = allVenuesQuery.data
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

      return shuffledVenues.slice(0, 5);
    }

    return [];
  }, [
    nearbyVenuesQuery.data,
    allVenuesQuery.data,
    currentLocation?.coords?.latitude,
    currentLocation?.coords?.longitude,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {nearbyVenuesQuery?.isLoading || allVenuesQuery?.isLoading ? (
        <NearbyVenuesSkeleton />
      ) : (
        <>
          <SmallHeader headerText={headerText} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            {data?.map((venue) => (
              <VenueCard venue={venue} key={venue.id} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
