import { useAccount } from "wagmi";
import useMarketplace from "../../hooks/useMarketplace";
import { useEffect, useState } from "react";

export default function Marketplace() {
  const { isOpen, toggleMarketplace, uri } = useMarketplace();
  const { address } = useAccount()
  // http://localhost:4000
  // https://shop.momentify.xyz
  const [url, setUrl] = useState(`https://shop.momentify.xyz/${uri}?privyAddress=${address}`)

  useEffect(() => {
    setUrl(`https://shop.momentify.xyz/${uri}?privyAddress=${address}`)
  }, [uri])

  // if (!isOpen) return <></>

  return (
    <div style={{
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: 100,
      width: '100%',
      height: '90vh',
      display: isOpen ? 'block' : 'none',
    }}>
      <iframe style={{
        border: 'none',
        width: '100%',
        height: '90vh '
      }} src={url} />
    </div>
  );
}