import styled from "styled-components";
import { Title } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  min-height: 268px;
  max-height: 268px;
  width: 100%;
  position: relative;
`;

export const ArtistImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(12, 13, 23, 0.3) 0%,
    rgba(12, 13, 23, 0) 50%,
    rgba(12, 13, 23, 0.5) 100%
  );
  z-index: 1;
`;

export const BackButtonContainer = styled.div`
  z-index: 2;
  cursor: pointer;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 20px;
  flex: 1;
`;

export const ArtistPfp = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 2;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 2;
  gap: 16px;
`;

export const NameContainer = styled(Title)`
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FollowAndSubscribeContainer = styled.div`
  display: flex;
  z-index: 2;
  gap: 12px;
`;
