import PropTypes from "prop-types";
import { useMemo } from "react";
import { FooterText } from "../../../GlobalStyles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import Ticket from "../Ticket";
import EmptyState from "../../EmptyState";
import TwoTickets from "../../../assets/svg/two-tickets.svg?react";
import { TicketsContainer, TicketsHeader } from "./Tickets-Styles";
import TicketsStatus from "../TicketsStatus";

export default function Tickets({ data, status, statusLoading }) {
  const { tickets, contract } = data || {};
  const { perAccountMax } = contract || {};

  const availableTickets =
    useMemo(() => {
      return tickets.filter((ticket) => !ticket?.hidden);
    }, [tickets]) || [];

  if (statusLoading) {
    return (
      <TicketsContainer style={{ gap: "12px" }}>
        <TicketsHeader>
          <SkeletonElement height="18px" width="30%" />
          <SkeletonElement height="18px" width="30%" />
        </TicketsHeader>
        <TicketsContainer style={{ gap: "12px" }}>
          {Array.from({ length: 5 }).map((_, index) => (
            <SkeletonElement
              key={index}
              height="97px"
              width="100%"
              radius="16px"
            />
          ))}
        </TicketsContainer>
      </TicketsContainer>
    );
  }

  if (!availableTickets?.length) {
    return (
      <EmptyState
        icon={<TwoTickets height={24} width={24} stroke="white" />}
        title="No tickets available"
        description="Please check back later."
      />
    );
  }

  return (
    <TicketsContainer style={{ gap: "12px" }}>
      <TicketsHeader>
        <FooterText>Max of {perAccountMax} per account</FooterText>
        <TicketsStatus status={status} isLoading={statusLoading} />
      </TicketsHeader>
      <TicketsContainer style={{ gap: "16px" }}>
        {availableTickets.map((ticket) => (
          <Ticket
            key={ticket.id}
            ticket={ticket}
            status={status}
            isLoading={statusLoading}
          />
        ))}
      </TicketsContainer>
    </TicketsContainer>
  );
}

Tickets.propTypes = {
  data: PropTypes.object,
  status: PropTypes.object,
  statusLoading: PropTypes.bool,
};
