export const checkWhitelisted = async (phoneNumber) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/restriction/check-whitelisted`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
        }),
      }
    );

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to send report" };
    }

    const res = await result.json();
    console.log(res);
    if (!res.success) {
      console.error(res.error);
      return { success: false, error: "Failed to send report" };
    }

    return { success: true, isWhitelisted: res.isWhitelisted, data: res.data };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to send report" };
  }
};

export const logRestrictionCode = async (phoneNumber, code) => {
  try {
    const result = await fetch(
      `${import.meta.env.VITE_API_URL}/restriction/use-code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          code: code,
        }),
      }
    );
    const res = await result.json();
    console.log(res);
    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: res.error };
    }

    console.log(res);
    if (!res.success) {
      console.error(res.error);
      return { success: false, error: res.error };
    }

    return { success: true, data: res.data };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to send report" };
  }
};
