import { useLogEventClick } from "../../Events/hooks/interestedQueries";
import useAuth from "../../../hooks/useAuth";
import { useUserStore } from "../../../routes/Profile/store/userStore";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  EventDateContainer,
  EventText,
  EventVenueContainer,
  InterestedButtonContainer,
} from "../../Events/EventCardItem";
import InterestedButton from "../../Events/InterestedButton";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import moment from "moment";
import { theme } from "../../../utils/theme";
import { Overlay } from "./WideEventCard";

export const BigEventCard = ({
  event,
  isArtist,
  vertical_position = undefined,
  horizontal_position = undefined,
  header = undefined,
}) => {
  const { event_id, combined_artist_ids, artist_id } = event;
  const logEventClick = useLogEventClick();
  const { loggedInUser } = useAuth();
  const { userCountry } = useUserStore();
  const { fontSizes, colors } = theme;

  const eventImage = (event) => {
    const artistImage =
      !event?.artist_image || event?.artist_image == null
        ? null
        : event?.artist_image;

    const eventImage =
      !event?.image || event?.image == null
        ? GlassmorphPlaceholder
        : event?.image;

    return isArtist && artistImage ? artistImage : eventImage;
  };

  return (
    <Link
      style={{
        minWidth: 171,
        minHeight: 171,
        borderRadius: 8,
        display: "flex",
        backgroundImage: `url(${eventImage(event)})`,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "left !important",
        backgroundSize: "cover", // Ensure the image covers the entire area
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent image repetition
        justifyContent: "end",
        position: "relative",
      }}
      to={`/events/${event?.event_id}?date=${event?.date}`}
      onClick={() => {
        logEventClick.mutate({
          user_id: loggedInUser?.id,
          combined_artist_ids: combined_artist_ids ?? [artist_id],
          event_id: event_id,
          horizontal_position: horizontal_position,
          vertical_position: vertical_position,
          header: header,
          country: userCountry,
        });
      }}
    >
      <InterestedButtonContainer
        style={{
          top: 12,
          right: 12,
          zIndex: 10,
        }}
      >
        {/* The interested button */}
        <InterestedButton isFilled={true} event={event} size={20} />
      </InterestedButtonContainer>
      <Overlay
        style={{
          padding: 8,
          borderRadius: 8,
        }}
      >
        <EventText
          weight="600"
          style={{
            width: "100%",
            marginBottom: 4,
          }}
        >
          {isArtist && event.artist_name
            ? event?.artist_name
            : event.headline_artist}
        </EventText>

        <EventVenueContainer>
          <EventText
            weight="400"
            style={{ opacity: 0.6 }}
            size={fontSizes.mobile.footer}
          >
            {event.venue_name}
          </EventText>

          <EventText style={{ opacity: 0.6 }} size={fontSizes.mobile.footer}>
            {event.city}
          </EventText>
        </EventVenueContainer>

        <EventDateContainer>
          <EventText size={fontSizes.mobile.footer} color={colors.softGreen}>
            {moment(event.date).format("ddd D MMM")}
          </EventText>
          <EventText size={fontSizes.mobile.footer} color={colors.grey}>
            {moment(event.date).format("LT")}
          </EventText>
        </EventDateContainer>
      </Overlay>
    </Link>
  );
};

BigEventCard.propTypes = {
  event: PropTypes.object,
  isArtist: PropTypes.bool,
  isEvent: PropTypes.bool,
  vertical_position: PropTypes.number,
  horizontal_position: PropTypes.number,
  header: PropTypes.any,
};
