import PropTypes from "prop-types";
import { UserTicketsContainer } from "./UserTickets-Styles";
import { Link, useParams } from "react-router-dom";
import TicketItem from "../../Tickets/TicketItem";
import { uniqueId } from "lodash";
import { useUserTicketsStore } from "./stores/userTicketsStore";
import { USER_TICKETS_FILTERS } from "../../../constants/constants";
import EmptyState from "../../EmptyState";
import TicketsIcon from "../../../assets/svg/two-tickets.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { ALL } = USER_TICKETS_FILTERS;

export default function UserTicketsContent({ tickets, isLoading }) {
  const { id } = useParams();
  const { activeFilter } = useUserTicketsStore();

  if (isLoading) {
    return (
      <UserTicketsContainer>
        {Array.from({ length: 12 }).map((_, index) => (
          <SkeletonElement
            key={index}
            width="100%"
            height="125px"
            radius="10px"
          />
        ))}
      </UserTicketsContainer>
    );
  }

  if (!tickets || !tickets?.length) {
    return (
      <EmptyState
        icon={
          <TicketsIcon height={24} width={24} stroke="white" opacity={0.6} />
        }
        title={
          activeFilter !== ALL
            ? "Nothing Here..."
            : "Oh no, you have no tickets!"
        }
        description="Why not treat yourself to a ticket and have fun?"
      />
    );
  }

  return (
    <UserTicketsContainer>
      {tickets.map((ticket) => (
        <Link
          to={`/profile/${id}/tickets/${ticket?.event_id}/type/${ticket?.type}`}
          key={uniqueId("ticket-")}
        >
          <TicketItem ticket={ticket} />
        </Link>
      ))}
    </UserTicketsContainer>
  );
}

UserTicketsContent.propTypes = {
  tickets: PropTypes.array,
  isLoading: PropTypes.bool,
};
