import { erc20Abi, formatUnits } from "viem";
import { useReadContracts } from "wagmi";
import { formatPrice, isProd } from "../utils/utils";
import useAuth from "./useAuth";
import { useLinkedWallets } from "./useLinkedWallets";
import { useEffect, useMemo, useState } from "react";
import { USDC_ADDRESS, USDC_ADDRESS_SEPOLIA } from "../constants/constants";
import { base, baseSepolia } from "viem/chains";

export const usePrimaryWallet = () => {
  const { loggedInUser } = useAuth();
  const { linkedWallets } = useLinkedWallets();
  const { embedded_wallet_id, receiving_wallet } = loggedInUser || {};

  const primaryWallet = useMemo(() => {
    return (
      (linkedWallets || linkedWallets?.length) &&
      linkedWallets.find((wallet) => wallet.address === embedded_wallet_id)
    );
  }, [linkedWallets, embedded_wallet_id]);

  const receivingWallet = useMemo(() => {
    return (
      (linkedWallets || linkedWallets?.length) &&
      linkedWallets.find((wallet) => wallet.address === receiving_wallet)
    );
  }, [linkedWallets, receiving_wallet]);

  if (!primaryWallet) return { primaryWallet: null };

  return { primaryWallet, receivingWallet };
};

export const usePrimaryWalletBalance = () => {
  const { primaryWallet } = usePrimaryWallet();
  const { address } = primaryWallet || {};
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState("0.00");

  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainId = isProd ? base.id : baseSepolia.id;

  const { data, isLoading, error } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: USDC,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address],
        chainId,
      },
      {
        address: USDC,
        abi: erc20Abi,
        functionName: "decimals",
        chainId,
      },
    ],
  });

  const allLoading = isLoading || loading;

  useEffect(() => {
    if (!data || isLoading) return;
    setLoading(true);
    const { 0: balance, 1: decimals } = data || {};

    if (balance?.status !== "success" || decimals?.status !== "success") {
      setBalance("0.00");
      setLoading(false);
      return;
    }

    const formattedBalance = formatUnits(balance.result, decimals.result); // USDC has 6 decimal places

    const formattedPrice = formatPrice(formattedBalance, false);

    setBalance(formattedPrice);
    setLoading(false);
  }, [data, isLoading]);

  if (!primaryWallet) return { balance: "0.00", isLoading: false };

  return { balance, isLoading: allLoading, error };
};
