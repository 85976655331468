import { usePrivy } from "@privy-io/react-auth";
import { Container } from "./LinkWallets-Styles";
import { Button, BodyText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import Plus from "../../../assets/svg/plus.svg?react";

export default function LinkWallets() {
  const { linkWallet } = usePrivy();
  return (
    <Container onClick={linkWallet}>
      <Button>
        <Plus height={16} stroke="white" />
      </Button>
      <BodyText color={theme.colors.grey}>Add another wallet</BodyText>
    </Container>
  );
}
