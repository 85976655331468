import { useParams } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { VenueDetailsContainer, VenueSection } from "./Venue-Styles";
import {
  useVenue,
  useVenueCollectiblesQuery,
  useVenueMomentsQuery,
} from "./hooks/venueQueries";
import ErrorPage from "../Error";
import VenueHeader from "../../components/Venue/VenueHeader";
import VenueTabs from "../../components/Venue/VenueTabs";
import VenueDetails from "../../components/Venue/VenueDetails";
import TopNavV2 from "../../components/TopNavV2";
import { VenuePlaceholder } from "../../constants/constants";

export default function Venue() {
  const { loggedInUser } = useAuth();
  const { id: venueId, name: venueName } = useParams();
  const { data, error, isLoading } = useVenue({
    venueId,
    venueName,
    userId: loggedInUser?.id,
  });
  const { venueDetails } = data || {};
  const { venue, events } = venueDetails || {};

  const { data: moments, isLoading: momentsLoading } = useVenueMomentsQuery({
    venueId,
    userId: loggedInUser?.id,
  });

  const { data: collectibles, isLoading: collectiblesLoading } =
    useVenueCollectiblesQuery({
      venueId,
    });

  if ((!venueDetails || error) && !isLoading)
    return <ErrorPage description="We can't find this venue." />;

  return (
    <VenueSection>
      <TopNavV2 icon={VenuePlaceholder} title={venueName} />
      <VenueHeader venue={venue} events={events} isLoading={isLoading} />
      <VenueDetailsContainer>
        <VenueDetails venue={venue} isLoading={isLoading} />
        <VenueTabs
          events={events}
          moments={moments}
          collectibles={collectibles}
          isEventsLoading={isLoading}
          isMomentsLoading={momentsLoading}
          isCollectiblesLoading={collectiblesLoading}
        />
      </VenueDetailsContainer>
    </VenueSection>
  );
}
