import PropTypes from "prop-types";
import CollectiblesIcon from "../../../assets/svg/collectibles.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import EmptyState from "../../EmptyState";
import { theme } from "../../../utils/theme";
import CollectibleThumbnail from "../../Collectibles/CollectibleThumbnail";
import { BodyText, FooterText } from "../../../GlobalStyles";
import {
  Container,
  CollectiblesTabContainer,
} from "./ArtistCollectiblesTab-Styles";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export default function ArtistCollectiblesTab({ collectibles, isLoading }) {
  const { pathname } = useLocation();
  const noHeader = useMemo(() => {
    return pathname.includes("performances");
  }, [pathname]);

  // Whether or not the user has any events to render.
  const noCollectibles =
    !collectibles || collectibles.length == 0 || collectibles[0] == null;

  const artist = collectibles?.[0]?.artist;

  if (isLoading) {
    return (
      <Container>
        {!noHeader && <BodyText weight="600">Made by {artist}</BodyText>}
        <CollectiblesTabContainer>
          {Array.from({ length: 12 }).map((_, index) => (
            <SkeletonElement
              key={index}
              width="100%"
              height="270px"
              radius="10px"
            />
          ))}
        </CollectiblesTabContainer>
      </Container>
    );
  }

  // Render the empty tab if the user doesn't have any events.
  if (noCollectibles) {
    return (
      <EmptyState
        icon={
          <CollectiblesIcon
            height={24}
            width={24}
            stroke="white"
            opacity={0.6}
          />
        }
        title="Coming Soon"
        description={`The ${pathname.includes("artists") ? "artist" : pathname.includes("venue") ? "venue" : "performance"}'s collectibles will appear here.`}
        style={{
          paddingTop: "15%",
        }}
      />
    );
  }

  // Render the list of events if the user has events.
  return (
    <Container>
      {!noHeader && (
        <BodyText weight="600">
          Made by {artist}
          <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
            ({collectibles?.length})
          </FooterText>
        </BodyText>
      )}
      <CollectiblesTabContainer>
        {collectibles.map((collectible) => (
          <CollectibleThumbnail
            key={collectible?.id}
            collectible={collectible}
          />
        ))}
      </CollectiblesTabContainer>
    </Container>
  );
}

ArtistCollectiblesTab.propTypes = {
  user: PropTypes.object,
  collectibles: PropTypes.array,
  isLoading: PropTypes.bool,
};
