import PropTypes from "prop-types";
import PerformanceMoment from "../../Performance/PerformanceMoment";
import Polaroids from "../../../assets/svg/polaroids.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import EmptyState from "../../EmptyState";
import { BodyText } from "../../../GlobalStyles";
import { Container, MomentsGrid } from "./MomentsTab-Styles";
import { theme } from "../../../utils/theme";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export default function MomentsTab({ moments, isLoading }) {
  const { pathname } = useLocation();
  const noHeader = useMemo(() => {
    return pathname.includes("performances");
  }, [pathname]);

  if (isLoading) {
    return (
      <Container>
        {!noHeader && <BodyText weight="600">Photos from fans</BodyText>}
        <MomentsGrid
          style={{
            padding: 0,
          }}
        >
          {Array.from({ length: 6 }).map((_, index) => (
            <SkeletonElement
              height="243px"
              width="100%"
              radius="8px"
              key={index}
            />
          ))}
        </MomentsGrid>
      </Container>
    );
  }

  if (moments?.length === 0) {
    return (
      <EmptyState
        icon={<Polaroids height={24} width={24} stroke={theme.colors.grey} />}
        title={`No Moments Here... Yet!`}
        description={`Photos from fans will appear here.`}
        style={{
          paddingTop: "15%",
        }}
      />
    );
  }

  return (
    <Container>
      {!noHeader && <BodyText weight="600">Photos from fans</BodyText>}
      <MomentsGrid
        style={{
          padding: 0,
        }}
      >
        {moments?.map((moment) => (
          <PerformanceMoment key={moment?.moment_id} moment={moment} />
        ))}
      </MomentsGrid>
    </Container>
  );
}

MomentsTab.propTypes = {
  moments: PropTypes.array,
  isLoading: PropTypes.bool,
};
