import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { BodyText, FooterText } from "../../GlobalStyles";
import CloseIcon from "../../assets/svg/close.svg?react";
import PinIcon from "../../assets/svg/pin-white.svg?react";
import useAuth from "../../hooks/useAuth";
import { theme } from "../../utils/theme";
import { ColumnFlex, RowFlex } from "../Events/YourEvent/YourEvent-Style";
import {
  InputContainer,
  ResultDropdown,
  ResultItem,
  SearchInput,
} from "./Location-Styles";

import { useLocationQuery, useLocationXPQuery } from "./hooks/locationQueries";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";

export const Location = () => {
  const [location, setLocation] = useState("");
  const [valid, setValid] = useState(false);
  const [debouncedLocation] = useDebounce(location, 200);
  const { loggedInUser, updateCurrentUser } = useAuth();
  const locationXPMutation = useLocationXPQuery({ userId: loggedInUser?.id });

  const locationQuery = useLocationQuery({
    query: debouncedLocation,
    enabled: !valid && !!loggedInUser,
  });

  const { handleTracking } = useMixpanelTracker();

  useEffect(() => {
    if (loggedInUser) {
      setLocation(loggedInUser.location ? loggedInUser.location : "");
      setValid(true);
    }
  }, [loggedInUser]);

  const handleResultClick = async (result) => {
    setLocation(result);
    setValid(true);

    await updateCurrentUser({
      location: result,
    });

    if (loggedInUser?.location == null) {
      await locationXPMutation.mutateAsync();
      handleTracking("Added default location");
    } else {
      handleTracking("Updated default location");
    }
  };

  const handleClearLocation = async () => {
    setLocation("");
    setValid(false);
  };

  return (
    <ColumnFlex>
      <RowFlex
        style={{
          justifyContent: "space-between",
        }}
      >
        <BodyText
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Default location
        </BodyText>
        <FooterText secondary>You’re the only one who can see this.</FooterText>
      </RowFlex>
      <InputContainer>
        <PinIcon
          style={{
            position: "relative",
            bottom: "1px",
            minWidth: "18px",
            minHeight: "18px",
            transform: "none",
            willChange: "auto",
          }}
          stroke="white"
          height={18}
          width={18}
        />
        <SearchInput
          type="search"
          placeholder="Add city and country"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
            setValid(false);
          }}
          onFocus={(e) => {
            setLocation(e.target.value);
            setValid(false);
          }}
        />
        {location ? (
          <CloseIcon
            stroke="white"
            height={16}
            width={16}
            onClick={handleClearLocation}
          />
        ) : (
          <BodyText
            style={{
              color: theme.colors.green,
              position: "absolute",
              left: "180px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
            }}
          >
            +50xp
          </BodyText>
        )}
        {locationQuery?.data && locationQuery?.data.length > 0 && !valid && (
          <ResultDropdown>
            {locationQuery.data.map((item, index) => (
              <ResultItem key={index} onClick={() => handleResultClick(item)}>
                <BodyText style={{ fontWeight: 400, fontSize: 14 }}>
                  {item}
                </BodyText>
              </ResultItem>
            ))}
          </ResultDropdown>
        )}
      </InputContainer>
    </ColumnFlex>
  );
};
