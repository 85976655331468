import { useParams } from "react-router-dom";
import { useGetTickets } from "./ticketQueries";
import { Section } from "../../GlobalStyles";
import TopNav from "../../components/TopNav";
import { Container } from "../Collectible/Collectible-Styles";
import { NavBarTypes } from "../../constants/constants";
import ErrorPage from "../Error";
import TicketHeader from "../../components/TicketPage/TicketHeader";
import TicketImage from "../../components/TicketPage/TicketImage";
import TicketEventDetails from "../../components/TicketPage/TicketEvent";
import TicketOwner from "../../components/TicketPage/TicketOwner";
import TicketTokens from "../../components/TicketPage/TicketTokens";
import TicketUrls from "../../components/TicketPage/TicketUrls";
import TicketAdditionalInfo from "../../components/TicketPage/TicketAdditionalInfo";

export default function Ticket() {
  const { profileId, eventId, ticketType } = useParams();
  const { data, isLoading } = useGetTickets({
    userId: profileId,
    eventId,
    type: ticketType,
  });

  const { type, price, image, contract_address, contract_data } =
    data?.[0] || {};

  if (!isLoading && (data?.length === 0 || !data)) {
    return <ErrorPage description={"Can't seem to find this ticket."} />;
  }

  return (
    <Section>
      <TopNav type={NavBarTypes.TICKET} />
      <Container style={{ padding: "0 20px 16px" }}>
        <TicketHeader
          type={type}
          price={price}
          count={data?.length}
          isLoading={isLoading}
        />
        <TicketImage image={image} isLoading={isLoading} />
        <TicketEventDetails ticket={data?.[0]} isLoading={isLoading} />
        <TicketOwner ticket={data?.[0]} isLoading={isLoading} />
        <TicketTokens tickets={data} isLoading={isLoading} />
        <TicketUrls contractAddress={contract_address} isLoading={isLoading} />
        <TicketAdditionalInfo
          contractData={{ ...contract_data, ticketType, contract_address }}
          isLoading={isLoading}
        />
      </Container>
    </Section>
  );
}
